import { useMutation, useQuery, UseQueryResult, useSuspenseQuery, UseSuspenseQueryResult } from '@tanstack/react-query';
import axios from 'axios';

import {
  GetBackOfficeOrdersData,
  OrderAddChattingUrlRequest,
  ResponseDtoListOrderRemakeHistory,
  ResponseDtoOrderDetailResponse,
  ResponseDtoPageBackOfficeOrderResponse,
  ResponseDtoRemakeRateResponse,
} from '../../client';
import { getOrdersUserInfo } from './order.api';

type OrderListProps = {
  option: {
    page: number;
    size: number;
    sort?: string[];
    startDate?: GetBackOfficeOrdersData['startDate'];
    endDate?: GetBackOfficeOrdersData['endDate'];
    filterType?: GetBackOfficeOrdersData['filterType'];
    searchKeyword?: GetBackOfficeOrdersData['searchKeyword'];
  };
};

export const useOrderListQuery = ({
  option,
}: OrderListProps): UseQueryResult<ResponseDtoPageBackOfficeOrderResponse> => {
  return useQuery({
    queryKey: ['orderList', option],
    queryFn: () => axios.get(`connect/back-office/admin/orders`, { params: { ...option } }).then((res) => res.data),
    refetchOnWindowFocus: true,
  });
};

export const useOrderDetailQuery = (orderId: string): UseSuspenseQueryResult<ResponseDtoOrderDetailResponse> => {
  return useSuspenseQuery({
    queryKey: ['orderDetail', orderId],
    queryFn: () => axios.get(`connect/back-office/admin/orders/${orderId}`).then((res) => res.data),
    refetchOnWindowFocus: true,
    // enabled: !!orderId,
  });
};

export const useOrderAssignToLabQuery = () => {
  return useMutation({
    mutationFn: async ({ labId, orderId }: { labId: string; orderId: string }) =>
      axios.patch(`connect/back-office/admin/orders/${orderId}/assign`, { labId }).then((res) => res.data),
  });
};

export const useOrderRejectionByQCQuery = () => {
  return useMutation({
    mutationFn: async ({ orderId }: { orderId: string }) =>
      axios.patch(`connect/back-office/admin/orders/${orderId}/reject`).then((res) => res.data),
  });
};

export const useOrdersUserInfoQuery = (targetUserId: string): UseQueryResult<ResponseDtoRemakeRateResponse> => {
  return useQuery({
    queryKey: ['ordersUserInfo', targetUserId],
    queryFn: () => getOrdersUserInfo(targetUserId),
  });
};

export const useOrderRemakeHistoryQuery = (orderId: string): UseQueryResult<ResponseDtoListOrderRemakeHistory> => {
  return useQuery({
    queryKey: ['orderRemakeHistory', orderId],
    queryFn: () => axios.get(`connect/back-office/admin/orders/${orderId}/remake/history`).then((res) => res.data),
    enabled: !!orderId,
  });
};

// TODO: Not working now.
const patchChattingChannelUrl = (orderId: string, chattingChannelUrl: string) =>
  axios.patch(`connect/back-office/admin/orders/${orderId}/chatting`, { chattingChannelUrl }).then((res) => res.data);

export const useOrderAddChat = (orderId: string) => {
  return useMutation({
    mutationFn: ({ chattingChannelUrl }: OrderAddChattingUrlRequest) =>
      patchChattingChannelUrl(orderId, chattingChannelUrl),
  });
};
