import React from 'react';
import { Table, TableBody, TableCell, TableCellProps, TableContainer, TableHead, TableRow } from '@mui/material';

import { ITableColumnTypes } from './DataTable';

interface IDataTableWithScrollProps {
  tableColumn: Array<ITableColumnTypes>;
  children: React.ReactNode;
  sx?: any;
}

export const DataTableWithScroll = ({ tableColumn, children, sx }: Readonly<IDataTableWithScrollProps>) => {
  return (
    <TableContainer
      sx={{
        pt: '0 !important',
        minHeight: '360px',
        maxHeight: 'calc(100vh - 300px)',
        overflowY: 'auto',
        width: '100%',
        ...sx,
      }}
    >
      <Table
        stickyHeader
        aria-label="custom pagination table"
        sx={{
          position: 'relative',
          borderCollapse: 'separate !important',
        }}
      >
        <TableHead>
          <TableRow
            sx={{
              '& th': {
                padding: '16px',
                border: 0,
                color: 'text.secondary',
                background: 'background.neutral',
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '24px',
                boxSizing: 'border-box',
                '&:first-of-type': {
                  paddingLeft: '24px',
                },
                '&:last-child': {
                  paddingRight: '24px',
                },
              },
            }}
          >
            {tableColumn.map((item, index) => (
              <TableCell
                key={index}
                style={{ display: item?.display }}
                align={item?.alignCol as TableCellProps['align']}
                sx={{ minWidth: item?.minWidth, maxWidth: item?.maxWidth }}
              >
                {item.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            overflowY: 'auto',
            '& tr': {
              '& td': {
                borderBottom: 0,
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '22px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                '&:first-of-type': {
                  paddingLeft: '24px',
                },
                '&:last-child': {
                  paddingRight: '24px',
                },
              },
            },
          }}
        >
          {children}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
