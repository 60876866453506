import React, { useEffect, useState } from 'react';
import { Checkbox, TableCell, TableRow } from '@mui/material';

import { ProductDetail } from '../../../client';
import CustomAlertDialog from '../../../components/dialog/CustomAlertDialog';
import CustomDataTable, { ITableColumnTypes } from '../../../components/table/DataTable';
import { TableLoading } from '../../../components/table/TableLoading';
import { TableNoData } from '../../../components/table/TableNoData';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { useProductItemLifeCycleMutation, useProductItemListDelete } from '../../../recoil/products/useProduct';
import useTopAlert from '../../../recoil/topAlert/useTopAlert';
import { convertOrderRangeText } from '../../Order/Helper';
import { ProductPublishAndDraftDialogue } from './ProductPublishAndDraftDialogue';
import { ProductStatusType } from './ProductsListPage';
import TableRowActions from './TableRowActions';

interface StyledIStatusProps {
  status: string;
}

const tableColumn: ITableColumnTypes[] = [
  { id: 'prosthesis', label: 'Prosthesis', minWidth: 156, alignCol: 'left' },
  { id: 'method', label: 'Method', minWidth: 160, alignCol: 'left' },
  { id: 'material', label: 'Material', minWidth: 200, alignCol: 'left' },
  { id: 'shape', label: 'Shape', minWidth: 156, alignCol: 'center' },
  { id: 'range', label: 'Range', minWidth: 238, alignCol: 'left' },
  {
    id: 'uniquePrice',
    label: 'Unique Price',
    minWidth: 200,
    alignCol: 'center',
  },
  { id: 'actions', label: '', minWidth: 68, alignCol: 'center' },
];

interface ITableProps {
  activeTab: ProductStatusType;
  productList: Array<ProductDetail>;
  setProductList: any;
  // pagination
  dense: boolean;
  page: number;
  totalElement: number;
  rowsPerPage: number;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeDense: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isPending: boolean;
}

// Publish / Draft dialog 관련
export default function ProductsListTable({
  activeTab,
  productList,
  // isProductEditingOrLoading,
  setProductList,
  // pagination
  dense,
  page,
  totalElement,
  rowsPerPage,
  onChangePage,
  onChangeDense,
  onChangeRowsPerPage,
  isPending,
}: Readonly<ITableProps>) {
  const { setTopAlert } = useTopAlert();
  const { mutateAsync: deleteProductItems } = useProductItemListDelete();
  const { mutateAsync, variables } = useProductItemLifeCycleMutation();

  const { open, handleOpenDialogue, handleCloseDialogue } = useDialogue();
  const [openAlertDialog, setOpenAlertDialog] = useState(false);
  const [checkedItemList, setCheckedItemList] = useState<ProductDetail[]>([]);
  const isDraftTab = activeTab === 'DRAFTED';

  const handleOpenAlertDialog = () => {
    setOpenAlertDialog(true);
  };
  const handleCloseAlertDialog = () => {
    setOpenAlertDialog(false);
  };
  const handleItemCheckClick = (product: ProductDetail) => {
    setCheckedItemList((prev) => {
      const isChecked = prev.some((item) => item.id === product.id);
      if (isChecked) {
        return prev.filter((item) => item.id !== product.id);
      } else {
        return [...prev, product];
      }
    });
  };
  const handleAllItemClick = () => {
    if (productList.length === checkedItemList.length) {
      setCheckedItemList([]);
    } else {
      setCheckedItemList(productList);
    }
  };

  useEffect(() => {
    setCheckedItemList([]);
  }, [activeTab]);

  return (
    <>
      <CustomDataTable
        activeTab={activeTab}
        tableColumn={tableColumn}
        checkedItemList={checkedItemList}
        onAllItemClick={handleAllItemClick}
        rowsPerPage={rowsPerPage}
        onOpenConfirmDialog={handleOpenDialogue}
        dense={dense}
        page={page}
        totalElement={totalElement}
        onChangePage={onChangePage}
        onChangeDense={onChangeDense}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onOpenDeleteDialog={handleOpenAlertDialog}
        // onPublishClick={handlePublishClick}
        // onDraftClick={handleDraftClick}
      >
        {isPending ? (
          <TableLoading style={{ height: '344px' }} colSpan={tableColumn.length} />
        ) : productList && productList.length > 0 ? (
          productList?.map((row, index) => (
            <TableRow key={row.id}>
              <TableCell align="right">
                <Checkbox
                  onClick={() => handleItemCheckClick(row)}
                  checked={checkedItemList.some((item) => item.id === row.id)}
                />
              </TableCell>
              <TableCell align="left">{row.prosthesis.productOptionValue}</TableCell>
              <TableCell align="left">{row.method}</TableCell>
              <TableCell align="left">{row.material}</TableCell>
              <TableCell align="center">{row.shape}</TableCell>
              {/*// @ts-ignore*/}
              <TableCell align="left">{convertOrderRangeText(row.designManufactureCombination)}</TableCell>
              <TableCell align="center">{`JPY ${row?.price.toLocaleString()}`}</TableCell>
              <TableCell align="center">
                {isDraftTab && <TableRowActions row={row} setCheckedItemList={setCheckedItemList} />}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <TableNoData style={{ height: '344px' }} colSpan={tableColumn.length} content={'No product list to show'} />
        )}
      </CustomDataTable>

      <ProductPublishAndDraftDialogue
        open={open}
        onClose={handleCloseDialogue}
        isDraft={isDraftTab}
        checkedItemList={checkedItemList}
        setCheckedItemList={setCheckedItemList}
      />

      {/*openAlertDialog*/}

      {isDraftTab && openAlertDialog ? (
        <CustomAlertDialog
          title={`Delete Product?`}
          desc={`Are you sure to delete this product?`}
          alertType={'danger'}
          btnActionText={'Delete'}
          btnAction={async () => {
            const idArray = checkedItemList.map((item) => item.id);
            await deleteProductItems(idArray).then(() => {
              handleCloseAlertDialog();
              setCheckedItemList([]);
              setTopAlert({
                open: true,
                description: `Product${idArray.length > 1 ? 's' : ''} deleted successfully`,
                variant: 'filled',
                severity: 'success',
              });
            });
          }}
          open={openAlertDialog}
          handleClose={handleCloseAlertDialog}
        />
      ) : (
        <ProductPublishAndDraftDialogue
          open={openAlertDialog}
          onClose={handleCloseAlertDialog}
          isDraft={isDraftTab}
          isDeleteMode={true}
          checkedItemList={checkedItemList}
          setCheckedItemList={setCheckedItemList}
        />
      )}

      {/*{open && (*/}
      {/*<CustomAlertDialog*/}
      {/*  {...(isDraftedTab ? TO_PUBLISH : TO_DRAFT)}*/}
      {/*  open={open}*/}
      {/*  handleClose={handleCloseDialogue}*/}
      {/*  btnAction={isDraftedTab ? handlePublishClick : handleDraftClick}*/}
      {/*/>*/}
      {/*)}*/}
    </>
  );
}
