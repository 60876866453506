import React from 'react';
import { Card, Stack, Typography } from '@mui/material';

import { ProductDetail } from '../../../client';
import useTable from '../../../components/table/useTable';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { useAllProductList } from '../../../recoil/products/useProduct';
import ActiveTab from './ActiveTab';
import NewProductDialog from './NewProductDialog';
import ProductsTable from './ProductListTable';
import { ProductsTableToolbar } from './TableToolbar';

export type ProductStatusType = ProductDetail['status'];

// Add, Edit Product dialog 관련
const ProductsListPage = () => {
  const table = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0, defaultOrderBy: 'id', defaultOrder: 'desc' });
  const { dense, page, order, orderBy, rowsPerPage, onChangeDense, onChangePage, onChangeRowsPerPage } = table;
  const newProductDialog = useDialogue();

  const [activeTab, setActiveTab] = React.useState<ProductStatusType>('PUBLISHED');
  const { data, isPending } = useAllProductList({
    status: activeTab,
    page: page + 1,
    size: rowsPerPage,
    sort: [order, orderBy],
  });
  const productList = data?.data?.content ?? [];
  const handleTabChange = (_e: React.SyntheticEvent, newValue: ProductStatusType) => {
    table.setPage(0);
    setActiveTab(newValue);
  };

  return (
    <Stack gap="40px" sx={{ px: 7, py: 5 }}>
      <Typography variant="h3">Product List</Typography>
      <Card>
        <ActiveTab activeTab={activeTab} onTabChange={handleTabChange}>
          {activeTab === 'DRAFTED' && (
            <ProductsTableToolbar onOpenProductCreateDialogue={newProductDialog.handleOpenDialogue} />
          )}
          <ProductsTable
            page={page}
            dense={dense}
            totalElement={data?.data?.totalElements as number}
            activeTab={activeTab}
            productList={productList}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            onChangeDense={onChangeDense}
            onChangePage={onChangePage}
            setProductList={() => {}}
            isPending={isPending}
          />
        </ActiveTab>
      </Card>

      <NewProductDialog open={newProductDialog.open} onCloseDialog={newProductDialog.handleCloseDialogue} />
    </Stack>
  );
};

export default ProductsListPage;
