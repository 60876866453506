import React, { ReactNode } from 'react';

import { LoadingScreen } from '../components/loading/LoadingScreen';
import { useAuthInitialization } from './useAuthInitialization';

const AUTH_ERROR: { [key: string]: { title: string; message: string; redirectUrl: string } } = {
  USER_ROLE_NOT_ALLOWED: {
    title: '역할 제한',
    message: '해당 역할로 이 페이지에 접근할 수 없습니다.',
    redirectUrl: process.env.REACT_APP_CONNECT_CLIENT_URL as string,
  },
  default: {
    title: '접근 오류',
    message: '이 페이지에 접근할 수 없습니다.',
    redirectUrl: process.env.REACT_APP_CONNECT_CLIENT_URL as string,
  },
};

export const AuthHoc = ({ children }: { children: ReactNode }) => {
  const { isLoading, hasForbiddenError } = useAuthInitialization();

  if (isLoading) {
    return <LoadingScreen />;
  }

  // if (hasForbiddenError) {
  //   return (
  //     <div>
  //       <h1>접근이 제한되었습니다.</h1>
  //       <p>403 오류: 이 페이지에 접근할 수 없습니다.</p>
  //       <button onClick={() => window.location.replace(process.env.REACT_APP_CLOUD_CLIENT_URL as string)}>
  //         cloud로 이동
  //       </button>
  //     </div>
  //   );
  // }
  return <>{children}</>;
};
