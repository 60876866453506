import React, { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

import CustomDialog from '../../../components/dialog/CustomDialog';
import { InvoiceDTO } from '../../../recoil/dto/response/invoice.dto';
import { useInvoiceActions } from '../../../recoil/invoice/useInvoice';
import { SalesHistoryDetail } from './SaleHistoryDetail';
import SalesHistoryTable from './Table';

const SalesHistoryPage = () => {
  const { getInvoicesForAdminListing, getInvoiceById, allInvoiceListing, isLoading } = useInvoiceActions();
  // const { getAllLabNamesList, allLabList } = useRatingActions();
  const [openDetailDlg, setOpenDetailDlg] = useState(false);
  const [modifiedLabListing, setModifiedLabListing] = useState<string[]>();
  const [invoiceListing, setInvoiceListing] = useState<InvoiceDTO[]>([]);
  const [selectedInvoicesForInprogress, setSelectedInvoicesForInprogress] = useState<string[]>([]);
  const [invoiceListingLoading, setInvoiceListingLoading] = useState<boolean>(false);
  // useEffect(() => {
  //   setInvoiceListingLoading(true);
  //   getInvoicesForAdminListing({
  //     startDate: '',
  //     endDate: '',
  //     selectBy: '',
  //     searchValue: '',
  //     searchBy: '',
  //   }).then((res) => {
  //     setInvoiceListingLoading(false);
  //   });
  //   getAllLabNamesList();
  //
  //   // eslint-disable-next-line
  // }, []);
  // useEffect(() => {
  //   if (CHECK_ARRAY_IS_EMPTY_OR_NOT(allInvoiceListing)) {
  //     setInvoiceListing([...allInvoiceListing]);
  //     setInvoiceListingLoading(false);
  //   } else {
  //     setInvoiceListing([]);
  //     setInvoiceListingLoading(false);
  //   }
  //   // eslint-disable-next-line
  // }, [allInvoiceListing]);
  // useEffect(() => {
  //   const modifiedLabList = ['All'];
  //   if (allLabList?.length) {
  //     allLabList.map((item) => {
  //       if (item !== undefined) {
  //         modifiedLabList.push(item);
  //       }
  //     });
  //     setModifiedLabListing(modifiedLabList);
  //   }
  // }, [allLabList]);
  const getInvoiceByIdHandler = (id: string) => {
    if (id) {
      getInvoiceById(id).then((res) => {
        setOpenDetailDlg(true);
      });
    }
  };
  return (
    <Box className="page-wrapper" sx={{ px: 7, py: 5 }}>
      <Typography className="page-title" fontSize={24} fontWeight={600}>
        Sales History
      </Typography>
      {invoiceListingLoading ? (
        <div>
          <div
            style={{
              width: '83.5%',
              height: '83.5%',
              position: 'absolute',
              backgroundColor: 'grey',
              opacity: '0.3',
              zIndex: '11',
            }}
          ></div>
          <div
            className="no-data"
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              zIndex: '11',
            }}
          >
            <CircularProgress color="primary" size={50} style={{ marginLeft: '10px' }} />
          </div>
        </div>
      ) : null}
      {/*<SalesHistoryTableToolbar*/}
      {/*  allLabList={modifiedLabListing ? modifiedLabListing : []}*/}
      {/*  selectedInvoicesForInprogress={selectedInvoicesForInprogress}*/}
      {/*  setSelectedInvoicesForInprogress={setSelectedInvoicesForInprogress}*/}
      {/*  invoiceListing={invoiceListing}*/}
      {/*/>*/}
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        >
          <CircularProgress color="primary" size={40} />
        </Box>
      ) : (
        <SalesHistoryTable
          invoiceListing={invoiceListing}
          getInvoiceByIdHandler={getInvoiceByIdHandler}
          setSelectedInvoicesForInprogress={setSelectedInvoicesForInprogress}
          selectedInvoicesForInprogress={selectedInvoicesForInprogress}
        />
      )}
      <CustomDialog
        title="Detail"
        open={openDetailDlg}
        handleClose={() => {
          setOpenDetailDlg(false);
        }}
        btnAction={() => {}}
      >
        <SalesHistoryDetail />
      </CustomDialog>
    </Box>
  );
};

export default SalesHistoryPage;
