import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
  useSuspenseQuery,
  UseSuspenseQueryResult,
} from '@tanstack/react-query';
import axios from 'axios';

import {
  GetProductPricesData,
  optionGroup,
  PatchAdditionalPriceRequest,
  PatchProductInfo,
  ResponseDtoAdditionalPrice,
  ResponseDtoRemakePolicy,
} from '../../client';
import * as productApi from './product.api';
import {
  getAdditionalOptionPrices,
  getProsthesisColor,
  getRemakePolicy,
  patchProsthesisColor,
  putRemakePolicy,
} from './product.api';

// GET
export const useAllProductList = ({ status, page, size, sort }: GetProductPricesData) =>
  useQuery({ queryKey: ['get-products', status, page, size, sort], queryFn: productApi.getProductList });

export const useProductOptions = (option: optionGroup) =>
  useQuery({ queryKey: ['get-product-option', option], queryFn: productApi.getProductOptions });

export const useAllProductOptions = () => {
  const { data: prosthesisOption, isLoading: prosthesisLoading } = useProductOptions('PROSTHESIS');
  const { data: methodOption, isLoading: methodLoading } = useProductOptions('METHOD');
  const { data: materialOption, isLoading: materialLoading } = useProductOptions('MATERIAL');
  const { data: shapeOption, isLoading: shapeLoading } = useProductOptions('SHAPE');
  const queryLoading = prosthesisLoading || methodLoading || materialLoading || shapeLoading;
  return {
    queryLoading,
    prosthesisOption,
    methodOption,
    materialOption,
    shapeOption,
  };
};

export const useProsthesisColor = () => {
  return useQuery({ queryKey: ['prosthesis-color'], queryFn: getProsthesisColor });
};

export const useProsthesisColorMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchProsthesisColor,
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['get-products'] }),
  });
};

// POST
export const useProductItemMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: productApi.postProductItem,
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['get-products'] }),
  });
};

// PATCH
export const useProductItemEditMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, body }: { id: string; body: PatchProductInfo }) => productApi.patchProductItem(id, body),
    onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['get-products'] }),
  });
};

// POST Product option
export const useProductOptionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: productApi.postProductOptions,
    onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['get-product-option'] }),
  });
};

// Delete Product option
export const useProductOptionDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: productApi.deleteProductOption,
    onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['get-product-option'] }),
  });
};

// PATCH
export const useProductItemLifeCycleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: productApi.patchProductItemLifeCycle,
    onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['get-products'] }),
  });
};

// delete product
export const useProductItemDelete = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: productApi.deleteProductItem,
    onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['get-products'] }),
  });
};

//다중 delete product
export const useProductItemListDelete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: productApi.deleteProductListItem,
    onSettled: async () => await queryClient.invalidateQueries({ queryKey: ['get-products'] }),
  });
};

//additional options
export const useAdditionalOptionPricesQuery = (): UseSuspenseQueryResult<ResponseDtoAdditionalPrice> => {
  return useSuspenseQuery({
    queryKey: ['additionalOptionPrices'],
    queryFn: getAdditionalOptionPrices,
  });
};

export const usePatchAdditionalOptionPrices = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: PatchAdditionalPriceRequest) =>
      axios.patch(`connect/back-office/admin/additional-prices`, { ...data }).then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['additionalOptionPrices'] });
    },
  });
};

// remake
export const useRemakePolicyQuery = (): UseQueryResult<ResponseDtoRemakePolicy> => {
  return useQuery({
    queryKey: ['remakePolicy'],
    queryFn: getRemakePolicy,
  });
};

export const usePutRemakePolicyMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (content: string) => await putRemakePolicy(content),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['remakePolicy'] });
    },
  });
};
