import { useMutation } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { AxiosError, AxiosResponse } from 'axios';
import { ErrorResponseDTO } from '../dto/response/error.dto';
import * as InvoiceApi from './invoice.api';
import { SuccessResponseDTO } from '../dto/response/success.dto';
import { invoiceState } from './invoiceState';
import { InvoiceStateDTO } from '../dto/states/invoiceState.dto';
import { InvoiceDTO, InvoiceStatisticDTO } from '../dto/response/invoice.dto';
import { PaginatedResponseDTO } from '../dto/response/paginated.dto';

export function useInvoiceActions() {
  const [invoiceRecoilState, setInvoiceRecoilState] = useRecoilState<InvoiceStateDTO>(invoiceState);

  const { errorMessage, isLoading, isError, allInvoiceListing, invoiceForDetail, invoiceStatistics } =
    invoiceRecoilState;

  const getInvoicesForAdminListing = useMutation({
    mutationFn: InvoiceApi.getInvoicesForAdminListing,
    onMutate: () => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<InvoiceDTO>>>) => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        allInvoiceListing: response.data.data.results,
      }));
    },
  });

  const getInvoiceById = useMutation({
    mutationFn: InvoiceApi.getInvoiceById,
    onMutate: () => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<InvoiceDTO>>) => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        invoiceForDetail: response.data.data,
      }));
    },
  });

  const updateInvoiceStatusById = useMutation({
    mutationFn: InvoiceApi.updateInvoiceStatusById,
    onMutate: () => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<InvoiceDTO>>) => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const updateMultipleInvoiceForPublishing = useMutation({
    mutationFn: InvoiceApi.updateMultipleInvoiceForPublishing,
    onMutate: () => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<InvoiceDTO>>) => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
  });

  const getInvoiceStatictics = useMutation({
    mutationFn: InvoiceApi.getInvoiceStatictics,
    onMutate: () => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
    },
    onError: (error: AxiosError<ErrorResponseDTO>) => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        errorMessage: error?.response ? error?.response?.data?.message : '',
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<InvoiceStatisticDTO>>>) => {
      setInvoiceRecoilState((prevData) => ({
        ...prevData,
        isLoading: false,
        isError: true,
        invoiceStatistics: response.data.data.results,
      }));
    },
  });

  return {
    isLoading,
    isError,
    errorMessage,
    allInvoiceListing,
    invoiceForDetail,
    invoiceStatistics,
    getInvoicesForAdminListing: getInvoicesForAdminListing.mutateAsync,
    getInvoiceById: getInvoiceById.mutateAsync,
    updateInvoiceStatusById: updateInvoiceStatusById.mutateAsync,
    updateMultipleInvoiceForPublishing: updateMultipleInvoiceForPublishing.mutateAsync,
    getInvoiceStatictics: getInvoiceStatictics.mutateAsync,
  };
}
