import * as React from 'react';
import { TableCell, TableRow, TableRowProps, Typography } from '@mui/material';

export const TableNoData = ({
  style,
  colSpan,
  content,
}: {
  style: TableRowProps['style'];
  colSpan: number;
  content: string;
}) => {
  return (
    <TableRow style={{ ...style }}>
      <TableCell colSpan={colSpan} align="center">
        <Typography variant="subtitle2" color="text.secondary">
          {content}
        </Typography>
      </TableCell>
    </TableRow>
  );
};
