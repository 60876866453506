import { useQuery, UseQueryResult } from '@tanstack/react-query';
import axios from 'axios';

import { GetBackOfficeOrderRatingsData, ResponseDtoPageBackOfficeOrderRatingResponse } from '../../client';

export const useRatingListQuery = ({
  option,
}: {
  option: GetBackOfficeOrderRatingsData;
}): UseQueryResult<ResponseDtoPageBackOfficeOrderRatingResponse> => {
  return useQuery({
    queryKey: ['ratingList', JSON.stringify(option)],
    queryFn: () =>
      axios.get(`connect/back-office/admin/orders/ratings`, { params: { ...option } }).then((res) => res.data),
  });
};
