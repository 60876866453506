import { QueryFunctionContext } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

import { GetOrdersData, PageOrderResponse, ResponseDtoRemakeRateResponse } from '../../client';
import { OrderDTO } from '../dto/response/order.dto';
import { RemakeOrderResponseDateDTO } from '../dto/response/remake-response.dto';
import { SuccessResponseDTO } from '../dto/response/success.dto';

const GET_ALL_ADMIN_ORDERS = 'connect/orders';
const GET_ORDER_BY_ID = 'admin/orders';
const ASSIGN_ORDER_TO_LAB_BY_ADMIN = 'admin/orders/assign-to-lab';
const ORDER_REJECTION_BY_ADMIN = 'admin/orders/status/reject';
const GET_ORIGINAL_ORDER_DATE_OF_REMAKE_ORDER = 'admin/orders/originalOrder';
interface assignOrderToLabDTO {
  id: string;
  labOrganization: string;
}

export const getAllOrders = ({
  queryKey,
}: QueryFunctionContext<[string, GetOrdersData]>): Promise<AxiosResponse<PageOrderResponse>> => {
  const [_key, { page = '1', size = '10', sort = 'asc' }] = queryKey;
  return axios.get(`${GET_ALL_ADMIN_ORDERS}?page=${page}&size=${size}&sort=${sort}`);
};

export const getOrderById = (id: string): Promise<AxiosResponse<SuccessResponseDTO<OrderDTO>>> => {
  return axios.get(`${GET_ORDER_BY_ID}/${id}`);
};
export const assignOrderToLab = (
  assignedOrder: assignOrderToLabDTO,
): Promise<AxiosResponse<SuccessResponseDTO<OrderDTO>>> => {
  return axios.patch(`${ASSIGN_ORDER_TO_LAB_BY_ADMIN}/${assignedOrder.id}`, {
    labOrganization: assignedOrder.labOrganization,
  });
};
export const orderRejection = (id: string): Promise<AxiosResponse<SuccessResponseDTO<OrderDTO>>> => {
  return axios.patch(`${ORDER_REJECTION_BY_ADMIN}/${id}`);
};

export const getOrignalOrderDateOfRemakeOrder = (
  id: string,
): Promise<AxiosResponse<SuccessResponseDTO<RemakeOrderResponseDateDTO[]>>> => {
  return axios.get(`${GET_ORIGINAL_ORDER_DATE_OF_REMAKE_ORDER}/${id}`);
};

export const getOrdersUserInfo = async (targetUserId: string): Promise<ResponseDtoRemakeRateResponse> => {
  return await axios.get(`connect/back-office/admin/orders/users/${targetUserId}`).then((res) => res.data);
};
