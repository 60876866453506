import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  children: React.ReactNode;
}
// TODO: permission || role 확인
const PrivateRoute = ({ children }: IProps) => {
  const navigate = useNavigate();

  // const [admin] = useRecoilState<AuthStateDTO>(authState);
  // const { user } = admin;
  // // console.log(admin);
  // // console.log(user);
  //
  // useEffect(() => {
  //   if (!user || user?._id === '' || !user?.role) {
  //     // navigate('https://cloud.dev-dentbird.com');
  //   }
  //   // eslint-disable-next-line
  // }, [user]);

  return <>{children}</>;
};

export default PrivateRoute;
