import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Stack, SxProps, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { OrderDetailResponse } from '../../../client';
import { getFormatDateWithTime } from '../../../utils/formatDate';
import { STATUS_LABELS } from '../OrderList/statusLabel';

interface Iprops {
  orderDetailInfo: OrderDetailResponse;
  orignalOrderDateOfRemakeOrder?: { id: string; originalOrderDate: string }[];
}
export const OrderDetailInfo = ({ orderDetailInfo, orignalOrderDateOfRemakeOrder }: Iprops) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState<string>('');

  return (
    <Card sx={{ padding: '20px 10px' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <OrderInfoRow
          label={'User name'}
          value={`${orderDetailInfo.orderer.name.firstName} ${orderDetailInfo.orderer.name.lastName}`}
          sx={{ borderRight: `1px dashed ${alpha(theme.palette.divider, 0.2)}` }}
        />
        <OrderInfoRow
          label={'Organization name'}
          value={orderDetailInfo.orderer.organization.name ?? ''}
          sx={{ borderRight: `1px dashed ${alpha(theme.palette.divider, 0.2)}` }}
        />
        <OrderInfoRow
          label={'Patient name'}
          value={orderDetailInfo.patient.name ?? ''}
          sx={{ borderRight: `1px dashed ${alpha(theme.palette.divider, 0.2)}` }}
        />
        <OrderInfoRow
          label={'Order number'}
          value={orderDetailInfo.orderNumber ?? ''}
          sx={{ borderRight: `1px dashed ${alpha(theme.palette.divider, 0.2)}` }}
        />
        <OrderInfoRow
          label={'Ordered date'}
          value={getFormatDateWithTime(orderDetailInfo.orderedAt)}
          sx={{ borderRight: `1px dashed ${alpha(theme.palette.divider, 0.2)}` }}
        />
        <OrderInfoRow
          label={'Status'}
          value={STATUS_LABELS[orderDetailInfo.status] ?? ''}
          sx={{ borderRight: `1px dashed ${alpha(theme.palette.divider, 0.2)}` }}
        />
        <OrderInfoRow label={'Lab'} value={orderDetailInfo.lab?.organization?.name ?? '-'} />

        {/*{CHECK_ARRAY_IS_EMPTY_OR_NOT(orignalOrderDateOfRemakeOrder) ? (*/}
        {/*  <ListItem sx={{ minWidth: "97px", minHeight: "30px" }}>*/}
        {/*    <FormControl*/}
        {/*      sx={{*/}
        {/*        height: 30,*/}
        {/*        backgroundColor: "white",*/}
        {/*        "& .MuiInputBase-root": { height: 30 },*/}
        {/*        "& .MuiInputLabel-root": {*/}
        {/*          top: -11,*/}
        {/*          "&.MuiInputLabel-shrink": { display: "none" },*/}
        {/*        },*/}
        {/*        "& fieldset": {*/}
        {/*          border: "1px solid #6A6E74 !important",*/}
        {/*          "& legend": { display: "none" },*/}
        {/*        },*/}
        {/*      }}*/}
        {/*      style={{ width: "97px" }}*/}
        {/*    >*/}
        {/*      <InputLabel*/}
        {/*        id="demo-simple-select-label"*/}
        {/*        sx={{*/}
        {/*          fontWeight: 700,*/}
        {/*          fontSize: "14px",*/}
        {/*          color: "#333333 !important",*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        {"Remake"}*/}
        {/*      </InputLabel>*/}
        {/*      <Select*/}
        {/*        label={"Remake Order"}*/}
        {/*        labelId="demo-simple-select-label"*/}
        {/*        id="demo-simple-select"*/}
        {/*        value={selectedItem}*/}
        {/*        onChange={(e) => {*/}
        {/*          console.log(e);*/}
        {/*          navigate(*/}
        {/*            `/${APP_ROUTES.ORDER_DETAIL.ROUTE}/${e?.target.value}`,*/}
        {/*          );*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        {orignalOrderDateOfRemakeOrder?.map((item) => (*/}
        {/*          <MenuItem*/}
        {/*            onClick={() =>*/}
        {/*              setSelectedItem(item?.originalOrderDate)*/}
        {/*            }*/}
        {/*            value={item?.id}*/}
        {/*          >*/}
        {/*            {item?.originalOrderDate}*/}
        {/*          </MenuItem>*/}
        {/*        ))}*/}
        {/*      </Select>*/}
        {/*    </FormControl>*/}
        {/*  </ListItem>*/}
        {/*) : null}*/}
      </Stack>
    </Card>
  );
};

const OrderInfoRow = ({ label, value, sx }: { label: string; value: string; sx?: SxProps }) => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap="4px"
      sx={{
        flexBasis: '220px',
        flexGrow: 1,
        padding: '8px 0',
        ...sx,
      }}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <Typography variant="body2" color="text.disabled">
        {value}
      </Typography>
    </Stack>
  );
};
