import React, { MouseEvent } from 'react';

export const handleMouseEnter = (
  e: React.KeyboardEvent<HTMLDivElement>,
  onClick: (event: MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLDivElement>) => void,
  isBlur?: boolean,
) => {
  const target = e.target as HTMLDivElement;
  if (e.key === 'Enter') {
    onClick(e);
    if (isBlur) {
      target.blur();
    }
  }
};
