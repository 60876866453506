import * as React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { OrderTimeline } from '../../../../client';
import { getFormatDateWithTime } from '../../../../utils/formatDate';
import { STATUS_LABELS } from '../../OrderList/statusLabel';

export const TIMELINE = [
  { label: 'Design Uploaded', datetime: '2022-09-06 10:47 AM', createdBy: 'Lab', active: true },
  { label: 'Designing', datetime: '2022-09-06 10:47 AM', createdBy: 'you' },
  { label: 'Ordered', datetime: '2022-09-06 10:47 AM', createdBy: 'you' },
];
interface timeline {
  status: string;
  updatedBy: string;
  updated_At: string;
}

interface Iprops {
  timeline: OrderTimeline[];
  status: string;
}
export const OrderDesignTabTimeline = ({ timeline, status }: Iprops) => {
  return (
    <Stack sx={{ padding: '24px', width: '100%' }}>
      {timeline?.map((item, index) => (
        <TimelineRow
          key={`${item.afterStatus}_${index}`}
          detail={item}
          index={index}
          isLast={index === timeline.length - 1}
        />
      ))}
    </Stack>
  );
};

const TimelineRow = ({ detail, index, isLast }: { detail: OrderTimeline; index: number; isLast: boolean }) => {
  const theme = useTheme();
  const { afterStatus, updatedAt, updatedBy } = detail;
  const isRecent = index === 0;

  return (
    <Stack key={`${afterStatus}_${index}`} direction="row" gap="16px" sx={{ width: '100%' }}>
      <Stack direction="column" sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Stack
          sx={{
            width: 12,
            height: 12,
            margin: '12px 0',
            borderRadius: '50%',
            backgroundColor: isRecent ? 'primary.main' : alpha(theme.palette.success.main, 0.24),
          }}
        />
        <Divider orientation={'vertical'} sx={{ height: '32px' }} />
      </Stack>

      <Stack direction="column" sx={{ paddingTop: '6px' }}>
        <Typography variant="subtitle2">{STATUS_LABELS[afterStatus] || afterStatus}</Typography>
        <Typography variant="caption" color="text.disabled">
          {`${getFormatDateWithTime(updatedAt)} by ${updatedBy}`}
        </Typography>
      </Stack>
    </Stack>
  );
};
