import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { useMutation } from '@tanstack/react-query';

export function useSendbirdSdkInstance() {
  const { stores } = useSendbirdStateContext();
  const sdk = stores.sdkStore.sdk;
  // as SendBirdInstance | undefined; // Sendbird SDK instance
  if (!sdk) {
    return;
  }

  return sdk;
}

export function useInvitePartnerLab(channelUrl: string) {
  const sdk = useSendbirdSdkInstance();
  const groupChannel = sdk?.groupChannel;

  const invite = async (userId: string) => {
    const channel = await groupChannel?.getChannel(channelUrl);
    if (!channel) {
      throw new Error('Channel not found');
    }
    const res = await channel.inviteWithUserIds([userId]);
  };
  // not required reject option

  const useInviteMutation = () => {
    return useMutation({
      mutationFn: invite,
      retry: 3,
      retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
      onError: (error) => {
        console.error('Failed to invite:', error);
      },
    });
  };

  return { useInviteMutation };
}

export type UseCreateChannelProps = {
  orderId: string;
  ordererId: string;
  // partnerLabId: string;
  patientName: string;
};

export function useCreateChannel() {
  const sdk = useSendbirdSdkInstance();
  const groupChannel = sdk?.groupChannel;

  const createChannel = async ({ orderId, ordererId, patientName }: UseCreateChannelProps) => {
    if (!groupChannel) {
      console.error('SDK groupChannel not initialised');
      return;
    }
    const coverImage = await getCoverImage();

    const channelParams = {
      name: patientName,
      channelUrl: orderId,
      invitedUserIds: [ordererId, 'QC'],
      isDistinct: true,
      operatorUserIds: ['QC'],
      coverImage,
    };
    return await sdk.groupChannel.createChannel(channelParams);
  };

  return useMutation({
    mutationFn: createChannel,
    retry: 3,
    retryDelay: (attempt) => Math.min(attempt > 1 ? 2 ** attempt * 1000 : 1000, 30 * 1000),
  });
}

async function getCoverImage() {
  const res = await fetch('/DentbirdChatLogo.png');
  const blob = await res.blob();
  return new File([blob], 'DentbirdLogo.png', { type: blob.type });
}
