import React, { useState } from 'react';
import { Card, Stack, Typography } from '@mui/material';

import { SEARCH_TARGET_TYPE } from '../../components/selectInput/SearchTargetSelect';
import CommonFilterBar, { CaseToolbarDataTypes } from '../../components/table/CommonFilterBar';
import useTable from '../../components/table/useTable';
import { useRatingListQuery } from '../../recoil/rating/useRating';
import RatingListTable from './RaingListTable';

export enum FILTER_TYPE {
  USER_NAME = 'USER_NAME',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
}

const ratingSearchTargetList: SEARCH_TARGET_TYPE[] = [
  { id: FILTER_TYPE.USER_NAME, label: 'User name' },
  { id: FILTER_TYPE.ORGANIZATION_NAME, label: 'Organization name' },
];

const RatingPage = () => {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    selected,
    //
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    setPage,
  } = useTable({ defaultRowsPerPage: 10, defaultCurrentPage: 0 });
  const [extraFilter, setExtraFilter] = useState({});

  const { data, isPending } = useRatingListQuery({ option: { page: page + 1, size: rowsPerPage, ...extraFilter } });
  const ratingListData = data?.data?.content;
  const totalElement = data?.data?.totalElements ?? 0;

  const resetPage = () => {
    setPage(0);
  };

  const handleDataChange = (data: CaseToolbarDataTypes) => {
    const { searchText, searchTarget } = data;
    setExtraFilter({
      ...{ backOfficeRatingFilterType: !searchTarget && searchText ? FILTER_TYPE.USER_NAME : searchTarget },
      ...(searchText !== '' && { searchKeyword: searchText }),
    });
    resetPage();
  };

  return (
    <Stack gap="40px" sx={{ px: 7, py: 5 }}>
      <Typography variant="h3">Rating</Typography>
      <Card>
        <Stack sx={{ padding: '24px' }}>
          <CommonFilterBar
            filterList={['searchTarget', 'searchText']}
            resetPage={resetPage}
            onDataChange={handleDataChange}
            searchTargetList={ratingSearchTargetList}
            searchTargetName={'Search target'}
          />
        </Stack>
        <RatingListTable
          dense={dense}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangeDense={onChangeDense}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          ratingListData={ratingListData}
          totalElement={totalElement}
          isPending={isPending}
        />
      </Card>
    </Stack>
    // <Box className="page-wrapper" sx={{ px: 7, py: 5 }}>
    //   <Typography className="page-title" fontSize={24} fontWeight={600}>
    //     Rating
    //   </Typography>
    //   <RatingTableToolbar
    //     labList={lablistArray}
    //     allRatingList={allRatingList}
    //     setUserSearch={setUserSearch}
    //     userSearch={userSearch}
    //   />
    //   {isLoading ? (
    //     <Box
    //       sx={{
    //         display: 'flex',
    //         position: 'absolute',
    //         top: '50%',
    //         left: '50%',
    //       }}
    //     >
    //       <CircularProgress color="primary" size={40} />
    //     </Box>
    //   ) : (
    //     <RatingTable ratingList={allRatingList} setOpenDetailDlg={setOpenDetailDlg} />
    //   )}
    //
    //   <CustomDialog
    //     iconClose
    //     title="Rating Detail"
    //     titleSize={18}
    //     open={openDetailDlg}
    //     handleClose={() => setOpenDetailDlg(false)}
    //     customStyle={{
    //       border: '2px solid black !important',
    //       '& .MuiDialogContent-root': { pt: 0 },
    //     }}
    //   >
    //     <Box
    //       mx={-2.5}
    //       sx={{
    //         '& p, & ul': {
    //           m: 0,
    //           py: 3,
    //           px: 3,
    //           borderBottom: '2px solid #DEE1E5',
    //           color: '#637381',
    //         },
    //       }}
    //     >
    //       <Typography sx={{ borderTop: '2px solid #DEE1E5' }}>
    //         {ratingOrderForId?.patientName} / {ratingOrderForId?.orderNo}
    //       </Typography>
    //       <Typography>
    //         {ratingOrderForId?.user?.name?.fullName} <br />
    //         {ratingOrderForId?.clinicOrganization?.name} <br />
    //         {ratingOrderForId?.user?.email} <br />
    //       </Typography>
    //
    //       <Typography>
    //         <div style={{ color: '#637381', fontWeight: 700, fontSize: '14px', marginBottom: '4px', marginTop: '0px' }}>
    //           Comment
    //         </div>
    //         <textarea
    //           disabled={true}
    //           ref={textareaRef}
    //           className="notes-input"
    //           style={{
    //             lineHeight: '20px',
    //             resize: 'none',
    //             color: '#333333',
    //             fontSize: '14px',
    //             fontWeight: 400,
    //             fontFamily: 'Inter',
    //             padding: '5px 13px 10px 9px',
    //             borderRadius: '10px',
    //             width: '520px',
    //             minHeight: '125px',
    //             height: 'auto',
    //             maxHeight: '332px',
    //             // transition: "border-color 0.1s ease",
    //             backgroundColor: '#FFFFFF',
    //             borderColor: '#979797',
    //           }}
    //           value={ratingOrderForId.review}
    //         />
    //       </Typography>
    //
    //       <List sx={{ border: '0 !important', pb: '10px !important' }}>
    //         <Table
    //           sx={{
    //             borderWidth: '1px 1px 1px 0',
    //             borderStyle: 'solid',
    //             borderColor: ' #9199A3',
    //             '& thead th': { color: '#637381' },
    //             '& thead th, & tbody td': {
    //               fontSize: 14,
    //               py: 2.5,
    //               borderBottom: '1px solid #9199A3',
    //               borderLeft: '1px solid #9199A3',
    //             },
    //             '& tbody td': {
    //               fontWeight: 700,
    //               color: '#333333',
    //               '&:last-child': { color: '#637381', fontWeight: 400 },
    //             },
    //           }}
    //         >
    //           <TableHead>
    //             <TableRow>
    //               <TableCell>Product</TableCell>
    //               <TableCell align="right" sx={{ pr: 6 }}>
    //                 Rate
    //               </TableCell>
    //             </TableRow>
    //           </TableHead>
    //           <TableBody>
    //             {CHECK_ARRAY_IS_EMPTY_OR_NOT(ratingOrderForId?.products)
    //               ? ratingOrderForId?.products.map((item: Product) => {
    //                   return (
    //                     <TableRow>
    //                       <TableCell>
    //                         {item?.product}{' '}
    //                         {item?.backwardBridgeTeeth! ? `${item?.backwardBridgeTeeth?.number} - ` : ''}(
    //                         {item?.teeth?.number})
    //                         {item?.backwardBridgeTeeth! ? `- ${item?.forwardBridgeTeeth?.number}` : ''}
    //                       </TableCell>
    //                       <TableCell align="right" sx={{ pr: 5 }}>
    //                         {item?.teeth.rating}
    //                       </TableCell>
    //                     </TableRow>
    //                   );
    //                 })
    //               : null}
    //           </TableBody>
    //         </Table>
    //       </List>
    //     </Box>
    //   </CustomDialog>
    // </Box>
  );
};

export default RatingPage;
