import 'react-quill/dist/quill.snow.css';
import React from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import { SxProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

import EditorToolbar, { formats } from './EditorToolbar';
import { StyledEditor } from './styles';

interface TextEditorProps extends ReactQuillProps {
  id?: string;
  error?: boolean;
  helperText?: React.ReactNode;
  simple?: boolean;
  sx?: SxProps<Theme>;
}

const TextEditor = ({
  id = 'text-editor',
  error,
  value,
  onChange,
  helperText,
  simple = true,
  sx,
  ...other
}: TextEditorProps) => {
  const modules = {
    toolbar: {
      container: `#${id}`,
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true,
    },
    clipboard: {
      matchVisual: false,
    },
  };

  return (
    <>
      <StyledEditor
        sx={{
          ...sx,
        }}
      >
        <EditorToolbar id={id} isSimple={simple} />
        <ReactQuill
          value={value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder="Write a policy for remakes"
          {...other}
        />
      </StyledEditor>
      {helperText && helperText}
    </>
  );
};

export default TextEditor;
