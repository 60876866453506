import { Dayjs } from 'dayjs';

import InputlessDatePicker from './InputlessDatePicker';

type SelectInputSetProps = {
  inputFormat?: string;
  startDateValue: Dayjs | null;
  endDateValue: Dayjs | null;
  startDateOnChange: (date: Dayjs | null) => void;
  endDateOnChange: (date: Dayjs | null) => void;
};
const DatePickerRangeInput = ({
  inputFormat = 'yyyy-MM-dd',
  startDateValue,
  endDateValue,
  startDateOnChange,
  endDateOnChange,
}: SelectInputSetProps) => {
  return (
    <>
      <InputlessDatePicker
        label="Start Date(UTC+9)"
        placeholder="Start date"
        inputFormat={inputFormat}
        value={startDateValue}
        onChange={startDateOnChange}
      />
      <InputlessDatePicker
        label="End Date(UTC +9)"
        placeholder="End date"
        inputFormat={inputFormat}
        value={endDateValue}
        onChange={endDateOnChange}
      />
    </>
  );
};

export default DatePickerRangeInput;
