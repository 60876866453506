import React, { Suspense, useState } from 'react';
import { Box, Card, CircularProgress, Stack, Typography } from '@mui/material';

import { SEARCH_TARGET_TYPE } from '../../components/selectInput/SearchTargetSelect';
import CommonFilterBar, { CaseToolbarDataTypes } from '../../components/table/CommonFilterBar';
import useTable from '../../components/table/useTable';
import { useOrganizationActions } from '../../recoil/organizations/useOrganizations';
import OrganizationListTable from './OrganizationListTable';

export enum ORGANIZATION_FILTER_TYPE {
  ALL = '*',
  USER = 'USER',
  PARTNER_LAB = 'PARTNER_LAB',
}

const organizationTypeList: SEARCH_TARGET_TYPE[] = [
  { id: ORGANIZATION_FILTER_TYPE.ALL, label: 'All' },
  { id: ORGANIZATION_FILTER_TYPE.USER, label: 'Normal user' },
  { id: ORGANIZATION_FILTER_TYPE.PARTNER_LAB, label: 'Partner lab' },
];

const OrganizationsPage = () => {
  const { dense, page, order, orderBy, rowsPerPage, setPage, onChangeDense, onChangePage, onChangeRowsPerPage } =
    useTable({
      defaultRowsPerPage: 10,
      defaultCurrentPage: 0,
      defaultOrderBy: 'id',
      defaultOrder: 'desc',
    });
  const [extraFilter, setExtraFilter] = useState({});

  const resetPage = () => {
    setPage(0);
  };

  const { useOrganizationList } = useOrganizationActions();
  const { data, isPending } = useOrganizationList({
    page: page + 1,
    size: rowsPerPage,
    sort: [orderBy, order],
    ...extraFilter,
  });

  const organizationList = data?.data?.content ?? [];
  const totalElement = data?.data?.totalElements ?? 0;

  const handleDataChange = (data: CaseToolbarDataTypes) => {
    const { searchTarget, searchText } = data;
    setExtraFilter({
      organizationType: searchTarget,
      ...(searchText !== '' && { searchKeyword: searchText }),
    });
    resetPage();
  };

  return (
    <Stack gap="40px" sx={{ px: 7, py: 5 }}>
      <Typography variant="h3">Organization List</Typography>
      <Card>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '24px' }}>
          <CommonFilterBar
            filterList={['searchTarget', 'searchText']}
            resetPage={resetPage}
            onDataChange={handleDataChange}
            searchTargetName={'Organization type'}
            searchTargetList={organizationTypeList}
          />
        </Stack>
        <Suspense fallback={<Loading />}>
          <OrganizationListTable
            isPending={isPending}
            organizationList={organizationList}
            page={page}
            totalElement={totalElement}
            rowsPerPage={rowsPerPage}
            dense={dense}
            onChangePage={onChangePage}
            onChangeDense={onChangeDense}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </Suspense>
      </Card>
    </Stack>
  );
};

export default OrganizationsPage;

const Loading = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        top: '50%',
        left: '50%',
      }}
    >
      <CircularProgress color={'primary'} size={40} />
    </Box>
  );
};
