import moment from 'moment';
import * as XLSX from 'xlsx-js-style';

import { TEETH_DTO } from '../../recoil/dto/common/teethSvg.dto';
import { DashboardDto } from '../../recoil/dto/response/dashboard.dto';
import { InvoiceDTO } from '../../recoil/dto/response/invoice.dto';
import { OrderDTO } from '../../recoil/dto/response/order.dto';
import { DENTAL_NOTATION } from '../constants';

export const CHECK_ARRAY_IS_EMPTY_OR_NOT = (array?: any[]) => {
  if (array && array?.length) {
    return true;
  } else {
    return false;
  }
};

export const CONVER_ORDER_TYPE_INTO_READABLE_STRING = (type: string) => {
  if (type === 'DESIGN_AND_MANUFACTURE') {
    return 'Design and manufacture';
  } else if (type === 'DESIGN_ONLY') {
    return 'Design Only';
  } else {
    return 'Manufacture Only';
  }
};
export const CONVERT_READABLE_STRING_FOR_ORDERS_QUERY_PRAMAS = (value: string) => {
  if (value === 'Clinic OrganizationName') {
    return 'clinicOrganizationName';
  } else if (value === 'Lab OrganizationName') {
    return 'labOrganizationName';
  } else if (value === 'Patient Name') {
    return 'patientName';
  } else if (value === 'User Name') {
    return 'userName';
  } else if (value === 'Order No') {
    return 'orderNo';
  }
};
interface cardStats {
  label: string;
  value: number;
}

export const DashboardStatsData = [
  { label: 'Total', value: 0 },
  { label: 'Monthly', value: 0 },
  { label: 'Weekly', value: 0 },
  { label: 'Today', value: 0 },
];
export const GET_DASHBOARD_CARD_STATS = (cardStats: cardStats[], dashboardAnalytics: DashboardDto) => {
  if (CHECK_ARRAY_IS_EMPTY_OR_NOT(cardStats)) {
    return cardStats.map((item) => {
      return {
        label: item.label,
        value:
          item.label === 'Total'
            ? dashboardAnalytics.allOrders
            : item.label === 'Monthly'
              ? dashboardAnalytics.monthly
              : item.label === 'Weekly'
                ? dashboardAnalytics.weekly
                : item.label === 'Today'
                  ? dashboardAnalytics.today
                  : 0,
      };
    });
  } else {
    return DashboardStatsData;
  }
};

export const CHAT_STATISTICS_OPTIONS = {
  credits: false,
  chart: {
    type: 'column',
    height: 665,
  },

  title: {
    text: '',
    align: 'left',
  },

  xAxis: {
    categories: [],
    min: 1,
    max: 31,
  },

  yAxis: {
    allowDecimals: false,
    min: 0,
    title: {
      text: '',
    },
  },

  tooltip: {
    format: `<b>{key}</b><br/>{series.name}: {y}<br/>" + "Total: {point.stackTotal}`,
  },

  plotOptions: {
    column: {
      stacking: 'normal',
      pointPadding: 0.3,
      borderWidth: 5,
      borderRadius: 5,
    },
  },
  lagend: [],
  series: [],
};

interface SeriesData {
  name: string;
  color?: string;
  data: number[];
}
export const SET_CHAT_STATISTICS_OPTIONS_SERIES = (series: SeriesData[]) => {
  if (CHECK_ARRAY_IS_EMPTY_OR_NOT(series)) {
    return series.map((item) => {
      return {
        name: item.name,
        stack: 'single',
        color:
          item.name === 'Crown'
            ? '#00A3EC'
            : item.name === 'Pontic'
              ? '#0067A7'
              : item.name === 'Inlay'
                ? '#77CC46'
                : item.name === 'Onlay'
                  ? '#FFC912'
                  : item.name === 'Denture'
                    ? '#CC4646'
                    : '',
        data: item?.data?.length ? [...item.data] : [null],
      };
    });
  }
};

interface CommonArray {
  _id: string;
  key: string;
  value: string;
}
export const COVERT_ARRAY_INTO_DROPDOWN_STRUCTURE = (array: CommonArray[]) => {
  if (CHECK_ARRAY_IS_EMPTY_OR_NOT(array)) {
    return array.map((item) => {
      return { label: item.value, value: item.key, _id: item._id };
    });
  } else {
    return [{ label: '', value: '', _id: '' }];
  }
};

interface CommonCheckingArray {
  _id: string;
  label: string;
  value: string;
}
export const CHECK_ARRAY_CONTAIN_ELEMENT_OR_NOT = (array: CommonCheckingArray[], value: string) => {
  if (CHECK_ARRAY_IS_EMPTY_OR_NOT(array) && array.find((item) => item.label === value)) {
    return true;
  } else {
    return false;
  }
};

export interface ProductDTO {
  product: string;
  method: string;
  material: string;
  teeth: TeethDTO;
  forwardBridgeTeeth: BridgeTeethDTO;
  backwardBridgeTeeth: BridgeTeethDTO;
}

export interface BridgeTeethDTO {
  placement: string;
  number: number | string;
  readableNumber: number | string;
}

export interface TeethDTO {
  number: number;
  readableNumber: number;
  placement: string;
  rating: number;
  price?: number;
  currency?: string;
}

export interface GROUPED_BRIDGE_PRODUCTS_DTO {
  isBridge: boolean;
  products: ProductDTO[];
  productName: string;
  uuid: number;
  reviewRatting: number;
}

export const GROUP_PRODUCTS_BY_BRIDGE = (products: ProductDTO[]) => {
  let groupedProductsByBridge: GROUPED_BRIDGE_PRODUCTS_DTO[] = [];
  let sortedProducts = [...products];
  if (products?.length) {
    sortedProducts.sort((a: ProductDTO, b: ProductDTO) => a.teeth.readableNumber - b.teeth.readableNumber);

    for (let i = 0; i < sortedProducts.length; i++) {
      let groupedProductByBridge: GROUPED_BRIDGE_PRODUCTS_DTO = {
        isBridge: false,
        productName: '',
        products: [],
        uuid: 0,
        reviewRatting: 0,
      };
      if (sortedProducts[i].forwardBridgeTeeth && !sortedProducts[i].backwardBridgeTeeth) {
        groupedProductByBridge.isBridge = true;
        groupedProductByBridge.productName = 'Bridge';
        groupedProductByBridge.uuid = sortedProducts[i].teeth.readableNumber;
        groupedProductByBridge.reviewRatting = sortedProducts[i].teeth.rating;
        groupedProductByBridge.products.push(sortedProducts[i]);
        groupedProductsByBridge.push(groupedProductByBridge);
      } else if (sortedProducts[i].backwardBridgeTeeth && !sortedProducts[i].forwardBridgeTeeth) {
        groupedProductsByBridge = UPDATE_EXISTING_GROUP_BY_BRIDGE(groupedProductsByBridge, sortedProducts[i]);
      } else if (sortedProducts[i].backwardBridgeTeeth && sortedProducts[i].forwardBridgeTeeth) {
        groupedProductsByBridge = UPDATE_EXISTING_GROUP_BY_BRIDGE(groupedProductsByBridge, sortedProducts[i]);
      } else {
        groupedProductByBridge.productName = sortedProducts[i].product;
        groupedProductByBridge.uuid = sortedProducts[i].teeth.readableNumber;
        groupedProductByBridge.reviewRatting = sortedProducts[i].teeth.rating;
        groupedProductByBridge.products.push(sortedProducts[i]);
        groupedProductsByBridge.push(groupedProductByBridge);
      }
    }
  }

  return groupedProductsByBridge;
};

const UPDATE_EXISTING_GROUP_BY_BRIDGE = (
  groupedProductsByBridge: GROUPED_BRIDGE_PRODUCTS_DTO[],
  product: ProductDTO,
) => {
  for (let i = 0; i < groupedProductsByBridge.length; i++) {
    if (groupedProductsByBridge[i].isBridge === true) {
      for (let j = 0; j < groupedProductsByBridge[i].products.length; j++) {
        if (
          product.backwardBridgeTeeth.readableNumber === groupedProductsByBridge[i].products[j].teeth.readableNumber
        ) {
          groupedProductsByBridge[i].products.push(product);
          break;
        }
      }
    }
  }
  return groupedProductsByBridge;
};

export const CREATE_EXCEL_SHEET = (selectedYear: string, labs: string, data: any) => {
  const exportDate = {
    col1: 'Year',
    col2: selectedYear,
  };
  const labName = {
    col1: 'Lab',
    col2: labs === 'Search By' ? 'All labs' : labs,
  };
  const gap = { col1: '' };
  const months = {
    col1: '',
    col2: 'Jan',
    col3: 'Feb',
    col4: 'Mar',
    col5: 'Apr',
    col6: 'May',
    col7: 'Jun',
    col8: 'Jul',
    col9: 'Aug',
    col10: 'Sep',
    col11: 'Oct',
    col12: 'Nov',
    col13: 'Dec',
    col14: 'Sum',
  };
  const dataNumberAccordingToMonth = {
    col1: 'Number',
    col2: `${data.number.jan}`,
    col3: `${data.number.feb}`,
    col4: `${data.number.mar}`,
    col5: `${data.number.apr}`,
    col6: `${data.number.may}`,
    col7: `${data.number.jun}`,
    col8: `${data.number.jul}`,
    col9: `${data.number.aug}`,
    col10: `${data.number.sep}`,
    col11: `${data.number.oct}`,
    col12: `${data.number.nov}`,
    col13: `${data.number.dec}`,
    col14: `${data.number.totalCount}`,
  };
  const dataAmountAccordingToMonth = {
    col1: 'Amount(JPY)',
    col2: `${data.uniquePrice.jan}`,
    col3: `${data.uniquePrice.feb}`,
    col4: `${data.uniquePrice.mar}`,
    col5: `${data.uniquePrice.apr}`,
    col6: `${data.uniquePrice.may}`,
    col7: `${data.uniquePrice.jun}`,
    col8: `${data.uniquePrice.jul}`,
    col9: `${data.uniquePrice.aug}`,
    col10: `${data.uniquePrice.sep}`,
    col11: `${data.uniquePrice.oct}`,
    col12: `${data.uniquePrice.nov}`,
    col13: `${data.uniquePrice.dec}`,
    col14: `${data.uniquePrice.totalAmountCount}`,
  };

  const combinedData = [exportDate, labName, gap, gap, months, dataNumberAccordingToMonth, dataAmountAccordingToMonth];

  const wb = XLSX.utils.book_new();

  const combinedWs = XLSX.utils.json_to_sheet(combinedData, {
    skipHeader: true,
  });
  combinedWs['A1'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['A2'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['A6'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['A7'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['B5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['C5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['D5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['E5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['F5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['G5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['H5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['I5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['J5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['K5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['L5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['M5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['N5'].s = {
    font: {
      bold: true,
    },
  };

  autoFitColumns(combinedWs);
  XLSX.utils.book_append_sheet(wb, combinedWs, 'CombinedData');

  XLSX.writeFile(wb, `${selectedYear}_${labs === 'Search By' ? 'All labs' : labs}_SalesStatistics.xlsx`);
};
export const CREATE_EXCEL_SHEET_FOR_ORDERS = (orderList: OrderDTO[], dateStart: string, dateEnd: string) => {
  const startDate = {
    col1: 'Start Date',
    col2: dateStart,
  };
  const endDate = {
    col1: 'End Date',
    col2: dateEnd,
  };
  const gap = { col1: '' };
  const orderHeader = {
    col1: 'User Name',
    col2: 'Organization Name',
    col3: 'Pt Name',
    col4: 'Order Number',
    col5: 'Lab',
    col6: 'Created Date',
    col7: 'Status',
  };
  const allOrder: any[] = [];
  if (orderList && orderList.length) {
    for (let i = 0; i < orderList?.length; i++) {
      if (!allOrder.find((item) => item._id === orderList[i]._id)) {
        allOrder.push({
          col1: orderList[i].user?.name?.fullName ? orderList[i].user?.name?.fullName : '',
          col2: orderList[i].clinicOrganization?.name ? orderList[i].clinicOrganization?.name : '',
          col3: orderList[i].patientName,
          col4: orderList[i].orderNo,
          col5: orderList[i].labOrganization?.name,
          col6: moment(orderList[i].createdAt).format('YYYY-MM-DD'),
          col7: orderList[i].status,
        });
      }
    }
  }

  const combinedData = [startDate, endDate, gap, gap, orderHeader, ...allOrder];

  const wb = XLSX.utils.book_new();

  const combinedWs = XLSX.utils.json_to_sheet(combinedData, {
    skipHeader: true,
  });
  combinedWs['A1'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['A2'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['A5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['B5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['C5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['D5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['E5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['F5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['G5'].s = {
    font: {
      bold: true,
    },
  };

  autoFitColumns(combinedWs);
  XLSX.utils.book_append_sheet(wb, combinedWs, 'CombinedData');

  XLSX.writeFile(wb, `${moment().format('YYMMDD')}_AdminOrdersList.xlsx`);
};
export const CREATE_EXCEL_SHEET_FOR_SALES_HISTORY = (saleHistory: InvoiceDTO[], dateStart: string, dateEnd: string) => {
  const startDate = {
    col1: 'Start Date',
    col2: dateStart,
  };
  const endDate = {
    col1: 'End Date',
    col2: dateEnd,
  };
  const gap = { col1: '' };
  const orderHeader = {
    col1: 'User Name',
    col2: 'Organization Name',
    col3: 'Pt Name',
    col4: 'Order Number',
    col5: 'Amount (JPY)',
    col6: 'Completed Date',
    col7: 'Lab',
    col8: 'Status',
  };
  const allSales: any[] = [];
  if (saleHistory && saleHistory.length) {
    for (let i = 0; i < saleHistory?.length; i++) {
      if (!allSales.find((item) => item._id === saleHistory[i]._id)) {
        allSales.push({
          col1: saleHistory[i].user?.name?.fullName ? saleHistory[i].user?.name?.fullName : '',
          col2: saleHistory[i].clinicOrganization?.name ? saleHistory[i].clinicOrganization?.name : '',
          col3: saleHistory[i]?.order?.patientName,
          col4: saleHistory[i]?.order?.orderNo,
          col5: `${saleHistory[i]?.order?.productPrice.total}`,
          col6: moment(saleHistory[i].updatedAt).format('YYYY-MM-DD'),
          col7: saleHistory[i].labOrganization?.name ? saleHistory[i].labOrganization?.name : '',
          col8: saleHistory[i].status,
        });
      }
    }
  }

  const combinedData = [startDate, endDate, gap, gap, orderHeader, ...allSales];

  const wb = XLSX.utils.book_new();

  const combinedWs = XLSX.utils.json_to_sheet(combinedData, {
    skipHeader: true,
  });
  combinedWs['A1'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['A2'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['A5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['B5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['C5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['D5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['E5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['F5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['G5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['H5'].s = {
    font: {
      bold: true,
    },
  };

  autoFitColumns(combinedWs);
  XLSX.utils.book_append_sheet(wb, combinedWs, 'CombinedData');

  XLSX.writeFile(wb, `SalesHistory_${moment().format('YYMMDD')}.xlsx`);
};
export const CREATE_EXCEL_SHEET_FOR_RATING = (
  rattingList: OrderDTO[],
  dateStart: string,
  dateEnd: string,
  searchBy: string,
) => {
  const startDate = {
    col1: 'Start Date',
    col2: dateStart,
  };
  const endDate = {
    col1: 'End Date',
    col2: dateEnd,
  };
  const gap = { col1: '' };
  const orderHeader = {
    col1: 'User Name',
    col2: 'Organization Name',
    col3: 'Email',
    col4: 'Pt Name',
    col5: 'Lab',
    col6: 'Average',
  };
  const allRatting: any[] = [];
  if (rattingList && rattingList.length) {
    for (let i = 0; i < rattingList?.length; i++) {
      if (!allRatting.find((item) => item._id === rattingList[i]._id)) {
        allRatting.push({
          col1: rattingList[i].user?.name?.fullName ? rattingList[i].user?.name?.fullName : '',
          col2: rattingList[i].clinicOrganization?.name ? rattingList[i].clinicOrganization?.name : '',
          col3: rattingList[i]?.user?.email,
          col4: rattingList[i]?.patientName,
          col5: rattingList[i].labOrganization?.name ? rattingList[i].labOrganization?.name : '',
          col6: rattingList[i].productAverage,
        });
      }
    }
  }

  const combinedData = [startDate, endDate, gap, gap, orderHeader, ...allRatting];

  const wb = XLSX.utils.book_new();

  const combinedWs = XLSX.utils.json_to_sheet(combinedData, {
    skipHeader: true,
  });
  combinedWs['A1'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['A2'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['A5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['B5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['C5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['D5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['E5'].s = {
    font: {
      bold: true,
    },
  };
  combinedWs['F5'].s = {
    font: {
      bold: true,
    },
  };
  autoFitColumns(combinedWs);
  XLSX.utils.book_append_sheet(wb, combinedWs, 'CombinedData');

  XLSX.writeFile(wb, `${searchBy === 'searchBy' ? 'AllLabs' : searchBy}_RatingHistory.xlsx`);
};

function autoFitColumns(worksheet: XLSX.WorkSheet): void {
  //@ts-ignore
  const [firstCol, lastCol] = worksheet['!ref']?.replace(/\d/, '').split(':');

  const numRegexp = new RegExp(/\d+$/g);

  const firstColIndex = firstCol.charCodeAt(0),
    lastColIndex = lastCol.charCodeAt(0),
    //@ts-ignore
    rows = +numRegexp.exec(lastCol)[0];

  //@ts-ignore
  const objectMaxLength: XLSX.ColInfo[] = [];

  // Loop on columns
  for (let colIndex = firstColIndex; colIndex <= lastColIndex; colIndex++) {
    const col = String.fromCharCode(colIndex);
    let maxCellLength = 0;

    // Loop on rows
    for (let row = 1; row <= rows; row++) {
      if (worksheet[`${col}${row}`] && worksheet[`${col}${row}`]?.v?.length) {
        const cellLength = worksheet[`${col}${row}`].v.length + 1;
        if (cellLength > maxCellLength) maxCellLength = cellLength;
      }
    }

    objectMaxLength.push({ width: maxCellLength });
  }
  worksheet['!cols'] = objectMaxLength;
}

export const CHECK_TEETH_NUMBER_BY_USER_SETTING = (teeth: TEETH_DTO, teethNotation: string) => {
  // if (teethNotation === DENTAL_NOTATION.PALMER_SYSTEM) {
  //   if (teeth.number === 55 || teeth.number === "A") {
  //     return "A";
  //   } else if (teeth.number === 54 || teeth.number === "B") {
  //     return "B";
  //   } else if (teeth.number === 53 || teeth.number === "C") {
  //     return "C";
  //   } else if (teeth.number === 52 || teeth.number === "D") {
  //     return "D";
  //   } else if (teeth.number === 51 || teeth.number === "E") {
  //     return "E";
  //   } else if (teeth.number === 61 || teeth.number === "F") {
  //     return "F";
  //   } else if (teeth.number === 62 || teeth.number === "G") {
  //     return "G";
  //   } else if (teeth.number === 63 || teeth.number === "H") {
  //     return "H";
  //   } else if (teeth.number === 64 || teeth.number === "I") {
  //     return "I";
  //   } else if (teeth.number === 65 || teeth.number === "J") {
  //     return "J";
  //   } else if (teeth.number === 75 || teeth.number === "K") {
  //     return "K";
  //   } else if (teeth.number === 74 || teeth.number === "L") {
  //     return "L";
  //   } else if (teeth.number === 73 || teeth.number === "M") {
  //     return "M";
  //   } else if (teeth.number === 72 || teeth.number === "N") {
  //     return "N";
  //   } else if (teeth.number === 71 || teeth.number === "O") {
  //     return "O";
  //   } else if (teeth.number === 81 || teeth.number === "P") {
  //     return "P";
  //   } else if (teeth.number === 82 || teeth.number === "Q") {
  //     return "Q";
  //   } else if (teeth.number === 83 || teeth.number === "R") {
  //     return "R";
  //   } else if (teeth.number === 84 || teeth.number === "S") {
  //     return "S";
  //   } else if (teeth.number === 85 || teeth.number === "T") {
  //     return "T";
  //   } else {
  //     return teeth.readableNumber;
  //   }
  // }
  // else if (teethNotation === DENTAL_NOTATION.FDA_SYSTEM) {
  //   if (teeth.number === 55 || teeth.number === "A") {
  //     return 55;
  //   } else if (teeth.number === 54 || teeth.number === "B") {
  //     return 54;
  //   } else if (teeth.number === 53 || teeth.number === "C") {
  //     return 53;
  //   } else if (teeth.number === 52 || teeth.number === "D") {
  //     return 52;
  //   } else if (teeth.number === 51 || teeth.number === "E") {
  //     return 51;
  //   } else if (teeth.number === 61 || teeth.number === "F") {
  //     return 61;
  //   } else if (teeth.number === 62 || teeth.number === "G") {
  //     return 62;
  //   } else if (teeth.number === 63 || teeth.number === "H") {
  //     return 63;
  //   } else if (teeth.number === 64 || teeth.number === "I") {
  //     return 64;
  //   } else if (teeth.number === 65 || teeth.number === "J") {
  //     return 65;
  //   } else if (teeth.number === 75 || teeth.number === "K") {
  //     return 75;
  //   } else if (teeth.number === 74 || teeth.number === "L") {
  //     return 74;
  //   } else if (teeth.number === 73 || teeth.number === "M") {
  //     return 73;
  //   } else if (teeth.number === 72 || teeth.number === "N") {
  //     return 72;
  //   } else if (teeth.number === 71 || teeth.number === "O") {
  //     return 71;
  //   } else if (teeth.number === 81 || teeth.number === "P") {
  //     return 81;
  //   } else if (teeth.number === 82 || teeth.number === "Q") {
  //     return 82;
  //   } else if (teeth.number === 83 || teeth.number === "R") {
  //     return 83;
  //   } else if (teeth.number === 84 || teeth.number === "S") {
  //     return 84;
  //   } else if (teeth.number === 85 || teeth.number === "T") {
  //     return 85;
  //   } else {
  //     return teeth.number;
  //   }
  // }

  return teeth?.number;
};

export const getSelectedProductColor = (product: string) => {
  if (product === 'Crown') {
    return { teethFill: '#0083CA', roundFill: '#E5F2F9' };
  } else if (product === 'Inlay' || product === 'Onlay') {
    return { teethFill: '#961414', roundFill: '#F9E9E9' };
  } else if (product === 'Pontic') {
    return { teethFill: '#00808C', roundFill: 'rgba(0, 164, 177, 0.08)' };
  } else if (product === 'Denture') {
    return { teethFill: '#449715', roundFill: 'rgba(119, 204, 70, 0.08)' };
  } else if (product === 'Coping') {
    return { teethFill: 'rgba(255, 201, 18, 1)', roundFill: 'rgba(255, 201, 18, 0.08)' };
  } else {
    return { teethFill: '#0083CA', roundFill: '#E5F2F9' };
  }
};

export const generatePlacementShortCode = (placement: string) => {
  if (placement === 'UPPER_LEFT') {
    return 'UL';
  } else if (placement === 'UPPER_RIGHT') {
    return 'UR';
  } else if (placement === 'BOTTOM_LEFT') {
    return 'LL';
  } else if (placement === 'BOTTOM_RIGHT') {
    return 'LR';
  } else {
    return '';
  }
};
