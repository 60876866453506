import React, { useEffect, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import { ColorPair } from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';

export const DEFAULT_COLOR_SET = { stroke: '', fill: '' };

const PALETTE_OPTION = [
  { name: 'Dark Blue', stroke: '#0D3B66', fill: '#E5F2F9' },
  { name: 'Brick Red', stroke: '#C0392B', fill: '#F9EBEA' },
  { name: 'Forest Green', stroke: '#2E7D32', fill: '#E8F5E9' },
  { name: 'Tangerine', stroke: '#EF6C00', fill: '#FFF3E0' },
  { name: 'Purple', stroke: '#0277BD', fill: '#E1F5FE' },
  { name: 'Maroon', stroke: '#662222', fill: '#F9E5E5' },
  { name: 'Sky Blue', stroke: '#8E24AA', fill: '#F3E5F5' },
  { name: 'Coral', stroke: '#E91E63', fill: '#FFF0F0' },
  { name: 'Deep Purple', stroke: '#3949AB', fill: '#E8EAF6' },
  { name: 'Golden Yellow', stroke: '#F9A825', fill: '#FFFDE7' },
  { name: 'Dark Lavender', stroke: '#5E35B1', fill: '#EDE7F6' },
  { name: 'Light Green', stroke: '#7CB342', fill: '#F1F8E9' },
  { name: 'Dark Gray', stroke: '#607D8B', fill: '#ECEFF1' },
  { name: 'Olive Green', stroke: '#9E9D24', fill: '#F9FBE7' },
  { name: 'Slate Gray', stroke: '#424242', fill: '#F3F3F3' },
  { name: 'Bright Pink', stroke: '#D84315', fill: '#FBE9E7' },
];

interface ColorSelectorProps {
  selectedColor: ColorPair;
  onColorChange: (color: ColorPair) => void;
}

export default function ColorSelector({ selectedColor, onColorChange }: ColorSelectorProps) {
  const { open, handleOpenDialogue, handleCloseDialogue } = useDialogue();

  const colorName = PALETTE_OPTION.find((color) => color.stroke === selectedColor.stroke)?.name;

  return (
    <>
      <Stack gap="8px">
        <Box>
          <Typography variant="subtitle1">Color</Typography>
        </Box>

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap="8px">
            <Box
              sx={{
                backgroundColor: selectedColor.stroke,
                width: '28px',
                height: '28px',
                border: '1px solid #CDD1D5',
                borderRadius: '6px',
              }}
            />
            <Typography variant="body1">{colorName ?? 'None selected'}</Typography>
          </Stack>
          <Button variant="outlined" size="medium" onClick={handleOpenDialogue}>
            Select color
          </Button>
        </Stack>
      </Stack>

      <ColorDialog open={open} onClose={handleCloseDialogue} color={selectedColor} onColorSelect={onColorChange} />
    </>
  );
}

interface ColorDialogProps {
  open: boolean;
  onClose: () => void;
  color: ColorPair;
  onColorSelect: (color: ColorPair) => void;
}
function ColorDialog({ open, onClose, color, onColorSelect }: ColorDialogProps) {
  const [innerColorSelected, setInnerColorSelected] = useState({ stroke: '#0D3B66', fill: '#E5F2F9' });
  const handleInnerColorClick = (color: ColorPair) => {
    setInnerColorSelected(color);
  };

  useEffect(() => {
    if (color.stroke === '' || color.fill === '') {
      setInnerColorSelected({ stroke: '#0D3B66', fill: '#E5F2F9' });
    } else {
      setInnerColorSelected(color);
    }
  }, [open]);

  return (
    <CustomDialog
      open={open}
      title="Select color"
      handleClose={onClose}
      btnActionText={'Apply'}
      btnAction={() => {
        onColorSelect(innerColorSelected);
        onClose();
      }}
    >
      <Stack direction="row" justifyContent="space-between" gap="70px" sx={{ marginBottom: '40px' }}>
        <Stack gap="8px">
          <Box>
            <Typography fontWeight="700" fontSize="14px" lineHeight="20px">
              Color
            </Typography>
          </Box>
          <Stack direction="row" gap="4px" sx={{ width: 'fit-content', flexWrap: 'wrap' }}>
            {PALETTE_OPTION.map((color) => (
              <ColorBox
                key={color.name}
                color={color}
                isSelected={innerColorSelected.stroke === color.stroke}
                onInnerColorClick={handleInnerColorClick}
              />
            ))}
          </Stack>
        </Stack>

        <Stack gap="8px">
          <Box>
            <Typography fontWeight="700" fontSize="14px" lineHeight="20px">
              Preview
            </Typography>
          </Box>
          <Stack
            sx={{
              width: '96px',
              height: '96px',
              border: '1px solid #DFE3E8',
              borderRadius: '4px',
              position: 'relative',
            }}
          >
            <PreviewTooth tooth={PREVIEW_UI} colorSet={innerColorSelected} />
          </Stack>
        </Stack>
      </Stack>
    </CustomDialog>
  );
}

const ColorBox = ({
  color,
  isSelected,
  onInnerColorClick,
}: {
  color: ColorPair;
  isSelected: boolean;
  onInnerColorClick: (colorSet: { stroke: string; fill: string }) => void;
}) => {
  return (
    <Box
      sx={{
        width: '44px',
        height: '44px',
        padding: '2px',
        borderRadius: '8px',
      }}
      border={isSelected ? '1px solid #637381' : '1px solid #FFF'}
      onClick={() => onInnerColorClick(color)}
    >
      <Box sx={{ width: '38px', height: '38px', backgroundColor: color.stroke, borderRadius: '8px' }} />
    </Box>
  );
};

// Preview
type SvgToothUIType = { tooth: { d: string }; text: { d: string } };

const PREVIEW_UI = {
  tooth: {
    d: 'M8.98628 46.9005C4.10405 45.2731 2.69836 38.081 2.35939 32.6562C2.16844 29.6005 0.230051 21.3938 1.34214 14.3479C2.86724 4.68512 22.7015 0.164932 30.8586 1.12681C39.484 2.1439 45.1015 13.335 46.6237 27.0664C48.4591 43.6239 42.1123 46.3299 36.9574 46.9005C27.8073 47.9132 15.0891 48.9347 8.98628 46.9005Z',
  },
  text: {
    d: 'M23.2994 29.5259C22.7757 29.5226 22.2702 29.4348 21.783 29.2624C21.2991 29.0901 20.8649 28.81 20.4805 28.4222C20.096 28.0344 19.7911 27.5207 19.5657 26.881C19.3436 26.2414 19.2326 25.4558 19.2326 24.5245C19.2359 23.6694 19.3337 22.9054 19.5259 22.2326C19.7215 21.5565 19.9999 20.9831 20.3612 20.5124C20.7257 20.0418 21.1616 19.6838 21.6687 19.4386C22.1758 19.19 22.7442 19.0657 23.3739 19.0657C24.0534 19.0657 24.6533 19.1983 25.1737 19.4634C25.694 19.7253 26.1116 20.0816 26.4265 20.5323C26.7447 20.9831 26.9369 21.4885 27.0032 22.0487H24.8803C24.7975 21.694 24.6235 21.4156 24.3583 21.2134C24.0932 21.0112 23.765 20.9102 23.3739 20.9102C22.7111 20.9102 22.2073 21.1985 21.8626 21.7752C21.5212 22.3519 21.3472 23.1358 21.3406 24.1268H21.4102C21.5626 23.8252 21.7681 23.5683 22.0266 23.3562C22.2885 23.1407 22.5851 22.9767 22.9165 22.864C23.2513 22.748 23.6043 22.69 23.9755 22.69C24.5787 22.69 25.1156 22.8325 25.5863 23.1175C26.0569 23.3993 26.4281 23.787 26.6999 24.2809C26.9717 24.7747 27.1076 25.3398 27.1076 25.9762C27.1076 26.6656 26.9469 27.2788 26.6254 27.8157C26.3072 28.3526 25.8614 28.7736 25.288 29.0785C24.7179 29.3801 24.055 29.5292 23.2994 29.5259ZM23.2894 27.8356C23.6209 27.8356 23.9175 27.756 24.1793 27.5969C24.4412 27.4379 24.6467 27.2224 24.7958 26.9506C24.945 26.6789 25.0195 26.3739 25.0195 26.0359C25.0195 25.6978 24.945 25.3945 24.7958 25.1261C24.65 24.8576 24.4478 24.6438 24.1893 24.4847C23.9308 24.3256 23.6358 24.2461 23.3043 24.2461C23.0558 24.2461 22.8254 24.2925 22.6133 24.3853C22.4045 24.4781 22.2205 24.6074 22.0614 24.7731C21.9057 24.9388 21.783 25.131 21.6935 25.3498C21.604 25.5652 21.5593 25.7956 21.5593 26.0408C21.5593 26.369 21.6339 26.6689 21.783 26.9407C21.9355 27.2125 22.141 27.4296 22.3995 27.592C22.6613 27.7544 22.958 27.8356 23.2894 27.8356Z',
  },
};

const PreviewTooth = ({ tooth, colorSet }: { colorSet: ColorPair; tooth: SvgToothUIType }) => {
  return (
    <Box component="svg" sx={{ position: 'relative', top: '25%', left: '25%' }}>
      <Box component={'path'} d={tooth.tooth.d} fill={colorSet.fill} stroke={colorSet.stroke} />
      <Box component={'path'} d={tooth.text.d} fill={colorSet.stroke} />
    </Box>
  );
};
