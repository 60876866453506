import React, { useState } from 'react';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { BackOfficeOrderRatingResponse } from '../../client';
import Iconify from '../../components/iconify/Iconify';
import MenuPopover from '../../components/popover/MenuPopover';
import CustomDataTable, { ITableColumnTypes } from '../../components/table/DataTable';
import { TableLoading } from '../../components/table/TableLoading';
import { TableNoData } from '../../components/table/TableNoData';
import { TablePropsReturnType } from '../../components/table/types';
import { useDialogue } from '../../constants/helperHooks/useDialogue';
import { RatingDetailDialogue } from './RatingDetailDialogue';

const tableColumn: ITableColumnTypes[] = [
  { id: 'username', label: 'User name', maxWidth: 240, alignCol: 'left' },
  {
    id: 'organizationName',
    label: 'Organization name',
    maxWidth: 300,
    alignCol: 'left',
  },
  { id: 'email', label: 'Email', maxWidth: 279, alignCol: 'left' },
  { id: 'patientName', label: 'Patient name', maxWidth: 230, alignCol: 'left' },
  { id: 'lab', label: 'Lab', maxWidth: 276, alignCol: 'left' },
  { id: 'average', label: 'Average', maxWidth: 140, alignCol: 'center' },
  { id: 'actions', label: '', maxWidth: 87, alignCol: 'center' },
];

type RatingListTablePropsType = Partial<TablePropsReturnType> & {
  ratingListData: BackOfficeOrderRatingResponse[] | undefined;
  totalElement: number;
  isPending: boolean;
};

export default function RatingListTable(props: RatingListTablePropsType) {
  const {
    isPending,
    ratingListData,
    onChangePage,
    totalElement,
    onChangeRowsPerPage,
    onChangeDense,
    page,
    dense,
    rowsPerPage,
  } = props;

  const [openMenu, setOpenMenu] = useState<HTMLElement | null>(null);
  const { open: dialogueOpen, handleOpenDialogue, handleCloseDialogue } = useDialogue();

  const [selectedOrder, setSelectedOrder] = useState<BackOfficeOrderRatingResponse>();

  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  return (
    <>
      <CustomDataTable
        tableColumn={tableColumn}
        rowsPerPage={rowsPerPage}
        page={page}
        totalElement={totalElement}
        dense={dense}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangeDense={onChangeDense}
      >
        {isPending ? (
          <TableLoading style={{ height: '344px' }} colSpan={tableColumn.length} />
        ) : ratingListData && ratingListData.length > 0 ? (
          ratingListData.map((rating, index) => {
            return (
              <TableRow key={rating.orderId}>
                <TableCell
                  align="left"
                  sx={{ maxWidth: 240 }}
                >{`${rating.orderer.name.firstName} ${rating.orderer.name.lastName}`}</TableCell>
                <TableCell align="left" sx={{ maxWidth: 300 }}>
                  {rating.orderer.organization.name}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: 279 }}>
                  {rating.orderer.email}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: 230 }}>
                  {rating.patientName}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: 276 }}>
                  {rating.lab?.organization.name}
                </TableCell>
                <TableCell align="center" sx={{ maxWidth: 140 }}>
                  {rating.rateInfo.rateAvg}
                </TableCell>
                <TableCell align="center" sx={{ maxWidth: 87 }}>
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      setSelectedOrder(rating);
                      setOpenMenu(e.currentTarget);
                    }}
                  >
                    <Iconify icon={'eva:more-vertical-fill'} sx={{ color: 'action.active', width: 20, height: 20 }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableNoData style={{ height: '344px' }} colSpan={tableColumn.length} content={'No orders yet'} />
        )}
      </CustomDataTable>
      {openMenu && (
        <MenuPopover id="popover-rating" open={openMenu} onClose={handleCloseMenu} sx={{ minWidth: 200 }}>
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              handleOpenDialogue();
            }}
          >
            <Typography variant="body2">Detail</Typography>
          </MenuItem>
        </MenuPopover>
      )}
      {dialogueOpen && selectedOrder && (
        <RatingDetailDialogue open={dialogueOpen} onClose={handleCloseDialogue} order={selectedOrder} />
      )}
    </>
  );
}
