import { Button, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ICloseButtonOnlyDialogueProps } from '../../../constants/helperHooks/useDialogue';

type ApplyUnsavedDialogueProps = ICloseButtonOnlyDialogueProps & {
  onDiscard: () => void;
  onApply: () => void;
};
export const ApplyUnsavedDialogue = ({ open, onClose, onDiscard, onApply }: ApplyUnsavedDialogueProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '539px !important',
          minWidth: '539px',
        },
      }}
    >
      <DialogTitle>Apply unsaved changed?</DialogTitle>
      <DialogContent sx={{ padding: '12px 24px !important' }}>
        <Typography variant="body1" color="text.secondary">
          Your unsaved changes will be lost.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ border: 'none !important' }}>
        <Stack direction="row" gap="12px" sx={{ minWidth: '76px' }}>
          <Button
            variant="outlined"
            color="inherit"
            size="medium"
            onClick={() => {
              onDiscard();
              onClose();
            }}
          >
            Discard
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            sx={{ minWidth: '64px' }}
            onClick={() => {
              onApply();
              onClose();
            }}
          >
            Apply
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
