import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCell, TableRow } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { BackOfficeOrderResponse } from '../../../client';
import CustomDataTable, { ITableColumnTypes } from '../../../components/table/DataTable';
import { TableLoading } from '../../../components/table/TableLoading';
import { TableNoData } from '../../../components/table/TableNoData';
import { TablePropsReturnType } from '../../../components/table/types';
import { APP_ROUTES } from '../../../constants/AppRoutes';
import { getFormatDate } from '../../../utils/formatDate';
import { OrderStatusLabel } from './OrderStatusLabel';

const tableColumn: ITableColumnTypes[] = [
  { id: 'username', label: 'User name', maxWidth: 210, alignCol: 'left' },
  {
    id: 'organizationName',
    label: 'Organization name',
    maxWidth: 222,
    alignCol: 'left',
  },
  {
    id: 'patientName',
    label: 'Patient name',
    maxWidth: 222,
    alignCol: 'center',
  },
  {
    id: 'orderNumber',
    label: 'Order number',
    maxWidth: 222,
    alignCol: 'center',
  },
  { id: 'lab', label: 'Lab', maxWidth: 222, alignCol: 'center' },
  {
    id: 'createdDate',
    label: 'Created date',
    maxWidth: 222,
    alignCol: 'center',
  },
  { id: 'status', label: 'Status', maxWidth: 180, alignCol: 'center' },
];

type OrderListTablePropsType = Partial<TablePropsReturnType> & {
  orderListData: BackOfficeOrderResponse[] | undefined;
  totalElement: number;
  isPending: boolean;
};

export default function OrderListTable(props: OrderListTablePropsType) {
  const {
    dense,
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    onChangeDense,
    totalElement,
    orderListData,
    isPending,
  } = props;

  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <CustomDataTable
      tableColumn={tableColumn}
      rowsPerPage={rowsPerPage}
      page={page}
      totalElement={totalElement}
      dense={dense}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangeDense={onChangeDense}
    >
      {isPending ? (
        <TableLoading style={{ height: '344px' }} colSpan={tableColumn.length} />
      ) : orderListData && orderListData.length > 0 ? (
        orderListData.map((order: BackOfficeOrderResponse, index) => {
          return (
            <TableRow
              key={order.orderId}
              onClick={() => {
                navigate(`/${APP_ROUTES.ORDER_DETAIL.ROUTE}/${order.orderId}`);
              }}
              sx={{
                backgroundColor:
                  order.status === 'ORDERED' ? alpha(theme.palette.primary.main, 0.08) : 'background.default',
                '&:hover': {
                  cursor: 'pointer',
                  backgroundColor:
                    order.status === 'ORDERED' ? alpha(theme.palette.primary.main, 0.24) : 'action.hover',
                },
              }}
            >
              <TableCell
                align="left"
                sx={{ maxWidth: 210 }}
              >{`${order.orderer.name.firstName} ${order.orderer.name.lastName}`}</TableCell>
              <TableCell align="left" sx={{ maxWidth: 222 }}>
                {order.orderer.organization.name}
              </TableCell>
              <TableCell align="center" sx={{ maxWidth: 222 }}>
                {order.patientName}
              </TableCell>
              <TableCell align="center" sx={{ maxWidth: 222 }}>
                {order.orderNumber}
              </TableCell>
              <TableCell align="center" sx={{ maxWidth: 222 }}>
                {order.lab ? order.lab.organization.name : '-'}
              </TableCell>
              <TableCell align="center" sx={{ maxWidth: 222 }}>
                {getFormatDate(order.orderedAt)}
              </TableCell>
              <TableCell align="center" sx={{ maxWidth: 182 }}>
                <OrderStatusLabel orderStatus={order.status} />
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableNoData style={{ height: '344px' }} colSpan={tableColumn.length} content={'No orders yet'} />
      )}
    </CustomDataTable>
  );
}
