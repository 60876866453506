import React, { Suspense, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Box, Button, CircularProgress, Tab, Tabs, Typography } from '@mui/material';

import { CustomTabPanel } from '../../../components/tabs/CustomTabPanel';
import { useOrganizationActions } from '../../../recoil/organizations/useOrganizations';
import InformationTab from './InformationTab';
import SupplyList from './SupplyList';

export default function OrganizationDetailPage() {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get('tab') ?? 'info';

  const { id } = useParams();
  const { useOrganizationDetails } = useOrganizationActions();
  const {
    data: { data },
  } = useOrganizationDetails(id as string);
  const isPartnerLab = data?.organizationType === 'PARTNER_LAB';

  const [selectedTab, setSelectedTab] = useState(currentTab);
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === 'info') {
      searchParams.set('tab', 'info');
      navigate(`?${searchParams.toString()}`, { replace: true });
    } else {
      searchParams.set('tab', 'supply');
      navigate(`?${searchParams.toString()}`, { replace: true });
    }
    setSelectedTab(newValue);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const ORGANIOZATION_DETAIL_TABS = [
    { label: 'Information', tab: 'info' },
    ...(isPartnerLab ? [{ label: 'Supply list', tab: 'supply' }] : []),
  ];

  return (
    <Box className="page-wrapper" sx={{ padding: '40px' }}>
      <Button onClick={handleBackClick} startIcon={<ArrowBackIosIcon />} sx={{ marginBottom: '30px' }}>
        Back
      </Button>
      <Typography className="page-title" fontSize={24} fontWeight={600}>
        Organization Detail
      </Typography>
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        sx={{
          mt: '40px !important',
          mb: '30px !important',
          // borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        }}
      >
        {ORGANIOZATION_DETAIL_TABS.map(({ label, tab }) => {
          return <Tab key={label} label={label} value={tab} {...a11yProps(label)} />;
        })}
      </Tabs>
      <CustomTabPanel sx={{ pt: 0, width: '100%' }} value={selectedTab} index={selectedTab}>
        {selectedTab === 'info' && data && (
          <Suspense fallback={<CircularProgress />}>
            <InformationTab organizationInfo={data} />
          </Suspense>
        )}
        {selectedTab === 'supply' && (
          <Suspense fallback={<CircularProgress />}>
            <SupplyList />
          </Suspense>
        )}
      </CustomTabPanel>
    </Box>
  );
}

function a11yProps(index: string) {
  return {
    id: `order-form-tab-${index}`,
    'aria-controls': `order-form-tabpanel-${index}`,
  };
}
