import React, { useState } from 'react';
import { Button, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import dayjs, { Dayjs } from 'dayjs';

import { ONE_YEAR, SELECT_ALL_ID } from '../../constants/common';
import { handleMouseEnter } from '../../utils/keyboardHandler';
import Iconify from '../iconify/Iconify';
import DatePickerRangeInput from '../selectInput/DatePicker/DatePickerRangeInput';
import { DateValidType, useDatePicker } from '../selectInput/DatePicker/useDatePicker';
import SearchTargetSelect, { SEARCH_TARGET_TYPE } from '../selectInput/SearchTargetSelect';

export type CaseToolbarDataTypes = {
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  searchText?: string | null;
  searchTarget?: string | null;
};

export type ToolbarFilters = 'date' | 'searchText' | 'searchTarget';
interface ICaseTableToolbarProps {
  filterList: ToolbarFilters[];
  dateValidType?: DateValidType;
  days?: number;
  onDataChange: (data: CaseToolbarDataTypes) => void;
  resetPage: () => void;
  searchTargetList?: SEARCH_TARGET_TYPE[];
  searchTargetName?: 'Search target' | 'Organization type';
}

///// TO DO: 버그 처리 후 리팩토링 예정

const CommonFilterBar = ({
  filterList,
  onDataChange,
  dateValidType = 'DURING_X_DAYS',
  days = ONE_YEAR,
  resetPage,
  searchTargetList,
  searchTargetName,
}: ICaseTableToolbarProps) => {
  const isDate = filterList?.includes('date');
  const isSearchText = filterList?.includes('searchText');
  const isSearchTarget = filterList?.includes('searchTarget');

  const { startDate, endDate, handleStartDateChange, handleEndDateChange, handleReset } = useDatePicker(
    dateValidType,
    days,
  );
  const [searchText, setSearchText] = useState<string | null>('');
  const [searchTarget, setSearchTarget] = useState<string | null>(null);
  const [isFiltered, setIsFiltered] = useState(false);

  const isSearchUnavailable = isSearchTarget && !startDate && !endDate && searchText === '';

  const handleStartDate = (date: any) => {
    handleStartDateChange(date);
  };

  const handleEndDate = (date: any) => {
    handleEndDateChange(date);
  };

  const handleSearchTargetChange = (e: SelectChangeEvent<string | null>) => {
    const newSearchTarget = e.target.value === SELECT_ALL_ID ? null : e.target.value;
    if (searchTargetName === 'Organization type') {
      onDataChange({
        startDate,
        endDate,
        searchText,
        searchTarget: newSearchTarget,
      });
    }
    setSearchTarget(newSearchTarget);
    setIsFiltered(true);
  };

  const handleSearchClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    let newStartDate = startDate;
    let newEndDate = endDate;

    if (searchText !== '' || startDate !== null || endDate !== null) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }

    if (startDate || endDate) {
      switch (dateValidType) {
        case 'DURING_X_DAYS':
          if (endDate && days) {
            newStartDate = startDate ? newStartDate : endDate.subtract(days - 1, 'day');
          } else if (startDate && days) {
            newEndDate = startDate.add(days - 1, 'day').isBefore(dayjs())
              ? startDate.add(days - 1, 'day')
              : dayjs().startOf('day');
          }
          break;
      }
    }
    handleStartDateChange(newStartDate);
    handleEndDateChange(newEndDate);

    onDataChange({
      startDate: newStartDate,
      endDate: newEndDate,
      searchText,
      searchTarget,
    });
  };

  const handleClear = () => {
    localStorage.removeItem('filteredCaseStatus');
    setSearchText('');
    setSearchTarget(null);
    handleReset();
    onDataChange({
      startDate: null,
      endDate: null,
      searchText: null,
      searchTarget: null,
    });
    resetPage();
    setIsFiltered(false);
  };

  return (
    <Stack
      spacing={1}
      alignItems="center"
      position="relative"
      direction={{
        xs: 'column',
        sm: 'row',
      }}
      sx={{ width: '100%' }}
    >
      {isDate && (
        <DatePickerRangeInput
          startDateValue={startDate}
          endDateValue={endDate}
          startDateOnChange={handleStartDate}
          endDateOnChange={handleEndDate}
        />
      )}

      {isSearchTarget && searchTargetList && searchTargetName && (
        <SearchTargetSelect
          onChange={handleSearchTargetChange}
          value={searchTarget}
          searchTargetList={searchTargetList}
          searchTargetName={searchTargetName}
        />
      )}
      {/*{isOrganizationType && (*/}
      {/*  <OrganizationTypeSelect onChange={handleOrganizationTypeChange} value={organizationType} />*/}
      {/*)}*/}
      {isSearchText && (
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position="start" sx={{ mr: 0 }}>
                <IconButton
                  onClick={(e) => {
                    !isSearchUnavailable && handleSearchClick(e);
                  }}
                  type="submit"
                >
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          size="small"
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
            setIsFiltered(true);
          }}
          onBlur={(e) => {
            setSearchText(e.target.value.trim());
          }}
          onKeyDown={(e) => handleMouseEnter(e, handleSearchClick)}
          inputProps={{ maxLength: 128 }}
          sx={{
            width: '280px !important',
            textTransform: 'capitalize',
            '& .MuiInputBase-root': {
              padding: 0,
            },
          }}
        />
      )}
      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={handleClear}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          Clear
        </Button>
      )}
    </Stack>
  );
};

export default CommonFilterBar;
