import axios, { AxiosResponse } from 'axios';
import { SuccessResponseDTO } from '../dto/response/success.dto';
import { InvoiceDTO, InvoiceStatisticDTO } from '../dto/response/invoice.dto';
import { PaginatedResponseDTO } from '../dto/response/paginated.dto';
import { CONVERT_READABLE_STRING_FOR_ORDERS_QUERY_PRAMAS } from '../../constants/helper/helperFunctions';
const GET_INVOICE_FOR_LISTING_URL = 'admin/invoice';
const GET_INVOICE_STATISTICS_URL = 'admin/orders/sales-statistices';

export interface InvoiceQueryParamsDTO {
  userType?: string;
  searchBy?: string;
  searchValue?: string;
  sortBy?: string;
  limit?: number;
  page?: number;
  startDate?: string;
  endDate?: string;
  selectBy?: string;
}

export const getInvoicesForAdminListing = (
  queryParams: InvoiceQueryParamsDTO,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<InvoiceDTO>>>> => {
  return axios.get(
    `${GET_INVOICE_FOR_LISTING_URL}?${queryParams.startDate ? `startDate=${queryParams.startDate}` : ''}&${
      queryParams.endDate ? `endDate=${queryParams.endDate}` : ''
    }&search=${queryParams.searchValue}&labOrganizationName=${queryParams.selectBy}&searchBy=${CONVERT_READABLE_STRING_FOR_ORDERS_QUERY_PRAMAS(
      queryParams.searchBy ? queryParams.searchBy : '',
    )}`,
  );
};

export const getInvoiceById = (id: string): Promise<AxiosResponse<SuccessResponseDTO<InvoiceDTO>>> => {
  return axios.get(`${GET_INVOICE_FOR_LISTING_URL}/${id}`);
};

export const updateInvoiceStatusById = (id: string): Promise<AxiosResponse<SuccessResponseDTO<InvoiceDTO>>> => {
  return axios.patch(`${GET_INVOICE_FOR_LISTING_URL}/status/${id}`);
};

export const updateMultipleInvoiceForPublishing = (
  id: string,
): Promise<AxiosResponse<SuccessResponseDTO<InvoiceDTO>>> => {
  return axios.patch(`${GET_INVOICE_FOR_LISTING_URL}/multiple?ids=${id}`);
};

interface StatisticParams {
  year: number;
  labName: string;
}
export const getInvoiceStatictics = (
  queryParams: StatisticParams,
): Promise<AxiosResponse<SuccessResponseDTO<PaginatedResponseDTO<InvoiceStatisticDTO>>>> => {
  return axios.get(
    `${GET_INVOICE_STATISTICS_URL}?${queryParams.year ? `selectedYear=${queryParams.year}` : ''}${
      queryParams.labName ? `&labOrganizationName=${queryParams.labName}` : ''
    }`,
  );
};
