import * as React from 'react';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { CHECK_ARRAY_IS_EMPTY_OR_NOT } from '../../../constants/helper/helperFunctions';
import { InvoiceStatisticDTO } from '../../../recoil/dto/response/invoice.dto';
interface Iprops {
  invoiceStatistics: InvoiceStatisticDTO[];
}
export default function SalesStaticticsTable({ invoiceStatistics }: Iprops) {
  return (
    <TableContainer component={Card} sx={{ mt: 2 }}>
      <Table aria-label="custom pagination table">
        <TableHead
          sx={{
            background: '#F4F6F8',
            borderRadius: '5px',
            position: 'relative',
            '&:after': {
              content: '""',
              height: 6,
              width: '100%',
              background: 'white',
              position: 'absolute',
              bottom: -6,
            },
          }}
        >
          <TableRow
            sx={{
              '& th': {
                py: 3,
                border: 0,
                color: '#637381',
                fontSize: '.875rem',
                fontWeight: 600,
                '&:first-of-type': {
                  pl: 4,
                  borderRadius: '5px 0 0 5px',
                },
                '&:last-child': {
                  borderRadius: '0 5px 5px 0',
                },
              },
            }}
          >
            <TableCell align="left">Order</TableCell>
            <TableCell align="center">Jan</TableCell>
            <TableCell align="center">Feb</TableCell>
            <TableCell align="center">Mar</TableCell>
            <TableCell align="center">Apr</TableCell>
            <TableCell align="center">May</TableCell>
            <TableCell align="center">Jun</TableCell>
            <TableCell align="center">Jul</TableCell>
            <TableCell align="center">Aug</TableCell>
            <TableCell align="center">Sep</TableCell>
            <TableCell align="center">Oct</TableCell>
            <TableCell align="center">Nov</TableCell>
            <TableCell align="center">Dec</TableCell>
            <TableCell align="center">Sum</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& tr': {
              '& td': {
                borderLeft: '1px solid #B4BBC2',
                borderBottom: '1px solid #B4BBC2',
                color: '#637381',
                fontSize: '.875rem',
                fontWeight: 700,
                '&:first-of-type': {
                  pl: 10,
                  borderLeft: 0,
                },
              },
            },
          }}
        >
          {CHECK_ARRAY_IS_EMPTY_OR_NOT(invoiceStatistics)
            ? invoiceStatistics.map((item, index) => {
                return (
                  <>
                    <TableRow>
                      <TableCell>Number</TableCell>
                      <TableCell align="center">{item?.number?.jan}</TableCell>
                      <TableCell align="center">{item?.number?.feb}</TableCell>
                      <TableCell align="center">{item?.number?.mar}</TableCell>
                      <TableCell align="center">{item?.number?.apr}</TableCell>
                      <TableCell align="center">{item?.number?.may}</TableCell>
                      <TableCell align="center">{item?.number?.jun}</TableCell>
                      <TableCell align="center">{item?.number?.jul}</TableCell>
                      <TableCell align="center">{item?.number?.aug}</TableCell>
                      <TableCell align="center">{item?.number?.sep}</TableCell>
                      <TableCell align="center">{item?.number?.oct}</TableCell>
                      <TableCell align="center">{item?.number?.nov}</TableCell>
                      <TableCell align="center">{item?.number?.dec}</TableCell>
                      <TableCell align="center">{item?.number?.totalCount}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Unique (JPY)</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.jan}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.feb}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.mar}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.apr}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.may}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.jun}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.jul}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.aug}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.sep}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.oct}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.nov}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.dec}</TableCell>
                      <TableCell align="center">{item?.uniquePrice?.totalAmountCount}</TableCell>
                    </TableRow>
                  </>
                );
              })
            : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
