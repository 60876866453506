export const handleDecimalNumberInputValidate = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.currentTarget.value = e.currentTarget.value.replace(/[^0-9.]/g, ''); // 숫자와 소수점만 허용
  if (e.currentTarget.value.split('.').length > 2) {
    e.currentTarget.value = e.currentTarget.value.replace(/\.+$/, '');
  }
};

export const handleNumberFieldInputValidate = (e: React.ChangeEvent<HTMLInputElement>) => {
  e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '');
};
