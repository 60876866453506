import { useMutation, useQueryClient } from '@tanstack/react-query';

import { patchProductRemake } from './remake.api';

export const useProductRemakeMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patchProductRemake,
    onSuccess: async () =>
      await queryClient.invalidateQueries({
        queryKey: ['get-products'],
      }),
  });
};
