export const APP_ROUTES = {
  LOGIN: {
    ROUTE: '/auth/login',
  },
  DASHBOARD: {
    ROUTE: 'dashboard',
  },
  SALES: {
    ROUTE: 'sales',
  },
  SALES_STATISTICS: {
    ROUTE: 'sales/statistics',
  },
  PAYMENT_HISTORY: {
    ROUTE: 'sales/payment-history',
  },
  REFUND: {
    ROUTE: 'sales/refund',
  },
  ORDER: {
    ROUTE: 'order',
  },
  ORDER_LIST: {
    ROUTE: 'order/list',
  },
  ORDER_DETAIL: {
    ROUTE: 'order/detail',
  },
  ORGANIZATION_LIST: {
    ROUTE: 'organization/list',
  },
  ORGANIZATION_DETAIL: {
    ROUTE: 'organization/detail',
  },
  RATING: {
    ROUTE: 'rating',
  },
  PRODUCTS: {
    ROUTE: 'products',
  },
  PRODUCTS_LIST: {
    ROUTE: 'products/list',
  },
  ADDITIONAL_OPTIONS: {
    ROUTE: 'products/additional-options',
  },
  PRODUCTS_REMAKE: {
    ROUTE: 'products/remake',
  },
  MANAGEMENT: {
    ROUTE: 'management',
  },
  ADMIN: {
    ROUTE: 'management/admin',
  },
  MENU_ACCESS: {
    ROUTE: 'management/menu-access',
  },
};
