import { atom } from 'recoil';
import { InvoiceStateDTO } from '../dto/states/invoiceState.dto';
export const INVOICE_INITAL_STATE: InvoiceStateDTO = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  allInvoiceListing: [],
  invoiceForDetail: {},
  invoiceStatistics: [],
};

export const invoiceState = atom({
  key: 'invoiceState',
  default: INVOICE_INITAL_STATE,
});
