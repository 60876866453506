import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Toolbar } from '@mui/material';
import { Box } from '@mui/system';

import { AppHeader } from './components/shared/AppHeader/AppHeader';
import SNBNav from './components/shared/AppSideBar/AppSideBar';
import TopAlert from './components/topAlert/TopAlert';
import { APP_ROUTES } from './constants/AppRoutes';

const RouteLayout = () => {
  const location = useLocation();

  const allRoutes = Object.values(APP_ROUTES).map((route) => route.ROUTE);
  const purePath = location.pathname.slice(1);
  const showSidebar = allRoutes.some((route) => purePath.startsWith(route));

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
      }}
    >
      {showSidebar && <SNBNav />}
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <AppHeader />
        <Toolbar />
        <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
          <Outlet />
        </Box>
        <TopAlert />
      </Box>
    </Box>
  );
};

export default RouteLayout;
