import { FieldPath, useForm, useFormContext } from 'react-hook-form';
import { useBlocker } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';

import { PatchAdditionalPriceRequest } from '../../../client';
import { LeaveSiteDialogue } from '../../../components/dialog/LeaveSiteDialogue';
import FormProvider from '../../../components/RHF/FormProvider';
import RHFTextField from '../../../components/RHF/RHFTextField';
import { useAdditionalOptionPricesQuery, usePatchAdditionalOptionPrices } from '../../../recoil/products/useProduct';
import useTopAlert from '../../../recoil/topAlert/useTopAlert';
import { handleNumberFieldInputValidate } from '../../../utils/inputHandler';

const AdditionalOptions = () => {
  const { setTopAlert, initTopAlert } = useTopAlert();

  const { data: additionalOptionsData } = useAdditionalOptionPricesQuery();
  const optionPrices = additionalOptionsData?.data as PatchAdditionalPriceRequest;

  const methods = useForm<PatchAdditionalPriceRequest>({
    mode: 'onSubmit',
    defaultValues: {
      printing3DModel: optionPrices.printing3DModel ?? 0,
      premiumFinishing: optionPrices.premiumFinishing ?? 0,
      hook: optionPrices.hook ?? 0,
      shipping: optionPrices.shipping ?? 0,
    },
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isValid, isDirty, dirtyFields },
  } = methods;

  const { mutateAsync, isPending } = usePatchAdditionalOptionPrices();

  let blocker: any = useBlocker(
    ({ currentLocation, nextLocation }) => false && currentLocation.pathname !== nextLocation.pathname,
  );

  const onSubmit = async (formData: PatchAdditionalPriceRequest) => {
    const updatedData: Partial<PatchAdditionalPriceRequest> = {};
    Object.keys(dirtyFields).forEach((key) => {
      updatedData[key as keyof PatchAdditionalPriceRequest] = watch(key as keyof PatchAdditionalPriceRequest);
    });

    await mutateAsync(updatedData, {
      onSuccess: () => {
        setTopAlert({
          open: true,
          variant: 'filled',
          severity: 'success',
          description: 'Additional options updated successfully',
          onClose: initTopAlert,
        });
        reset({
          ...updatedData,
        });
      },
    });
  };

  return (
    <FormProvider id={'additionalOptionPrice'} methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack gap="40px" sx={{ px: 7, py: 5 }}>
        <Typography variant="h3">Additional option</Typography>
        <Stack gap="52px" sx={{ maxWidth: '571px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Printing 3D model</Typography>
            <OptionInputField name="printing3DModel" unit={'/File'} />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography variant="h6">Finish</Typography>
            <Stack gap="16px">
              <Stack gap="8px" sx={{ width: '321px' }}>
                <Typography variant="subtitle1">Basic</Typography>
                <OptionInputField name="" unit={'/Case'} disabled={true} value={0} />
              </Stack>
              <Stack gap="8px" sx={{ width: '321px' }}>
                <Typography variant="subtitle1">Premium</Typography>
                <OptionInputField name="premiumFinishing" unit={'/Case'} />
              </Stack>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Hook (Nob)</Typography>
            <OptionInputField name="hook" unit={'/Tooth'} />
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Typography variant="h6">Shipping fee</Typography>
            <OptionInputField name="shipping" unit={'/Order'} />
          </Stack>
          <Stack direction="row" sx={{ mt: '18px' }} justifyContent="flex-end">
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              loading={isPending}
              disabled={!isDirty || !isValid}
            >
              Update
            </LoadingButton>
          </Stack>
          <LeaveSiteDialogue blocker={blocker} />
        </Stack>
      </Stack>
    </FormProvider>
  );
};

export default AdditionalOptions;

type OptionInputFieldProps = {
  name: FieldPath<PatchAdditionalPriceRequest> | '';
  unit: string;
  disabled?: boolean;
  value?: string | number;
};

const OptionInputField: React.FC<OptionInputFieldProps> = ({ name, unit, value, disabled = false }) => {
  const { setValue, trigger, watch } = useFormContext<PatchAdditionalPriceRequest>();

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (name === '') return;
    const value = Number(e.target.value.replace(/,/g, ''));
    const isValueDirty = value !== watch(name);
    setValue(name, value, { shouldDirty: isValueDirty });
    trigger(name);
  };

  return (
    <Stack direction="row" justifyContent="flex-start" alignItems="flex-end" gap="8px" sx={{ width: '321px' }}>
      <RHFTextField
        name={name}
        label=""
        variant="outlined"
        size="small"
        isNumberField={true}
        onBlur={onBlur}
        inputProps={{
          onInput: handleNumberFieldInputValidate,
          maxLength: 28,
        }}
        {...(value !== undefined ? { value } : {})}
        disabled={disabled}
        InputProps={{
          startAdornment: (
            <Typography variant="body2" sx={{ paddingRight: '9px' }}>
              JPY
            </Typography>
          ),
        }}
        sx={{ width: '260px' }}
      />
      <Typography variant="subtitle1" color="text.secondary">
        {unit}
      </Typography>
    </Stack>
  );
};
