import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'axios';

import {
  CreateProductOptionRequest,
  CreateProductRequest,
  CreateProductsAvailableOptionResponse,
  GetProductsAvailableOptionResponse,
  PatchProductInfo,
  PatchProsthesisColorCodeRequest,
  ResponseDtoAdditionalPrice,
  ResponseDtoListProductOptionColorCodeResponse,
  ResponseDtoPageProductDetail,
  ResponseDtoRemakePolicy,
  UpdateProductPriceStatusRequest,
} from '../../client';

const ALL_PRODUCT = 'connect/back-office/admin/products';
const ALL_PRODUCT_OPTIONS = 'connect/back-office/admin/products/options';

//get
export const getProductList = ({ queryKey }: QueryFunctionContext): Promise<ResponseDtoPageProductDetail> => {
  const [_key, status, page, size, sort] = queryKey;
  return axios
    .get(`${ALL_PRODUCT}?page=${page}&size=${size}&status=${status}&sort=${(sort as Array<string>).join(',')}`, {})
    .then((res) => res.data);
};

export const getProductOptions = async ({
  queryKey,
}: QueryFunctionContext): Promise<GetProductsAvailableOptionResponse> => {
  const [_key, option] = queryKey;
  return await axios.get(`${ALL_PRODUCT_OPTIONS}?optionGroup=${option}`).then((res) => res.data);
};

export const getProsthesisColor = (): Promise<ResponseDtoListProductOptionColorCodeResponse> =>
  axios.get('connect/back-office/admin/products/color').then((res) => res.data);

export const patchProsthesisColor = (body: PatchProsthesisColorCodeRequest) =>
  axios.patch('/connect/back-office/admin/products/color', { ...body }).then((res) => res.data);

export const postProductOptions = async (
  body: CreateProductOptionRequest,
): Promise<CreateProductsAvailableOptionResponse> => {
  return await axios.post(ALL_PRODUCT_OPTIONS, { ...body }).then((res) => res.data);
};

export const deleteProductOption = async (optionId: string) => {
  return await axios.delete(`${ALL_PRODUCT_OPTIONS}/${optionId}`).then((res) => res.data);
};

export const postProductItem = async (body: CreateProductRequest) => {
  return axios.post(ALL_PRODUCT, { ...body }).then((res) => res.data);
};

export const patchProductItem = async (productId: string, body: PatchProductInfo) => {
  return axios.patch(`${ALL_PRODUCT}/${productId}`, { ...body }).then((res) => res.data);
};

export const patchProductItemLifeCycle = async (body: UpdateProductPriceStatusRequest) => {
  return axios.patch(ALL_PRODUCT, { ...body }).then((res) => res.data);
};

export const deleteProductItem = async (productId: string) => {
  return axios.delete(`${ALL_PRODUCT}/${productId}`).then((res) => res.data);
};

export const deleteProductListItem = async (productIdList: string[]) => {
  return axios.patch(`${ALL_PRODUCT}/delete`, { targetProductIds: productIdList }).then((res) => res.data);
};

// additional options
export const getAdditionalOptionPrices = (): Promise<ResponseDtoAdditionalPrice> => {
  return axios.get('connect/back-office/admin/additional-prices').then((res) => res.data);
};

// remake
export const getRemakePolicy = (): Promise<ResponseDtoRemakePolicy> => {
  return axios.get('connect/back-office/admin/remake-policy').then((res) => res.data);
};

export const putRemakePolicy = (content: string) => {
  return axios.put('connect/back-office/admin/remake-policy', { content }).then((res) => res.data);
};
