import { AlertProps } from '@mui/material';
import { atom } from 'recoil';

export interface IAlert extends Omit<AlertProps, 'severity' | 'variant'> {
  open: boolean;
  severity?: AlertProps['severity'];
  variant?: AlertProps['variant'];
  title?: string;
  description?: string | null;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
  onClose?: () => void;
}

export const DEFAULT_TOP_ALERT_STATE: IAlert = {
  open: false,
  severity: undefined,
  variant: undefined,
  title: undefined,
  description: null,
  actionButtonText: undefined,
  onActionButtonClick: undefined,
  onClose: undefined,
};

export const topAlertState = atom({
  key: 'topAlert',
  default: DEFAULT_TOP_ALERT_STATE,
});
