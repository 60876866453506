import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ColorPair, optionGroup } from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import {
  useProductOptionDelete,
  useProductOptionMutation,
  useProductOptions,
  useProsthesisColor,
} from '../../../recoil/products/useProduct';
import { useAxiosErrorWithErrorCode } from '../../../utils/responseError';

const OPTION_GROUP = [
  { name: 'PROSTHESIS' },
  { name: 'METHOD' },
  { name: 'MATERIAL' },
  { name: 'SHAPE' },
  // { name: 'PRICE_UNIT' },
  // { name: 'COLOR_CODE' },
];

const PALETTE_OPTION = [
  { name: 'Dark Blue', stroke: '#0D3B66', fill: '#E5F2F9' },
  { name: 'Brick Red', stroke: '#C0392B', fill: '#F9EBEA' },
  { name: 'Forest Green', stroke: '#2E7D32', fill: '#E8F5E9' },
  { name: 'Tangerine', stroke: '#EF6C00', fill: '#FFF3E0' },
  { name: 'Purple', stroke: '#0277BD', fill: '#E1F5FE' },
  { name: 'Maroon', stroke: '#662222', fill: '#F9E5E5' },
  { name: 'Sky Blue', stroke: '#8E24AA', fill: '#F3E5F5' },
  { name: 'Coral', stroke: '#E91E63', fill: '#FFF0F0' },
  { name: 'Deep Purple', stroke: '#3949AB', fill: '#E8EAF6' },
  { name: 'Golden Yellow', stroke: '#F9A825', fill: '#FFFDE7' },
  { name: 'Dark Lavender', stroke: '#5E35B1', fill: '#EDE7F6' },
  { name: 'Light Green', stroke: '#7CB342', fill: '#F1F8E9' },
  { name: 'Dark Gray', stroke: '#607D8B', fill: '#ECEFF1' },
  { name: 'Olive Green', stroke: '#9E9D24', fill: '#F9FBE7' },
  { name: 'Slate Gray', stroke: '#424242', fill: '#F3F3F3' },
  { name: 'Bright Pink', stroke: '#D84315', fill: '#FBE9E7' },
];

// Dark Blue
const defaultColor = { stroke: '#0D3B66', fill: '#E5F2F9' };

const ERROR_MESSAGE = {
  'CONNECT:PRODUCT_OPTION_IS_USED': {
    title: 'Unable to delete',
    message: 'This item is used.',
  },
  'CONNECT:PRODUCT_OPTION_ALREADY_EXISTS': {
    title: 'Unable to add',
    message: 'This item already exists.',
  },
  // TODO: 서버에서 정의 되어 있지만, 서버까지 안가도 됨. 클라단에서만 사용. 서버와 맞출 필요 있음.
  // 'CONNECT:PRODUCT_OPTION_COLOR_CODE_IS_EMPTY': {
  //   title: 'Unable to add',
  //   message: 'Color is required.',
  // },
};

interface ProductOptionEditDialogProps {
  isOpen: boolean;
  handleClose: () => void;
}

export default function ProductOptionEditDialog({ isOpen, handleClose }: ProductOptionEditDialogProps) {
  const { mutateAsync: addProductOption } = useProductOptionMutation();
  const { mutateAsync: deleteProductOption } = useProductOptionDelete();

  const [selectedProductOption, setSelectedProductOption] = useState<optionGroup>('PROSTHESIS');

  const [isAddMode, setIsAddMode] = useState(false);
  const [typedOption, setTypedOption] = useState('');
  const [selectedColor, setSelectedColor] = useState<ColorPair>(defaultColor);

  // TODO: useSuspenseQuery 사용할 것.
  const { data, refetch: productOptionsRefetch } = useProductOptions(selectedProductOption);
  const { isAxiosErrorWithErrorCode, errorCode, resetError } = useAxiosErrorWithErrorCode();

  // Colour
  const { data: prosthesisColor, refetch: prosthesisColorRefetch } = useProsthesisColor();

  // Dialog for unable to delete option.
  const {
    open: isUnableToDeleteOpen,
    handleOpenDialogue: unableToDeleteDialogOpen,
    handleCloseDialogue: unableToDeleteDialogClose,
  } = useDialogue();

  const handleOptionClick = (e: React.SyntheticEvent<Element, Event>, newValue: string) => {
    setSelectedProductOption(newValue as optionGroup);
    setIsAddMode(false);
    setTypedOption('');
    setSelectedColor(defaultColor);
  };
  const handleAddModeClick = () => {
    setIsAddMode((prev) => !prev);
  };

  const handleTypedOption = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setTypedOption(value);
  };

  const handleSelectedColorChange = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const { stroke, fill } = PALETTE_OPTION.find((color) => color.name === value) as ColorPair;
    setSelectedColor({ stroke, fill });
  };

  const handleAddClick = async () => {
    if (!typedOption) {
      return unableToDeleteDialogOpen();
    }
    try {
      const res = await addProductOption({
        optionGroup: selectedProductOption,
        value: typedOption,
        colorCode: selectedColor,
      });

      if (res.success) {
        setTypedOption('');
        setSelectedColor(defaultColor);
      }
      // like invalidate
      await productOptionsRefetch();
      await prosthesisColorRefetch();
    } catch (err) {
      if (isAxiosErrorWithErrorCode(err, 'CONNECT:PRODUCT_OPTION_ALREADY_EXISTS')) {
        unableToDeleteDialogOpen();
      }
    }
  };

  const handleDeleteClick = async (id: string) => {
    try {
      await deleteProductOption(id);
      // like invalidate
      await productOptionsRefetch();
      await prosthesisColorRefetch();
    } catch (err) {
      if (isAxiosErrorWithErrorCode(err, 'CONNECT:PRODUCT_OPTION_IS_USED')) {
        unableToDeleteDialogOpen();
      }
    }
  };

  const selectedColorName =
    PALETTE_OPTION.find((color) => color.stroke === selectedColor.stroke && color.fill === selectedColor.fill)?.name ??
    'Dark Blue';

  return (
    <>
      <CustomDialog
        open={isOpen}
        title={'Product Options'}
        handleClose={() => {
          handleClose();
          setIsAddMode(false);
          setTypedOption('');
          setSelectedColor(defaultColor);
          setSelectedProductOption('PROSTHESIS');
        }}
        btnCancelText="Confirm"
      >
        <Tabs onChange={handleOptionClick} value={selectedProductOption} sx={{ mb: '20px' }}>
          {OPTION_GROUP.map(({ name }) => (
            <Tab key={name} label={name} value={name} />
          ))}
        </Tabs>

        <Stack direction="row" sx={{ width: '100%' }} justifyContent="flex-start" gap="8px">
          <Button
            variant="outlined"
            size="small"
            disabled={isAddMode}
            onClick={handleAddModeClick}
            sx={{ minWidth: '100px' }}
          >
            <AddIcon />
            Add new
          </Button>

          {isAddMode && (
            <Stack direction="row" gap="6px" justifyContent="space-between">
              {/* Add Value*/}
              <TextField value={typedOption} onChange={handleTypedOption} size="small" />

              {/* Select */}
              {selectedProductOption === 'PROSTHESIS' && (
                <Box>
                  <FormControl fullWidth size="small">
                    <InputLabel id="select-color" size="normal">
                      Color
                    </InputLabel>
                    <Select
                      labelId="select-color"
                      value={selectedColorName}
                      // label="Color"
                      // size="small"
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={handleSelectedColorChange}
                      sx={{ minWidth: '180px' }}
                    >
                      {PALETTE_OPTION.map(({ name, fill, stroke }) => {
                        return (
                          <MenuItem key={name} value={name}>
                            <Stack direction="row">
                              <ColorBox fill={fill} stroke={stroke} />
                              <Typography>{name}</Typography>
                            </Stack>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              )}
              <Button onClick={handleAddClick} sx={{ minWidth: '64px' }} size="small" variant="contained">
                Add
              </Button>
            </Stack>
          )}
        </Stack>

        <Divider sx={{ margin: '20px 0' }} />

        <Stack sx={{ height: '284px', overflowY: 'auto', padding: '8px 4px', marginBottom: '20px' }} gap="4px">
          {data?.map((option) => {
            const isProsthesisTab = selectedProductOption === 'PROSTHESIS';
            const color = isProsthesisTab && prosthesisColor?.data?.find((item) => item.productOptionId === option.id);

            return (
              <Stack
                key={option.value}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  width: '100%',
                  minHeight: '62px',
                  padding: '0 12px',
                  backgroundColor: '',
                  '&:hover': { backgroundColor: '#919EAB14' },
                }}
              >
                <Box sx={{ width: '120px' }}>
                  <Typography variant="subtitle1">{option.value}</Typography>
                </Box>
                {isProsthesisTab && color ? (
                  <ColorBox fill={color.colorCode.fill} stroke={color.colorCode.stroke} />
                ) : null}
                <Button onClick={() => handleDeleteClick(option.id)} variant="contained" color="error" size="small">
                  Delete
                </Button>
              </Stack>
            );
          })}
        </Stack>
      </CustomDialog>

      <ErrorDialogWithMessage
        errorMessage={ERROR_MESSAGE[errorCode as keyof typeof ERROR_MESSAGE]}
        open={isUnableToDeleteOpen}
        onClose={() => {
          unableToDeleteDialogClose();
          resetError();
        }}
      />
    </>
  );
}

const ColorBox = (color: ColorPair) => {
  return (
    <Box border={color.stroke} sx={{ marginRight: '9px' }}>
      <Box sx={{ width: '22px', height: '22px', backgroundColor: color.stroke, borderRadius: '8px' }} />
    </Box>
  );
};

interface DeleteUnableDialogProps {
  open: boolean;
  onClose: () => void;
  errorMessage:
    | {
        title: string;
        message: string;
      }
    | undefined;
}
function ErrorDialogWithMessage({
  open,
  onClose,
  errorMessage = { title: 'Error', message: 'Cannot proceed the task.' },
}: DeleteUnableDialogProps) {
  return (
    <CustomDialog open={open} title={errorMessage.title} handleClose={onClose}>
      <Box>
        <Typography>{errorMessage.message}</Typography>
      </Box>
    </CustomDialog>
  );
}
