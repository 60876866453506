// Prosthesis Color 만 수정하는 Dialog
import { useState } from 'react';
import { Box, Stack, TextField } from '@mui/material';

import { ColorPair, ProductDetail } from '../../../client';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useProsthesisColorMutation } from '../../../recoil/products/useProduct';
import ColorSelector from './ColorDialog';

const NO_COLOR = { fill: '', stroke: '' };

interface ProsthesisColorEditDialogProps {
  open: boolean;
  handleClose: () => void;
  productForEdit: ProductDetail;
}

export default function ProsthesisColorEditDialog({
  open,
  handleClose,
  productForEdit,
}: ProsthesisColorEditDialogProps) {
  const { mutateAsync: patchProsthesisColor } = useProsthesisColorMutation();
  const [selectedColor, setSelectedColor] = useState<ColorPair>(NO_COLOR);
  const originalColor = productForEdit.prosthesis.colorCode;

  const handleColorSelect = (colorPair: ColorPair) => {
    setSelectedColor(colorPair);
  };

  return (
    <>
      <CustomDialog
        open={open}
        title="Edit prosthesis"
        handleClose={() => {
          handleClose();
          setSelectedColor(NO_COLOR);
        }}
        btnActionText="Confirm"
        btnAction={async () => {
          // await Apply Change
          await patchProsthesisColor({
            prosthesisOptionId: productForEdit.prosthesis.productOptionId,
            colorCode: selectedColor,
          });
          handleClose();
          setSelectedColor(NO_COLOR);
        }}
      >
        <Stack gap="24px" sx={{ paddingBottom: '24px' }}>
          <Box>
            <TextField
              disabled
              value={productForEdit.prosthesis.productOptionValue}
              variant="outlined"
              size="small"
              sx={{ width: '100%' }}
            />
          </Box>

          <ColorSelector
            selectedColor={!selectedColor.stroke && !selectedColor.fill ? originalColor : selectedColor}
            onColorChange={handleColorSelect}
          />
        </Stack>
      </CustomDialog>
    </>
  );
}
