// import React from "react";
import { Box, Card, Typography } from '@mui/material';
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";
// import { DEMO_CHART_STATS_DATA } from "../../pages/Dashboard/DEMO_CHARTS_DATA";

interface IPropsCardStats {
  label: string;
  value: string;
}

export const CardStats = ({ label = 'Label Here', value = ' Value Here' }: IPropsCardStats) => {
  return (
    <Card sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Box>
        <Typography component="label" fontWeight={600} color="#9199a3" display="block">
          {label}
        </Typography>
        <Typography component="strong" fontSize="2.25rem" fontWeight={700} marginTop="0.25rem">
          {value}
        </Typography>
      </Box>
      {/* <HighchartsReact highcharts={Highcharts} options={DEMO_CHART_STATS_DATA} /> */}
    </Card>
  );
};
