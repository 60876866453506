import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormControlLabel, RadioGroup, RadioGroupProps, SxProps, Theme } from '@mui/material';
import Radio, { RadioProps } from '@mui/material/Radio';

type optionType = {
  label: string;
  value: string;
  disabled?: boolean;
};

type RHFRadioGroupPropType = RadioGroupProps & {
  name: string;
  options: optionType[];
  groupSx?: SxProps<Theme>;
  controlSx?: SxProps<Theme>;
  radioSize?: RadioProps['size'];
};

export default function RHFRadioGroup({
  name,
  options,
  groupSx,
  controlSx,
  radioSize = 'medium',
  row,
}: RHFRadioGroupPropType) {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl component="fieldset">
          <RadioGroup {...field} row={row}>
            {options.map(({ label, value, disabled }) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio size={radioSize} />}
                label={label}
                disabled={disabled}
                sx={{ ...controlSx }}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
}
