import * as React from 'react';
import { CircularProgress, TableCell, TableRow, TableRowProps } from '@mui/material';

export const TableLoading = ({ style, colSpan }: { style: TableRowProps['style']; colSpan: number }) => {
  return (
    <TableRow style={{ ...style }}>
      <TableCell colSpan={colSpan} align="center">
        <CircularProgress />
      </TableCell>
    </TableRow>
  );
};
