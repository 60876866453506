import { useEffect, useState } from 'react';
import { Button, IconButton, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';

import TextEditor from '../../../components/editor/TextEditor';
import Iconify from '../../../components/iconify/Iconify';
import { ICloseButtonOnlyDialogueProps, useDialogue } from '../../../constants/helperHooks/useDialogue';
import { usePutRemakePolicyMutation, useRemakePolicyQuery } from '../../../recoil/products/useProduct';
import { ApplyUnsavedDialogue } from './ApplyUnsavedDialogue';

type RemakePolicyDialoguePropsType = ICloseButtonOnlyDialogueProps & {};

export const RemakePolicyDialogue = ({ open, onClose }: RemakePolicyDialoguePropsType) => {
  const applyUnsavedDialogue = useDialogue();
  const [isEditing, setIsEditing] = useState(false);

  const { data } = useRemakePolicyQuery();
  const defaultContent = data?.data?.content ?? '';

  const [content, setContent] = useState(defaultContent);
  const isContentEdit = defaultContent !== content;

  const { mutateAsync } = usePutRemakePolicyMutation();
  const handleContentChange = (value: string) => {
    setContent(value);
  };

  const handleDiscardClick = () => {
    setContent(defaultContent);
  };
  const handleApplyClick = () => {
    setIsEditing(false);
    const contentToSave = content.trim() === '<p><br></p>' ? '' : content.trim();
    mutateAsync(contentToSave);
  };

  const handleDialogueClose = () => {
    if (isContentEdit) {
      applyUnsavedDialogue.handleOpenDialogue();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      setIsEditing(false);
      setContent(defaultContent);
    }
  }, [open, defaultContent]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx: {
            width: '800px !important',
            minWidth: '800px',
          },
        }}
      >
        <DialogTitle sx={{ padding: '24px 12px 24px 24px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Remake policy</Typography>
            <IconButton aria-label="close" color="default" size="medium" onClick={handleDialogueClose}>
              <Iconify icon="mingcute:close-line" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ height: '409px !important', pt: '8px !important' }}>
          {isEditing && (
            <TextEditor
              id="remake-policy-editor"
              onChange={handleContentChange}
              value={content}
              sx={{ height: '385px', '& .ql-editor': { height: 343 } }}
            />
          )}
          {!isEditing &&
            (content ? (
              <QuillContent
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
              />
            ) : (
              <Stack justifyContent="center" alignItems="center" gap="12px" sx={{ marginTop: '175.5px' }}>
                <Typography variant="h6" color="text.secondary">
                  No Remake policy yet
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Start to create a remake policy with Edit button below.
                </Typography>
              </Stack>
            ))}
        </DialogContent>
        <DialogActions sx={{ border: 'none !important' }}>
          {isEditing ? (
            <Stack direction="row" gap="12px" sx={{ minWidth: '76px' }}>
              <Button
                variant="outlined"
                color="inherit"
                size="medium"
                disabled={!isContentEdit}
                onClick={handleDiscardClick}
              >
                Discard
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                sx={{ minWidth: '64px' }}
                disabled={!isContentEdit}
                onClick={handleApplyClick}
              >
                Apply
              </Button>
            </Stack>
          ) : (
            <Button
              variant="outlined"
              color="primary"
              size="medium"
              onClick={() => setIsEditing(true)}
              sx={{ minWidth: '64px' }}
            >
              Edit
            </Button>
          )}
        </DialogActions>
      </Dialog>
      {applyUnsavedDialogue.open && (
        <ApplyUnsavedDialogue
          open={applyUnsavedDialogue.open}
          onClose={applyUnsavedDialogue.handleCloseDialogue}
          onDiscard={() => {
            handleDiscardClick();
            onClose();
          }}
          onApply={() => {
            handleApplyClick();
            onClose();
          }}
        />
      )}
    </>
  );
};

const QuillContent = styled('div')`
  p {
    margin: 0;
  }
  li {
    list-style-position: inside;
  }
`;
