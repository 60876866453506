import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { ResponseDtoUserGetResponse } from '../../client';
import { getUserData, postLogout } from './auth.api';

export const useUserQuery = (): UseQueryResult<ResponseDtoUserGetResponse> => {
  return useQuery({
    queryKey: ['userData'],
    queryFn: getUserData,
  });
};

export const logoutBO = async () => {
  await postLogout();
  if (window.sessionStorage.getItem('DENTBIRD_ADMIN_ACCESS_TOKEN')) {
    window.sessionStorage.removeItem('DENTBIRD_ADMIN_ACCESS_TOKEN');
    // TODO: HTTPONLY 아니라서 cookie 지워주면 됨.
  }
};
