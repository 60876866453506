interface PalmerInfo {
  short: string;
  full: string;
}

interface FDIConverterToPalmer {
  [key: number]: PalmerInfo;
}

export const FDIToPalmer: FDIConverterToPalmer = {
  11: { short: 'UR 1', full: 'Upper Right 1' },
  12: { short: 'UR 2', full: 'Upper Right 2' },
  13: { short: 'UR 3', full: 'Upper Right 3' },
  14: { short: 'UR 4', full: 'Upper Right 4' },
  15: { short: 'UR 5', full: 'Upper Right 5' },
  16: { short: 'UR 6', full: 'Upper Right 6' },
  17: { short: 'UR 7', full: 'Upper Right 7' },
  18: { short: 'UR 8', full: 'Upper Right 8' },

  21: { short: 'UL 1', full: 'Upper Left 1' },
  22: { short: 'UL 2', full: 'Upper Left 2' },
  23: { short: 'UL 3', full: 'Upper Left 3' },
  24: { short: 'UL 4', full: 'Upper Left 4' },
  25: { short: 'UL 5', full: 'Upper Left 5' },
  26: { short: 'UL 6', full: 'Upper Left 6' },
  27: { short: 'UL 7', full: 'Upper Left 7' },
  28: { short: 'UL 8', full: 'Upper Left 8' },

  31: { short: 'LL 1', full: 'Lower Left 1' },
  32: { short: 'LL 2', full: 'Lower Left 2' },
  33: { short: 'LL 3', full: 'Lower Left 3' },
  34: { short: 'LL 4', full: 'Lower Left 4' },
  35: { short: 'LL 5', full: 'Lower Left 5' },
  36: { short: 'LL 6', full: 'Lower Left 6' },
  37: { short: 'LL 7', full: 'Lower Left 7' },
  38: { short: 'LL 8', full: 'Lower Left 8' },

  41: { short: 'LR 1', full: 'Lower Right 1' },
  42: { short: 'LR 2', full: 'Lower Right 2' },
  43: { short: 'LR 3', full: 'Lower Right 3' },
  44: { short: 'LR 4', full: 'Lower Right 4' },
  45: { short: 'LR 5', full: 'Lower Right 5' },
  46: { short: 'LR 6', full: 'Lower Right 6' },
  47: { short: 'LR 7', full: 'Lower Right 7' },
  48: { short: 'LR 8', full: 'Lower Right 8' },
};

interface ICustomSelectorToFDI {
  [key: number]: number;
}

export const CustomSelectorToFDI: ICustomSelectorToFDI = {
  1: 18,
  2: 17,
  3: 16,
  4: 15,
  5: 14,
  6: 13,
  7: 12,
  8: 11,
  //
  9: 21,
  10: 22,
  11: 23,
  12: 24,
  13: 25,
  14: 26,
  15: 27,
  16: 28,
  //
  21: 48,
  22: 47,
  23: 46,
  24: 45,
  25: 44,
  26: 43,
  27: 42,
  28: 41,
  //
  29: 31,
  30: 32,
  31: 33,
  32: 34,
  33: 35,
  34: 36,
  35: 37,
  36: 38,
};

export const FDIToCustomSelectorNumber: ICustomSelectorToFDI = {
  18: 1,
  17: 2,
  16: 3,
  15: 4,
  14: 5,
  13: 6,
  12: 7,
  11: 8,
  //
  21: 9,
  22: 10,
  23: 11,
  24: 12,
  25: 13,
  26: 14,
  27: 15,
  28: 16,
  //
  48: 21,
  47: 22,
  46: 23,
  45: 24,
  44: 25,
  43: 26,
  42: 27,
  41: 28,
  //
  31: 29,
  32: 30,
  33: 31,
  34: 32,
  35: 33,
  36: 34,
  37: 35,
  38: 36,
};
