import { Suspense } from 'react';
import { Card, CircularProgress, Grid } from '@mui/material';

import { BackOfficeOrganizationResponse } from '../../../client';
import InformationSection from './InformationSection';
import MemberListTable from './MemberListTable';

type InformationTabPropsType = {
  organizationInfo: BackOfficeOrganizationResponse;
};

export default function InformationTab({ organizationInfo }: InformationTabPropsType) {
  return (
    <Grid container gap="20px" sx={{ display: 'grid', gridTemplateColumns: '0.7fr 1fr' }}>
      <Card sx={{ padding: '32px 24px' }}>
        <InformationSection organizationInfo={organizationInfo} />
      </Card>
      <Card sx={{ padding: '32px 24px' }}>
        <Suspense fallback={<CircularProgress />}>
          <MemberListTable />
        </Suspense>
      </Card>
    </Grid>
  );
}
