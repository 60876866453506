import { useState } from 'react';
import { isAxiosError } from 'axios';

export const useAxiosErrorWithErrorCode = () => {
  const [errorCode, setErrorCode] = useState('');
  const isAxiosErrorWithErrorCode = <T>(e: T, errorCode: string) => {
    // If is, do error handling...
    setErrorCode(errorCode);
    return isAxiosError(e) && e.response && e.response.data.errorCode === errorCode;
  };
  const resetError = () => setErrorCode('');

  return { isAxiosErrorWithErrorCode, errorCode, resetError };
};
