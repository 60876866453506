import React, { ReactNode } from 'react';
import { Stack, Tab, Tabs } from '@mui/material';

import { ProductStatusType } from './ProductsListPage';

interface IActiveTabProps {
  activeTab: ProductStatusType;
  onTabChange: (_e: React.SyntheticEvent, newValue: ProductStatusType) => void;
  children: ReactNode;
}

export default function ActiveTab({ activeTab, onTabChange, children }: IActiveTabProps) {
  return (
    <Stack>
      <Tabs
        value={activeTab}
        onChange={onTabChange}
        sx={{
          padding: '16px 24px 0 24px',
          margin: '0 !important',
          borderBottom: '1px solid',
          borderColor: 'divider',
        }}
      >
        <Tab label="Published" value={'PUBLISHED'} />
        <Tab label="Draft" value={'DRAFTED'} />
      </Tabs>
      {/* Table*/}
      {children}
    </Stack>
  );
}
