import { useEffect, useState } from 'react';
import axios, { AxiosError, isAxiosError } from 'axios';

import { adminLoginApi, getUserData } from '../recoil/auth/auth.api';

export const useAuthInitialization = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasForbiddenError, setForbiddenError] = useState(false);

  const handle403Error = (errorCode: string) => {
    switch (errorCode) {
      case 'CONNECT:USER_NOT_ALLOWED':
        window.location.replace(process.env.REACT_APP_CLOUD_CLIENT_URL as string);
        break;
      case 'USER_ROLE_NOT_ALLOWED':
        window.location.replace(process.env.REACT_APP_CONNECT_CLIENT_URL as string);
        break;
      default:
        window.location.replace(process.env.REACT_APP_CONNECT_CLIENT_URL as string);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const newAccessToken = await adminLoginApi();
        axios.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
        const { data } = await getUserData();
        if (!data) {
          console.error('No user data found');
          return;
        }
        setIsLoading(false);
      } catch (e) {
        if (isAxiosError(e)) {
          const axiosError = e as AxiosError<{ errorCode: string }>;
          const { status } = axiosError.response || {};
          const errorCode = axiosError.response?.data?.errorCode;
          if (status === 400 && errorCode === 'CONNECT:USER_NOT_FOUND') {
            setIsLoading(false);
            window.location.replace(process.env.REACT_APP_CONNECT_CLIENT_URL as string);
          } else if (status === 403 && errorCode) {
            // TODO : 403 error component
            setIsLoading(false);
            // setForbiddenError(true);
            handle403Error(errorCode);
          }
        }
        console.error('Initialization failed', e);
      }
    };
    initialize();
  }, []);

  return { isLoading, hasForbiddenError };
};
