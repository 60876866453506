import React, { useState } from 'react';
import { Checkbox, TableCell, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';

import { ReactComponent as CustomCheckboxIcon } from '../../../assets/images/custom-checkbox.svg';
import { ReactComponent as CustomCheckboxCheckedIcon } from '../../../assets/images/custom-checkbox-checked.svg';
import CustomDataTable from '../../../components/table/DataTable';
import { CHECK_ARRAY_IS_EMPTY_OR_NOT } from '../../../constants/helper/helperFunctions';
import { InvoiceDTO } from '../../../recoil/dto/response/invoice.dto';
import { getStatusStyle } from './Helper';
import TableRowActions from './TableRowActions';

const tableColumn = [
  {
    id: 'checkAll',
    label: 'chackbox',
    minWidth: 100,
    alignCol: 'center',
  },
  { id: 'username', label: 'User Name', minWidth: 100, alignCol: 'left' },
  {
    id: 'orgnizationName',
    label: 'Orgnization Name',
    minWidth: 200,
    alignCol: 'right',
  },
  { id: 'ptName', label: 'Pt Name', minWidth: 200, alignCol: 'center' },
  {
    id: 'orderNumber',
    label: 'Order Number',
    minWidth: 100,
    alignCol: 'center',
  },
  { id: 'amount', label: 'Amount (JPY)', minWidth: 100, alignCol: 'center' },
  {
    id: 'completedDate',
    label: 'Completed Date',
    minWidth: 100,
    alignCol: 'center',
  },
  { id: 'lab', label: 'Lab', minWidth: 100, alignCol: 'center' },
  { id: 'status', label: 'Status', minWidth: 100, alignCol: 'center' },
  { id: 'actions', label: '', minWidth: 100 },
];

interface StyledIStatusProps {
  status: string;
}

const StyledStatus = styled('span')<StyledIStatusProps>`
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  min-width: 100px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  ${({ status }: any) => getStatusStyle(status)};
`;

interface Iprops {
  invoiceListing: InvoiceDTO[];
  selectedInvoicesForInprogress: string[];
  setSelectedInvoicesForInprogress: React.Dispatch<React.SetStateAction<string[]>>;
  getInvoiceByIdHandler: (id: string) => void;
}
interface Error {
  error: boolean;
  message: string;
}
export default function SalesHistoryTable({
  invoiceListing,
  getInvoiceByIdHandler,
  selectedInvoicesForInprogress,
  setSelectedInvoicesForInprogress,
}: Iprops) {
  const [checkAll, setCheckAll] = React.useState(false);
  // eslint-disable-next-line
  const [showErrorAlert, setShowErrorAlert] = useState<Error>({
    error: false,
    message: '',
  });
  const selecteAllInProgressInvoices = (check: boolean) => {
    if (check) {
      if (CHECK_ARRAY_IS_EMPTY_OR_NOT(invoiceListing)) {
        let filteredAllConfirmedInvoices = invoiceListing.filter((item) => !item.status);
        if (CHECK_ARRAY_IS_EMPTY_OR_NOT(filteredAllConfirmedInvoices)) {
          setSelectedInvoicesForInprogress([...filteredAllConfirmedInvoices.map((item) => item._id)]);
        } else {
          setShowErrorAlert({
            error: true,
            message: "You don't have invoices for publishing",
          });
        }
        console.log(filteredAllConfirmedInvoices, 'filteredAllConfirmedInvoices');
      }
    } else {
      setSelectedInvoicesForInprogress([]);
    }
  };

  return (
    <CustomDataTable tableColumn={tableColumn} rowsPerPage={10}>
      {invoiceListing.map((row, index) => {
        return (
          <TableRow key={row._id} className={row?.status ? '' : ``}>
            <TableCell align="center" sx={{ pl: 5 }}>
              {!row?.status && (
                <Checkbox
                  checkedIcon={<CustomCheckboxCheckedIcon />}
                  icon={<CustomCheckboxIcon />}
                  checked={
                    CHECK_ARRAY_IS_EMPTY_OR_NOT(selectedInvoicesForInprogress)
                      ? selectedInvoicesForInprogress.includes(row?._id)
                      : false
                  }
                  onChange={(e) => {
                    let copyArray = [...selectedInvoicesForInprogress];
                    if (copyArray.includes(row?._id)) {
                      let findIndex = copyArray.findIndex((item) => item === row?._id);
                      if (findIndex !== null) {
                        copyArray.splice(findIndex, 1);
                      }
                    } else {
                      copyArray.push(row?._id);
                    }
                    setSelectedInvoicesForInprogress([...copyArray]);

                    console.log(e.target.checked, 'checked');
                  }}
                />
              )}
            </TableCell>
            <TableCell align="left">{row?.user?.name?.fullName}</TableCell>
            <TableCell align="right">{row?.clinicOrganization?.name}</TableCell>
            <TableCell align="center">
              <Typography
                noWrap
                sx={{
                  maxWidth: '300px',
                }}
              >
                {row?.order?.patientName}
              </Typography>
            </TableCell>
            <TableCell align="center">{row?.order?.orderNo}</TableCell>
            <TableCell align="center">{row?.order?.productPrice?.total}</TableCell>
            <TableCell align="center">{moment(row?.updatedAt).format('YYYY-MM-DD')}</TableCell>
            <TableCell align="center">{row?.labOrganization?.name}</TableCell>
            <TableCell align="center">
              <StyledStatus status={row?.status}>{row?.status ? row?.status : '-'}</StyledStatus>
            </TableCell>
            <TableCell align="left">
              <TableRowActions getInvoiceByIdHandler={getInvoiceByIdHandler} id={row?._id} />
            </TableCell>
          </TableRow>
        );
      })}

      {invoiceListing.length === 0 && (
        <TableRow>
          <TableCell colSpan={6}>
            <div className="no-data">
              <Typography variant="h4" fontSize="1.5rem" fontWeight={700} color="#6A6E74">
                You have no Sales yet.
              </Typography>
            </div>
          </TableCell>
        </TableRow>
      )}
    </CustomDataTable>
  );
}
