import React, { useState } from 'react';
import { Button, Card, Stack, Typography } from '@mui/material';

import Iconify from '../../../components/iconify/Iconify';
import { SEARCH_TARGET_TYPE } from '../../../components/selectInput/SearchTargetSelect';
import CommonFilterBar, { CaseToolbarDataTypes } from '../../../components/table/CommonFilterBar';
import { usePersistentPagination } from '../../../components/table/usePersistentPagination';
import useTable from '../../../components/table/useTable';
import { useOrderListQuery } from '../../../recoil/order/useOrder';
import OrderListTable from './OrderListTable';

export enum FILTER_TYPE {
  PATIENT_NAME = 'PATIENT_NAME',
  USER_NAME = 'USER_NAME',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
  ORDER_NUMBER = 'ORDER_NUMBER',
  LAB_NAME = 'LAB_NAME',
}

const orderSearchTargetList: SEARCH_TARGET_TYPE[] = [
  { id: FILTER_TYPE.PATIENT_NAME, label: 'Patient name' },
  { id: FILTER_TYPE.USER_NAME, label: 'User name' },
  { id: FILTER_TYPE.ORGANIZATION_NAME, label: 'Organization name' },
  { id: FILTER_TYPE.ORDER_NUMBER, label: 'Order number' },
  { id: FILTER_TYPE.LAB_NAME, label: 'Lab' },
];

const OrderPage = () => {
  const { dense, page, rowsPerPage, onChangeDense, onChangePage, onChangeRowsPerPage, setPage } = useTable({
    listName: 'orderList',
    defaultRowsPerPage: 10,
    defaultCurrentPage: 0,
  });

  const [extraFilter, setExtraFilter] = useState({});
  const resetPage = () => {
    setPage(0);
  };

  const { data, isPending } = useOrderListQuery({ option: { page: page + 1, size: rowsPerPage, ...extraFilter } });
  const orderListData = data?.data?.content;
  const totalElement = data?.data?.totalElements ?? 0;

  const handleDataChange = (data: CaseToolbarDataTypes) => {
    const { startDate, endDate, searchText, searchTarget } = data;
    setExtraFilter({
      startDate: startDate?.toISOString(),
      endDate: endDate?.add(1, 'day').toISOString(),
      ...(searchText !== ''
        ? { filterType: !searchTarget && searchText ? FILTER_TYPE.PATIENT_NAME : searchTarget }
        : {}),

      ...(searchText !== '' && { searchKeyword: searchText }),
    });
    resetPage();
  };

  usePersistentPagination({ listKey: 'orderList', flagKey: 'orderDetail', setPage });

  return (
    <Stack gap="40px" sx={{ px: 7, py: 5 }}>
      <Typography variant="h3">Order list</Typography>
      <Card>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ padding: '24px' }}>
          <CommonFilterBar
            filterList={['date', 'searchTarget', 'searchText']}
            resetPage={resetPage}
            onDataChange={handleDataChange}
            searchTargetList={orderSearchTargetList}
            searchTargetName={'Search target'}
          />
          <Button
            variant="outlined"
            color="primary"
            size="medium"
            startIcon={<Iconify icon="ic:outline-file-download" />}
          >
            Export
          </Button>
        </Stack>
        <OrderListTable
          dense={dense}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangeDense={onChangeDense}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          orderListData={orderListData}
          totalElement={totalElement}
          isPending={isPending}
        />
      </Card>
    </Stack>
  );
};

export default OrderPage;
