import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { OrderDetailResponse, OrderRemakeHistory } from '../../../client';
import { APP_ROUTES } from '../../../constants/AppRoutes';
import { useOrderRemakeHistoryQuery } from '../../../recoil/order/useOrder';
import { getFormatDate } from '../../../utils/formatDate';

export const RemakeHistorySelect = ({ orderDetailInfo }: { orderDetailInfo: OrderDetailResponse }) => {
  const navigate = useNavigate();

  const { canBeRemade, originalOrderId, id: currentOrderId } = orderDetailInfo;

  const remakeOrderId = canBeRemade ? (originalOrderId ? currentOrderId : '') : currentOrderId;
  const { data: remakeHistoryResponse } = useOrderRemakeHistoryQuery(remakeOrderId);
  const remakeHistory = remakeHistoryResponse?.data;
  const remakeHistoryList = remakeHistory && processedRemakeList(remakeHistory, currentOrderId);

  return (
    remakeHistoryList && (
      <Select
        variant="outlined"
        size="medium"
        value={currentOrderId}
        displayEmpty
        onChange={(e) => {
          navigate(`/${APP_ROUTES.ORDER_DETAIL.ROUTE}/${e.target.value}`);
        }}
        renderValue={() => 'Remake order'}
        sx={{
          width: '178px',
          height: '54px !important',
          alignSelf: 'flex-end',
          '& .MuiSelect-select': {
            height: '54px !important',
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        {remakeHistoryList.map(({ displayDate, orderId, isDisabled, isSelected }) => (
          <MenuItem key={orderId} value={orderId} disabled={isDisabled} selected={isSelected}>
            {displayDate}
          </MenuItem>
        ))}
      </Select>
    )
  );
};

const processedRemakeList = (remakeOrderList: OrderRemakeHistory[], currentOrderId: string) => {
  const dateCountMap: Record<string, number> = {};

  remakeOrderList.forEach((item) => {
    const formattedDate = getFormatDate(item.createdAt);
    dateCountMap[formattedDate] = (dateCountMap[formattedDate] || 0) + 1;
  });

  return remakeOrderList.map((item) => {
    const formattedDate = getFormatDate(item.createdAt);
    const instanceIndex = dateCountMap[formattedDate]--;
    const duplicateSuffix = instanceIndex > 1 ? ` (${instanceIndex - 1})` : '';

    const createdAtTime = new Date(item.createdAt).getTime();
    const isDisabled = Date.now() - createdAtTime > 3 * 365 * 24 * 60 * 60 * 1000;

    return {
      ...item,
      displayDate: `${formattedDate}${duplicateSuffix}`.trim(),
      isDisabled,
      isSelected: currentOrderId === item.orderId,
    };
  });
};
