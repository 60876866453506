import React from 'react';
import { Button, Card, Stack, Typography } from '@mui/material';

import { OrderDetailResponse } from '../../../client';
import Iconify from '../../../components/iconify/Iconify';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { AssignLabDialogue } from './Dialogue/AssignLabDialogue';
import { RejectOrderDialogue } from './Dialogue/RejectOrderDialogue';

export const OrderDetailAction = ({ orderDetailInfo }: { orderDetailInfo: OrderDetailResponse }) => {
  const assignLabDialogue = useDialogue();
  const rejectOrderDialogue = useDialogue();

  const showActionArea = !orderDetailInfo.lab && orderDetailInfo.status === 'ORDERED';

  return (
    <>
      {showActionArea && (
        <Card sx={{ flexGrow: 1 }}>
          <Stack direction="column" gap="20px" sx={{ padding: '32px 24px' }}>
            <Typography variant="h5">Action</Typography>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap="16px" sx={{ width: '100%' }}>
              <Button
                variant="outlined"
                color="success"
                size="large"
                startIcon={<Iconify icon="eva:checkmark-circle-2-fill" />}
                onClick={() => assignLabDialogue.handleOpenDialogue()}
                sx={{ flexGrow: 1 }}
              >
                Receive and assign a lab
              </Button>
              <Button
                variant="outlined"
                color="error"
                size="large"
                startIcon={<Iconify icon="solar:close-circle-bold" />}
                onClick={() => rejectOrderDialogue.handleOpenDialogue()}
                sx={{ flexGrow: 1 }}
              >
                Reject
              </Button>
            </Stack>
          </Stack>
        </Card>
      )}
      {assignLabDialogue.open && (
        <AssignLabDialogue
          open={assignLabDialogue.open}
          onClose={assignLabDialogue.handleCloseDialogue}
          orderDetailInfo={orderDetailInfo}
        />
      )}
      {rejectOrderDialogue.open && (
        <RejectOrderDialogue
          open={rejectOrderDialogue.open}
          onClose={rejectOrderDialogue.handleCloseDialogue}
          orderId={orderDetailInfo.id}
        />
      )}
    </>
  );
};
