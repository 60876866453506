import { BackOfficeOrderResponse } from '../../../client';

export const STATUS_LABELS: Partial<Record<BackOfficeOrderResponse['status'] | 'ALL', string>> = {
  ALL: 'All',
  ORDERED: 'New Order',
  DESIGNING: 'Designing',
  WAITING_DESIGN_CONFIRMATION: 'Confirmation required',
  SHIPPED: 'Shipped',
  COMPLETED: 'Completed',
  REJECTED: 'Rejected',
  QC_REJECTED: 'Rejected',
  LAB_REJECTED: 'Rejected',
  MANUFACTURING: 'Manufacturing',
  CANCELED: 'Canceled',
};
