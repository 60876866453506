import * as React from 'react';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, FormHelperText, Stack, TextField, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import humanizeString from 'humanize-string';

import Placeholder from '../../../assets/images/placeholder.svg';
import { BackOfficeOrganizationResponse, BackOfficePatchOrganizationRequest, organizationType } from '../../../client';
import Label from '../../../components/label';
import { queryClient } from '../../../index';
import { useOrganizationActions } from '../../../recoil/organizations/useOrganizations';
import useTopAlert from '../../../recoil/topAlert/useTopAlert';
import { WarningIcon } from '../../../theme/overrides/CustomIcons';
import Image from '../../../theme/overrides/Image';
import { formattedAddress } from '../../../utils/formatString';

const ORGANIZATION_TYPE_MAP = {
  USER: 'Normal user',
  PARTNER_LAB: 'Partner Lab',
};

const CODE_TYPE_MAP = {
  DENTAL_CLINIC: 'Dental Clinic',
  DENTAL_LAB: 'Dental Lab',
};

interface Props {
  organizationInfo: BackOfficeOrganizationResponse;
}

export default function InformationSection({ organizationInfo }: Props) {
  const { setTopAlert } = useTopAlert();

  const {
    id,
    name,
    address,
    businessPhone,
    organizationType,
    corporateNumber,
    corporateNumberType,
    certificateOfIncorporation,
  } = organizationInfo;
  const { useOrganizationTypeMutation, useOrganizationInfoMutation } = useOrganizationActions();
  const { mutateAsync: orgTypeMutate, isPending } = useOrganizationTypeMutation();
  const { mutateAsync: orgInfoMutate } = useOrganizationInfoMutation();

  const [isEditMode, setIsEditMode] = useState(false);

  const defaultValues = {
    organizationType,
    businessPhone: businessPhone.number,
    organizationCode: corporateNumberType,
    corporateNumber,
  };

  const {
    control,
    handleSubmit,
    watch,
    setError,
    reset,
    formState: { isDirty, dirtyFields },
  } = useForm({
    defaultValues,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [isEditMode]);

  const submitDisabled = (isEditMode && !isDirty) || !watch('corporateNumber') || !watch('businessPhone');
  const onSubmit = async (data: any) => {
    try {
      if (dirtyFields.organizationType) {
        await orgTypeMutate({
          organizationId: id,
          requestBody: { organizationType: data.organizationType },
        });
      }

      const requestBody = Object.keys(dirtyFields).reduce((acc, field) => {
        switch (field) {
          case 'businessPhone':
            acc.phone = data.businessPhone;
            break;
          case 'corporateNumber':
            acc.corporateNumber = data.corporateNumber;
            break;
          case 'organizationCode':
            acc.corporateNumberType = data.organizationCode;
            break;
          default:
            break;
        }
        return acc;
      }, {} as BackOfficePatchOrganizationRequest);

      if (Object.keys(requestBody).length > 0) {
        await orgInfoMutate({
          organizationId: id,
          requestBody,
        });
      }

      setIsEditMode((prev) => !prev);
      await queryClient.invalidateQueries({ queryKey: ['organization-details'] });
      setTopAlert({ open: true, variant: 'filled', severity: 'success', description: 'Updated successfully' });
    } catch (e: any) {
      const data = await e.response.data;
      if (data.errorCode === 'CONNECT:ORGANIZATION_PHONE_NUMBER_ALREADY_EXISTS') {
        setError('businessPhone', {
          type: 'error',
          message: 'Organization phone number already exists.',
        });
      }
      console.log(e);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ overflowX: 'auto' }}>
      <Stack direction="row" justifyContent="space-between" sx={{ mb: '16px' }}>
        <Typography variant={'h5'}>Information</Typography>
        {isEditMode && <Label>Edit</Label>}
      </Stack>
      <Stack sx={{ gap: '12px', mb: '24px' }}>
        <InfoField label={'Organization name'} value={name} />
        <InfoField label={'Organization address'} value={formattedAddress(address)} />
        <InfoField
          label={'Organization phone'}
          value={businessPhone.number}
          name={'businessPhone'}
          control={control}
          isEditMode={isEditMode}
        />

        <InfoField
          label={'Organization type'}
          value={ORGANIZATION_TYPE_MAP[organizationType]}
          name={'organizationType'}
          control={control}
          isEditMode={isEditMode}
          selectOptions={ORGANIZATION_TYPE_MAP}
        />
        <FormSection title="Organization code type">
          {isEditMode ? (
            <Stack direction="row" gap="8px">
              <InfoField
                label=""
                value="CLINIC"
                control={control}
                name="organizationCode"
                isEditMode={isEditMode}
                selectOptions={CODE_TYPE_MAP}
              />
              <InfoField
                label=""
                name="corporateNumber"
                value={corporateNumber}
                control={control}
                isEditMode={isEditMode}
              />
            </Stack>
          ) : (
            <Typography variant="body2" color="text.secondary">
              {`${corporateNumber} (${humanizeString(corporateNumberType)})`}
            </Typography>
          )}
        </FormSection>
        <Stack sx={{ gap: '4px' }}>
          <Typography variant={'subtitle2'}>Certificate of incorporation</Typography>
          <Stack sx={{ width: '483px', height: '301px' }}>
            <Image
              alt="certificate-of-incorporation"
              src={certificateOfIncorporation?.fileUrl || Placeholder}
              sx={{ width: '100%', height: '100%' }}
              disabledEffect={!certificateOfIncorporation?.fileUrl}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack sx={{ flexDirection: 'row', gap: '16px', justifyContent: 'flex-end' }}>
        {isEditMode ? (
          <>
            <Button
              variant="outlined"
              color={'error'}
              onClick={() => {
                setIsEditMode(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              loading={isPending}
              variant="contained"
              color="primary"
              sx={{ width: 'fit-content' }}
              disabled={submitDisabled}
            >
              Update
            </LoadingButton>
          </>
        ) : (
          <Button variant="contained" color="primary" onClick={() => setIsEditMode(true)}>
            Edit
          </Button>
        )}
      </Stack>
    </form>
  );
}

const FormSection = ({ title, children }: { title: string; children: React.ReactNode }) => (
  <Stack sx={{ gap: '4px' }}>
    <Typography variant="subtitle2">{title}</Typography>
    {children}
  </Stack>
);

const InfoField = ({
  label,
  value,
  control,
  name,
  isEditMode = false,
  selectOptions,
}: {
  label: string;
  value: string;
  control?: any;
  name?: string;
  isEditMode?: boolean;
  selectOptions?: Record<string, string>;
}) => (
  <FormSection title={label}>
    {isEditMode && name ? (
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) =>
          selectOptions ? (
            <FormControl fullWidth>
              <Select {...field} sx={{ height: '40px', fontSize: '14px' }}>
                {Object.entries(selectOptions).map(([key, optionLabel]) => (
                  <MenuItem key={key} value={key} sx={{ fontSize: '14px' }}>
                    {optionLabel}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <FormControl fullWidth>
              <TextField size="small" {...field} error={!!fieldState.error} />

              {fieldState.error && 'message' in fieldState.error && (
                <Stack sx={{ flexDirection: 'row', gap: '4px', alignItems: 'center', mt: '8px', ml: '12px' }}>
                  <WarningIcon sx={{ width: 16, height: 16, display: 'inline' }} color={'error'} />
                  <FormHelperText error sx={{ m: 0 }}>
                    {fieldState.error.message}
                  </FormHelperText>
                </Stack>
              )}
            </FormControl>
          )
        }
      />
    ) : (
      <Typography variant="body2" color="text.secondary">
        {value}
      </Typography>
    )}
  </FormSection>
);
