import { atom } from 'recoil';
import { UserDashboardStateDTO } from '../dto/states/dashboardState';

export const USER_DASHBOARD_ANALYTICS: UserDashboardStateDTO = {
  isLoading: false,
  isError: false,
  errorMessage: '',
  userDashboardAnalytics: {
    _id: '',
    allOrders: 0,
    monthly: 0,
    today: 0,
    weekly: 0,
    chartStatistics: [],
  },
};

export const userDashboardAnalyticsState = atom({
  key: 'userDashboardAnalytics',
  default: USER_DASHBOARD_ANALYTICS,
});
