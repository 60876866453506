import { Box, SxProps, TablePagination, TablePaginationProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

type Props = {
  dense?: boolean;
  onChangeDense?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  sx?: SxProps<Theme>;
};

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [10, 20, 30],
  sx,
  ...other
}: Props & TablePaginationProps) {
  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <TablePagination
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        sx={{ borderTop: 'none !important' }}
        {...other}
      />
    </Box>
  );
}
