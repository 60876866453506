import { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useRecoilState } from 'recoil';

import { CardStatistics } from '../../components/cards/CardStatistics';
import { CardStats } from '../../components/cards/CardStats';
import {
  CHAT_STATISTICS_OPTIONS,
  DashboardStatsData,
  GET_DASHBOARD_CARD_STATS,
  SET_CHAT_STATISTICS_OPTIONS_SERIES,
} from '../../constants/helper/helperFunctions';
import { userDashboardAnalyticsState } from '../../recoil/dashboard/dashboardState';
import { useDashboardActions } from '../../recoil/dashboard/useDashboard';
import { UserDashboardStateDTO } from '../../recoil/dto/states/dashboardState';

interface cardStats {
  label: string;
  value: number;
}
const Dashboard = () => {
  const [userAnalyticsState] = useRecoilState<UserDashboardStateDTO>(userDashboardAnalyticsState);
  const { userDashboardAnalytics } = userAnalyticsState;
  const [searchAnalyticsByMonth, setSearchAnalyticsByMonth] = useState<string>('');
  const [chartStatisticsOptions, setChartStatisticsOptions] = useState<any>({ ...CHAT_STATISTICS_OPTIONS });
  const { getUserDashboardAnalytics } = useDashboardActions();

  const [cardStats, setCardStats] = useState<cardStats[]>(DashboardStatsData);

  // useEffect(() => {
  //   getUserDashboardAnalytics({
  //     from: moment().startOf('month').format('LLL'),
  //     to: moment().endOf('month').format('LLL'),
  //   });
  //   // eslint-disable-next-line
  // }, []);

  // useEffect(() => {
  //   if (searchAnalyticsByMonth) {
  //     getUserDashboardAnalytics({
  //       from: moment(searchAnalyticsByMonth).month(searchAnalyticsByMonth).startOf('month').format('LLL'),
  //       to: moment(searchAnalyticsByMonth).month(searchAnalyticsByMonth).endOf('month').format('LLL'),
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [searchAnalyticsByMonth]);
  useEffect(() => {
    setCardStats(GET_DASHBOARD_CARD_STATS(cardStats, userDashboardAnalytics));
    if (userDashboardAnalytics?.chartStatistics) {
      setChartStatisticsOptions({
        ...chartStatisticsOptions,
        series: SET_CHAT_STATISTICS_OPTIONS_SERIES(userDashboardAnalytics?.chartStatistics),
      });
    } else {
      setChartStatisticsOptions({
        ...chartStatisticsOptions,
        series: [],
      });
    }
    // eslint-disable-next-line
  }, [userDashboardAnalytics]);
  return (
    <Box p={6}>
      <Typography mb={2} fontSize={12}>
        Connect Back Office v1.1.0
      </Typography>
      <Grid container spacing={3}>
        <Grid container md={12} sx={{ mb: 3.75 }}>
          {cardStats.map((item, index) => {
            return (
              <Grid item key={index} sm={6} md={3}>
                <CardStats label={item.label} value={`${item.value}`} />
              </Grid>
            );
          })}
        </Grid>
        <Grid item md={12}>
          <CardStatistics
            setSearchAnalyticsByMonth={setSearchAnalyticsByMonth}
            chartStatisticsOptions={chartStatisticsOptions}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
