import * as React from 'react';
import { Button, Stack, TableCell, TableRow, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ProductDetail } from '../../../client';
import { DataTableWithScroll } from '../../../components/table/DataTableWithScroll';
import { ICloseButtonOnlyDialogueProps } from '../../../constants/helperHooks/useDialogue';
import { useProductItemLifeCycleMutation, useProductItemListDelete } from '../../../recoil/products/useProduct';
import useTopAlert from '../../../recoil/topAlert/useTopAlert';
import { capitalizeFirstLetter } from '../../../utils/formatString';
import { convertOrderRangeText } from '../../Order/Helper';

type MovetoPublishAndDraftDialogueProps = ICloseButtonOnlyDialogueProps & {
  checkedItemList: ProductDetail[];
  setCheckedItemList: React.Dispatch<React.SetStateAction<ProductDetail[]>>;
  isDraft: boolean;
  isDeleteMode?: boolean;
};

const tableColumn = [
  {
    id: 'product',
    label: 'Product',
    alignCol: 'left',
    maxWidth: 200,
  },
  { id: 'method', label: 'Method', alignCol: 'center', maxWidth: 120 },
  {
    id: 'material',
    label: 'Material',
    alignCol: 'center',
    maxWidth: 120,
  },
  { id: 'shape', label: 'Shape', alignCol: 'center', maxWidth: 120 },
  { id: 'range', label: 'Range', alignCol: 'center', maxWidth: 180 },
  { id: 'uniquePrice', label: 'Unique price', alignCol: 'center', maxWidth: 144 },
];

const TO_DRAFT = {
  title: 'Move to draft',
  desc:
    'Selected products will move to to the draft list. \n' +
    "Once moved, they will no longer be visible on the user's screen.\n" +
    'Please be cautious when moving them.',
};

const TO_PUBLISH = {
  title: 'Publish products',
  desc:
    'Ready to publish the selected products?\n' +
    'They will appear instantly to users, so make sure everything is accurate before you proceed.',
};

const DELETE = {
  title: 'Delete product',
  desc:
    "If you delete it now, it will be immediately removed from the user's screen as well.\n" +
    'Please be cautious when deleting.',
};

export const ProductPublishAndDraftDialogue = ({
  open,
  onClose,
  checkedItemList,
  isDraft,
  isDeleteMode,
  setCheckedItemList,
}: MovetoPublishAndDraftDialogueProps) => {
  const { mutateAsync: deleteProductItems } = useProductItemListDelete();

  const { mutateAsync: productLifeCycleChange, variables } = useProductItemLifeCycleMutation();
  const { setTopAlert } = useTopAlert();
  const { title, desc } = isDeleteMode ? DELETE : isDraft ? TO_PUBLISH : TO_DRAFT;

  const handleConfirmClick = async (isDraft: boolean) => {
    const idArray = checkedItemList.map((item) => item.id);
    await productLifeCycleChange({ targetProductIds: idArray, newStatus: isDraft ? 'PUBLISHED' : 'DRAFTED' });
    setTopAlert({
      open: true,
      variant: 'filled',
      severity: 'success',
      description: `Products ${isDraft ? 'published' : 'drafted'} successfully`,
    });
    setCheckedItemList([]);
  };
  const handleDeleteClick = async () => {
    const idArray = checkedItemList.map((item) => item.id);
    await deleteProductItems(idArray);
    setTopAlert({
      open: true,
      description: `Product${idArray.length > 1 ? 's' : ''} deleted successfully`,
      severity: 'success',
      variant: 'filled',
    });
    setCheckedItemList([]);
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          minWidth: '932px',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack gap="16px" sx={{ height: '402px', width: '100%' }}>
          <Typography variant="body2" color="text.secondary" sx={{ whiteSpace: 'pre-line' }}>
            {desc}
          </Typography>
          <DataTableWithScroll tableColumn={tableColumn} sx={{ minHeight: '296px !important' }}>
            {checkedItemList?.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  sx={{
                    '& td': {
                      fontWeight: '400',
                      fontSize: '14px',
                      lineHeight: '22px',
                    },
                  }}
                >
                  <TableCell align="left" sx={{ maxWidth: 200 }}>
                    {capitalizeFirstLetter(row.prosthesis.productOptionValue)}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 120 }}>
                    {capitalizeFirstLetter(row.method)}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 120 }}>
                    {capitalizeFirstLetter(row.material)}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 120 }}>
                    {capitalizeFirstLetter(row.shape)}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 180 }}>
                    {convertOrderRangeText(row.designManufactureCombination)}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 144 }}>
                    {`JPY ${row.price.toLocaleString()}`}
                  </TableCell>
                </TableRow>
              );
            })}
          </DataTableWithScroll>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ border: 'none !important' }}>
        <Button variant="outlined" color="inherit" size="medium" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color={isDeleteMode ? 'error' : 'primary'}
          size="medium"
          onClick={() => {
            isDeleteMode ? handleDeleteClick() : handleConfirmClick(isDraft);
            onClose();
          }}
        >
          {isDeleteMode ? 'Delete' : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
