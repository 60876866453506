import React from 'react';
import { Button, Stack } from '@mui/material';

interface IProductTableToolbarProps {
  onOpenProductCreateDialogue: () => void;
}

export const ProductsTableToolbar = ({ onOpenProductCreateDialogue }: IProductTableToolbarProps) => {
  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ padding: '20px 20px 24px' }}>
      <Button variant="outlined" size="medium" onClick={onOpenProductCreateDialogue} sx={{ minWidth: '126px' }}>
        New Product
      </Button>
    </Stack>
  );
};
