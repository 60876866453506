import { Button, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export const LeaveSiteDialogue = ({ blocker }: { blocker: any }) => {
  return (
    <Dialog
      open={blocker.state === 'blocked'}
      PaperProps={{
        sx: {
          width: '539px !important',
          minWidth: '539px',
        },
      }}
    >
      <DialogTitle>Leave site?</DialogTitle>
      <DialogContent>
        <Stack sx={{ py: '12px' }}>
          <Typography variant="body1" color="text.secondary">
            Any unsaved changes will be lost.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ border: 'none !important' }}>
        <Button variant="outlined" color="inherit" size="medium" onClick={blocker.reset}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" size="medium" onClick={blocker.proceed}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
