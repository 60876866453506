import * as React from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';

import { OrderDetailResponse, OrderItem } from '../../../../client';
import { FDIToPalmer } from '../../../../utils/convertToPalmer';
import { getFormatDate } from '../../../../utils/formatDate';
import { capitalizeFirstLetter, formattedAddress } from '../../../../utils/formatString';
import { convertOrderRangeText } from '../../Helper';
import { LowerElement, OrderPrintRx } from '../../PrintRx/printRx';
import { usePrintRx } from './usePrintRx';

interface Iprops {
  orderDetailInfo: OrderDetailResponse;
}

export const OrderDesignTabRx = ({ orderDetailInfo }: Iprops) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const { upperRef, lowerRef, handleGeneratePDF } = usePrintRx();

  const groupedOrderItems = orderDetailInfo.orderItems.reduce(
    (acc, item) => {
      const { groupId, groupType, toothNumber } = item;
      if (groupType === 'BRIDGE' && groupId) {
        if (!acc[groupId]) acc[groupId] = [];
        acc[groupId].push(item);
      } else {
        acc[toothNumber] = [item];
      }
      return acc;
    },
    {} as Record<string, OrderItem[]>,
  );

  return (
    <>
      <Stack direction="column" sx={{ width: '100%' }}>
        <Stack direction="column" gap="12px" sx={{ padding: '24px 20px 24px 20px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
            <Stack direction="column" gap="8px">
              <RXInfoRow
                label={'Design confirm process:'}
                value={orderDetailInfo.designConfirmProcessIncluded ? 'Include' : 'Exclude'}
              />
              <RXInfoRow
                label={'Order range:'}
                value={convertOrderRangeText(orderDetailInfo.designManufactureCombination)}
              />
              <RXInfoRow
                label={'Try-in date:'}
                value={orderDetailInfo.patient.tryInDate ? getFormatDate(orderDetailInfo.patient.tryInDate) : '-'}
              />
              <RXInfoRow
                label={'Visit date:'}
                value={orderDetailInfo.patient.visitDate ? getFormatDate(orderDetailInfo.patient.visitDate) : '-'}
              />
            </Stack>
            <Button variant="outlined" color="primary" onClick={handleGeneratePDF}>
              Print Rx
            </Button>
          </Stack>
          {orderDetailInfo.note && (
            <textarea
              disabled={true}
              ref={textareaRef}
              className="notes-input"
              style={{
                padding: '10px 12px',
                lineHeight: '22px',
                resize: 'none',
                color: 'text.primary',
                fontSize: '14px',
                fontWeight: 400,
                borderRadius: '8px',
                width: '100%',
                height: 'auto',
                backgroundColor: alpha(grey['500'], 0.08),
                borderColor: alpha(grey['500'], 0.08),
              }}
              value={orderDetailInfo.note}
            />
          )}
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ padding: '16px 20px' }} gap="16px">
          <Stack direction="column" gap="8px">
            {groupedOrderItems &&
              Object.entries(groupedOrderItems)
                .sort(([a, itemA], [b, itemB]) => itemA[0].toothNumber - itemB[0].toothNumber)
                .map(([base, orderGroup]) => (
                  <Stack
                    key={base}
                    direction="column"
                    gap="12px"
                    sx={{
                      width: '100%',
                      padding: '16px',
                      border: '1px solid',
                      borderColor: 'grey.200',
                      borderRadius: '8px',
                    }}
                  >
                    {orderGroup.map((item) => {
                      const productOptionItems = [item.method, item.material, item.shape];
                      const productOptionLabel = productOptionItems
                        .filter((value) => value && value.trim() !== '')
                        .map((value) => capitalizeFirstLetter(value))
                        .join(' - ');

                      return (
                        <Stack key={item.toothNumber} direction="column" gap="4px">
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="subtitle1">{FDIToPalmer[item.toothNumber]?.short ?? '#'}</Typography>
                            <Typography variant="subtitle2">{`JPY ${item.amount.toLocaleString()}`}</Typography>
                          </Stack>
                          <RXInfoRow value={capitalizeFirstLetter(item.prosthesis)} label={'Product type:'} />
                          <RXInfoRow value={productOptionLabel} label={'Product option:'} />
                          <RXInfoRow value={item.shadeType as string} label={'Shade:'} />
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <RXInfoRow value={capitalizeFirstLetter(item.hook)} label={'Hook:'} />
                            <Typography variant="subtitle2">{`+ JPY ${item.hookPrice.toLocaleString()}`}</Typography>
                          </Stack>
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <RXInfoRow value={item.premiumFinishing ? 'Premium' : 'Basic'} label={'Finish:'} />
                            <Typography variant="subtitle2">{`+ JPY ${item.premiumFinishingPrice.toLocaleString()}`}</Typography>
                          </Stack>
                          {item.scanBodyDetail && <RXInfoRow value={item.scanBodyDetail} label={'Scanbody:'} />}
                          {item.implantDetail && (
                            <RXInfoRow value={item.implantDetail} label={'Implant information:'} />
                          )}
                        </Stack>
                      );
                    })}
                  </Stack>
                ))}
            {orderDetailInfo.patientPrintingFilesResponse.length > 0 && (
              <Stack
                direction="column"
                gap="8px"
                sx={{
                  width: '100%',
                  padding: '12px 16px',
                  border: '1px solid',
                  borderColor: 'grey.200',
                  borderRadius: '8px',
                }}
              >
                <Typography variant="subtitle1">Additional options</Typography>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <RXInfoRow
                    value={`${orderDetailInfo.patientPrintingFilesResponse.length} order`}
                    label={'3D model:'}
                  />
                  <Typography variant="subtitle2">{`JPY ${orderDetailInfo.printingFilesAmount.toLocaleString()}`}</Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
          <Stack
            direction="column"
            gap="8px"
            sx={{
              width: '100%',
              padding: '16px',
              backgroundColor: 'grey.100',
              borderRadius: '8px',
            }}
          >
            <Typography variant="subtitle1">Cost</Typography>
            <Stack direction="column" gap="4px">
              <CostInfoRow
                label={'Subtotal'}
                value={`JPY ${(orderDetailInfo.orderAmount + orderDetailInfo.printingFilesAmount).toLocaleString()}`}
              />
              <CostInfoRow label={'Shipping fee'} value={`JPY ${orderDetailInfo.shippingFee.toLocaleString()}`} />
              <CostInfoRow label={'Total'} value={`JPY ${orderDetailInfo.billingAmount.toLocaleString()}`} />
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack direction="column" gap="8px" sx={{ padding: '16px 20px' }}>
          <Typography variant="subtitle1">Shipping information</Typography>
          <Stack direction="column" gap="4px">
            <Typography variant="subtitle2">{`${orderDetailInfo.receiver?.name.firstName} ${orderDetailInfo.receiver?.name.lastName}`}</Typography>
            <Typography variant="subtitle2">{`+${orderDetailInfo.receiver?.number.countryCode} ${orderDetailInfo.receiver?.number.number}`}</Typography>
            <Typography variant="subtitle2">{formattedAddress(orderDetailInfo.shippingAddress!)}</Typography>
            <RXInfoRow
              label={'Delivery instruction:'}
              value={orderDetailInfo.deliveryInstruction ? orderDetailInfo.deliveryInstruction : '-'}
            />
          </Stack>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack direction="column" gap="8px" sx={{ padding: '20px' }}>
          <Typography variant="subtitle1">Payment method</Typography>
          <Typography variant="subtitle2">Pay later</Typography>
        </Stack>
      </Stack>
      <Divider sx={{ padding: '12px' }} />
      <OrderPrintRx rxPrintRef={upperRef} userOrder={orderDetailInfo} />
      <LowerElement rxPrintRef={lowerRef} userOrder={orderDetailInfo} />
    </>
  );
};

const RXInfoRow = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack direction="row" textAlign="center" alignItems="center" gap="4px">
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="subtitle2" color="text.secondary">
        {value}
      </Typography>
    </Stack>
  );
};

const CostInfoRow = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography variant="subtitle2">{label}</Typography>
      <Typography variant="subtitle2">{value}</Typography>
    </Stack>
  );
};
