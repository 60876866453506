// @mui
import { List, Stack } from '@mui/material';

// locales
//
import { NavSectionProps } from '../types';
import NavList from './NavList';

// ----------------------------------------------------------------------

export default function NavSection({ data, sx, ...other }: NavSectionProps) {
  return (
    <Stack sx={sx} {...other}>
      <List disablePadding sx={{ px: 2 }}>
        {data.map((list) => (
          <NavList key={list.title + list.path} data={list} depth={1} hasChild={!!list.children} />
        ))}
      </List>
    </Stack>
  );
}
