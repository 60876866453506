import * as React from 'react';
import { Button, Card, TableCell, TableRow } from '@mui/material';

import { ProductDetail, UpdateProductRemakePriceRequest } from '../../../client';
import Label from '../../../components/label';
import CustomDataTable, { ITableColumnTypes } from '../../../components/table/DataTable';
import { TableLoading } from '../../../components/table/TableLoading';
import { TableNoData } from '../../../components/table/TableNoData';
import useTable from '../../../components/table/useTable';
import { useAllProductList } from '../../../recoil/products/useProduct';
import { convertOrderRangeText } from '../../Order/Helper';

type RemakeTablePropsType = {
  handleOpenSetupRemakeDialogue: (product: ProductDetail) => void;
  modifiedProductRemakePrice: UpdateProductRemakePriceRequest;
};

const tableColumn: ITableColumnTypes[] = [
  { id: 'modified', label: '', minWidth: 63, alignCol: 'left' },
  { id: 'prosthesis', label: 'Prosthesis', maxWidth: 200, alignCol: 'left' },
  { id: 'method', label: 'Method', maxWidth: 156, alignCol: 'left' },
  { id: 'material', label: 'Material', maxWidth: 238, alignCol: 'left' },
  { id: 'shape', label: 'Shape', maxWidth: 200, alignCol: 'left' },
  { id: 'range', label: 'Range', maxWidth: 220, alignCol: 'left' },
  {
    id: 'uniquePrice',
    label: 'Unique price',
    maxWidth: 172,
    alignCol: 'left',
  },
  {
    id: 'remake',
    label: '',
    maxWidth: 103,
    alignCol: 'center',
  },
];

export default function RemakeListTable({
  handleOpenSetupRemakeDialogue,
  modifiedProductRemakePrice,
}: RemakeTablePropsType) {
  const { dense, page, order, orderBy, rowsPerPage, onChangeDense, onChangePage, onChangeRowsPerPage } = useTable({
    defaultRowsPerPage: 10,
    defaultCurrentPage: 0,
    defaultOrderBy: 'id',
    defaultOrder: 'desc',
  });

  const { data, isPending } = useAllProductList({
    status: 'PUBLISHED',
    page: page + 1,
    size: rowsPerPage,
    sort: [order, orderBy],
  });

  const productRemakeList = data?.data?.content ?? [];
  const totalElement = data?.data?.totalElements ?? 0;

  return (
    <Card>
      <CustomDataTable
        tableColumn={tableColumn}
        rowsPerPage={rowsPerPage}
        page={page}
        totalElement={totalElement}
        dense={dense}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangeDense={onChangeDense}
      >
        {isPending ? (
          <TableLoading style={{ height: '344px' }} colSpan={tableColumn.length} />
        ) : productRemakeList && productRemakeList?.length ? (
          productRemakeList.map((product: ProductDetail, index: number) => {
            return (
              <TableRow key={product.id}>
                <TableCell align="center" sx={{ width: 63 }}>
                  {modifiedProductRemakePrice.updateSingle.find((item) => item.productId === product.id) ||
                  modifiedProductRemakePrice.updateAllInfo.length > 0 ? (
                    <Label variant="soft" color="warning" sx={{ width: '63px' }}>
                      Modified
                    </Label>
                  ) : null}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: 200 }}>
                  {product.prosthesis.productOptionValue}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: 156 }}>
                  {product.method}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: 238 }}>
                  {product.material}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: 200 }}>
                  {product.shape}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: 220 }}>
                  {convertOrderRangeText(product.designManufactureCombination)}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: 172 }}>
                  {`JPY ${product.price.toLocaleString()}`}
                </TableCell>
                <TableCell align="center" sx={{ width: 103 }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => {
                      handleOpenSetupRemakeDialogue(product);
                    }}
                    sx={{ width: '103px' }}
                  >
                    Setup remake
                  </Button>
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableNoData style={{ height: '344px' }} colSpan={tableColumn.length} content={'No product list to show'} />
        )}
      </CustomDataTable>
    </Card>
  );
}
