// @mui
import { Box, Drawer } from '@mui/material';

import navConfig from './Nav/config-navigation';
// components
// config
// hooks
//
import NavSection from './Nav/NavSection';
// ----------------------------------------------------------------------

export default function SNBNav() {
  // const { pathname } = useLocation();
  // const navConfig = useTenantNav();
  // console.log('vertical nav config', navConfig);

  // useEffect(() => {
  //   if (openNav) {
  //     onCloseNav();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathname]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: 0,
        width: NAV.W_DASHBOARD,
      }}
    >
      <Drawer
        open
        variant="permanent"
        PaperProps={{
          sx: {
            mt: '66px',
            zIndex: 0,
            width: NAV.W_DASHBOARD,
            bgcolor: 'transparent',
            borderRightStyle: 'dashed',
          },
        }}
      >
        <NavSection data={navConfig} sx={{ mt: '23px' }} />
      </Drawer>
    </Box>
  );
}

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};
