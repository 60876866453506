import { useState } from 'react';
import { StandardTextFieldProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

import Iconify from '../../iconify/Iconify';

interface InputlessDatePickerProps {
  label: string;
  inputFormat?: string;
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  disabled?: boolean;
  error?: boolean;
  size?: 'small' | 'medium' | undefined;
  helperText?: string;
  placeholder?: string;
  endAdornment?: React.ReactNode;
  InputProps?: StandardTextFieldProps['InputProps'];
  name?: string;
  disablePast?: boolean;
}
const InputlessDatePicker = ({
  label,
  inputFormat,
  value,
  onChange,
  disabled = false,
  placeholder,
  endAdornment,
  InputProps,
  name,
  disablePast,
  ...rest
}: InputlessDatePickerProps) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <DatePicker
      disablePast={disablePast}
      disableFuture
      open={isOpen}
      onClose={() => {
        setIsOpen(false);
      }}
      value={value || null}
      onChange={(newValue) => {
        onChange && onChange(newValue as Dayjs);
      }}
      slotProps={{
        textField: {
          ...rest,
          name: name,
          size: 'small',
          placeholder: placeholder,
          disabled: disabled,
          label: label,
          inputProps: {
            value: value?.format('YYYY-MM-DD') || '',
          },
          InputProps: {
            readOnly: true,
            className: 'custom-read-only-input',
            endAdornment: endAdornment ? (
              endAdornment
            ) : (
              <Iconify icon="solar:calendar-mark-bold-duotone" width={24} color="#637381" />
            ),
            ...InputProps, // 다른 InputProps 병합
          },
          onClick: () => {
            setIsOpen(true);
          },
          onChange: (e) => {
            const newValue = e.target.value;
            console.log(newValue);
            onChange && onChange(newValue ? (newValue as unknown as Dayjs) : null);
          },
          sx: {
            '&.custom-read-only-input': {
              boxShadow: '0 0 0 1px rgba(145, 158, 171, 0.12) inset',
              borderRadius: '8px',
              color: theme.palette.primary.main,
            },
            '&:hover, &.Mui-focused': {
              '&.custom-read-only-input': {
                boxShadow: '0 0 0 1px #212B36 inset',
                borderRadius: '8px',
              },
            },
            input: {
              cursor: 'pointer',
              fontSize: '14px',
            },
            width: '200px',
          },
          InputLabelProps: {
            shrink: true,
          },
        },
      }}
    />
  );
};

export default InputlessDatePicker;
