import { designManufactureCombination } from '../../client';

export const convertOrderRangeText = (orderRange: designManufactureCombination) => {
  switch (orderRange) {
    case 'MANUFACTURE':
      return 'Manufacture only';
    case 'DESIGN':
      return 'Design only';
    case 'DESIGN_AND_MANUFACTURE':
      return 'Design & Manufacture';
    default:
      return '';
  }
};
