import { Box, Typography, Button, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getStatusStyle } from './Helper';
import React from 'react';
import CustomAlertDialog from '../../../components/dialog/CustomAlertDialog';
import { useInvoiceActions } from '../../../recoil/invoice/useInvoice';
import moment from 'moment';

interface StyledIStatusProps {
  status: string;
}

const StyledStatus = styled('span')<StyledIStatusProps>`
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
  min-width: 100px;
  margin: 20px 10px;
  display: inline-block;
  text-align: center;
  font-weight: 600;
  ${({ status }: any) => getStatusStyle(status)};
`;

export const SalesHistoryDetail = () => {
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  // eslint-disable-next-line
  const [status, setStatus] = React.useState('Published');
  const { invoiceForDetail, getInvoiceById, updateInvoiceStatusById, getInvoicesForAdminListing } = useInvoiceActions();
  const updateInvoiceStatusByIdHandler = (id: string) => {
    if (id) {
      updateInvoiceStatusById(id).then((res) => {
        getInvoiceById(id).then((res) => {
          setOpenAlertDialog(false);
          getInvoicesForAdminListing({
            startDate: '',
            endDate: '',
            searchBy: '',
            searchValue: '',
            selectBy: '',
          });
        });
      });
    }
  };
  return (
    <>
      <Box
        px={0.5}
        pt={3}
        pb={5}
        minWidth={500}
        sx={{
          '& p': { color: '#637381', fontWeight: 700 },
          '& ul': {
            mb: 4,
            gap: 0.5,
            pl: 2.5,
            display: 'flex',
            flexDirection: 'column',
            color: '#637381',
          },
        }}
      >
        <Typography component="p">Order Info</Typography>
        <ul>
          <li>User: {invoiceForDetail?.user?.name?.fullName}</li>
          <li>Organization: {invoiceForDetail?.clinicOrganization?.name}</li>
          <li
            style={{
              whiteSpace: 'nowrap',

              maxWidth: '300px',
            }}
          >
            Pt Name: {invoiceForDetail?.order?.patientName}
          </li>
          <li>O/N: {invoiceForDetail?.order?.orderNo}</li>
          <li>
            Amount{' '}
            {invoiceForDetail?.order?.productPrice?.currency
              ? `(${invoiceForDetail?.order?.productPrice?.currency})`
              : ''}
            :{invoiceForDetail?.order?.productPrice?.total}
          </li>
          <li>Completed Date: {moment(invoiceForDetail?.updatedAt).format('YYYY-MM-DD')}</li>
          <li>Lab: {invoiceForDetail?.labOrganization?.name}</li>
        </ul>
        <Divider sx={{ mb: 3, mx: -2.5, borderColor: '#B4BBC2' }} />
        <Typography component="p">Invoice Info</Typography>
        <ul>
          <li>
            Issue date: {invoiceForDetail?.issueDate ? moment(invoiceForDetail?.issueDate).format('YYYY-MM-DD') : ''}
          </li>
          <li>
            <Box display="flex" alignItems="center" gap={1}>
              Status:
              <StyledStatus status={invoiceForDetail?.status} onClick={() => setStatus('In progress')}>
                {invoiceForDetail?.status ? invoiceForDetail?.status : '-'}
              </StyledStatus>
              {invoiceForDetail?.status === 'IN_PROGRESS' && (
                <Button variant="contained" sx={{ ml: 4 }} onClick={() => setOpenAlertDialog(true)}>
                  Confirm Payout
                </Button>
              )}
            </Box>
          </li>
          <li>
            Confirm payout date:{' '}
            {invoiceForDetail?.confirmPayOutDate
              ? moment(invoiceForDetail?.confirmPayOutDate).format('YYYY-MM-DD')
              : ''}
          </li>
          <li>
            Invoice:{' '}
            <Typography component="span" sx={{ color: '#00A3EC', fontWeight: 700 }}>
              {invoiceForDetail?.invoiceNumber}
            </Typography>
          </li>
        </ul>
      </Box>
      {openAlertDialog && (
        <CustomAlertDialog
          title="Confirm Payout"
          desc="It will be processed as “Confirmed” status."
          btnActionText="Confirm"
          btnAction={() => {
            updateInvoiceStatusByIdHandler(invoiceForDetail?._id);
          }}
          open={openAlertDialog}
          salesActionBtn={true}
          handleClose={() => setOpenAlertDialog(false)}
        />
      )}
    </>
  );
};
