import { useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

import { useInvoiceActions } from '../../../recoil/invoice/useInvoice';
import SalesStaticticsTable from './Table';

const SalesStaticticsPage = () => {
  const [yearList, setYearList] = useState<number[]>([]);
  // const { getAllLabNamesList, allLabList= } = useRatingActions();
  const { getInvoiceStatictics, invoiceStatistics, isLoading } = useInvoiceActions();
  // const [selectedYear, setSeletedYear] = useState<UserSearch>({ searchBy: `${new Date().getFullYear()}` });
  // const [selectedLabName, setSeletedLabName] = useState<UserSearch>({ searchBy: '' });
  // useEffect(() => {
  //   let allYears: number[] = [];
  //   for (let i = MAKING_YEAR_FOR_PROJECT; i <= +moment().format('YYYY'); i++) {
  //     allYears.push(i);
  //   }
  //   setYearList(allYears);
  //   getAllLabNamesList();
  //   getInvoiceStatictics({ year: +moment().format('YYYY'), labName: '' });
  //   // eslint-disable-next-line
  // }, []);
  // useEffect(() => {
  //   getInvoiceStatictics({
  //     year: Number(selectedYear.searchBy),
  //     labName: selectedLabName?.searchBy === 'Search By' ? '' : selectedLabName?.searchBy!,
  //   });
  //   // eslint-disable-next-line
  // }, [selectedYear, selectedLabName]);
  return (
    <Box className="page-wrapper" sx={{ px: 7, py: 5 }}>
      <Typography className="page-title" fontSize={24} fontWeight={600}>
        Sales Statistics
      </Typography>
      {/*<SalesStatisticsTableToolbar*/}
      {/*  selectedYear={selectedYear.searchBy}*/}
      {/*  invoiceStatistics={invoiceStatistics}*/}
      {/*  yearList={yearList}*/}
      {/*  labList={[]}*/}
      {/*  setSeletedYear={setSeletedYear}*/}
      {/*  setSeletedLabName={setSeletedLabName}*/}
      {/*  selectedLab={selectedLabName?.searchBy}*/}
      {/*/>*/}
      {isLoading ? (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
          }}
        >
          <CircularProgress color={'primary'} size={40} />
        </Box>
      ) : (
        <SalesStaticticsTable invoiceStatistics={invoiceStatistics} />
      )}
    </Box>
  );
};

export default SalesStaticticsPage;
