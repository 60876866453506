import axios, { AxiosResponse } from 'axios';
import { SuccessResponseDTO } from '../dto/response/success.dto';
import { DashboardDto } from '../dto/response/dashboard.dto';
const DASHBOARD_ANALYTICS = 'admin/analytics';
const BASE_URL: string | undefined = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : '';
interface Querydate {
  from: string;
  to: string;
}
export const getUserDashboardAnalytics = (
  date: Querydate,
): Promise<AxiosResponse<SuccessResponseDTO<DashboardDto>>> => {
  return axios.get(`${BASE_URL}${DASHBOARD_ANALYTICS}?from=${date.from}&to=${date.to}`);
};
