import { Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
  classes?: string;
  sx?: any;
}

export function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, classes, sx, ...other } = props;

  return (
    <Box
      className={`tab-content ${classes}`}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      sx={sx}
    >
      {value === index && <div>{children}</div>}
    </Box>
  );
}
