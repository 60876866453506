import { Route } from 'react-router';
import { createBrowserRouter, createRoutesFromElements, Navigate, RouterProvider } from 'react-router-dom';

import { APP_ROUTES } from './constants/AppRoutes';
import Dashboard from './pages/Dashboard/DashboardPage';
import NotFound from './pages/NotFound/NotFound';
import OrderDetailPage from './pages/Order/OrderDetail/OrderDetailPage';
import OrderPage from './pages/Order/OrderList/OrderPage';
import OrganizationDetailPage from './pages/Organizations/OrganizationDetail/OrganizationDetailPage';
import OrganizationsPage from './pages/Organizations/OrganizationsPage';
import AdditionalOptions from './pages/Products/AdditionalOptions/AdditionalOptions';
import ProductsListPage from './pages/Products/ProductsList/ProductsListPage';
import RemakePage from './pages/Products/Remake/RemakePage';
import RatingPage from './pages/Rating/RatingPage';
import SalesHistoryPage from './pages/Sales/SalesHistory/SalesHistoryPage';
import SalesStaticticsPage from './pages/Sales/SalesStatistics/SalesStatisticsPage';
import PrivateRoute from './PrivateRoute';
import RouteLayout from './RouteLayout';

const ClinicAppRoutes = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RouteLayout />}>
        <Route index element={<Navigate to={`${APP_ROUTES.DASHBOARD.ROUTE}`} />} />
        <Route
          path={APP_ROUTES.DASHBOARD.ROUTE}
          element={
            // <PrivateRoute>
            <Dashboard />
          }
          // </PrivateRoute>
        />
        <Route
          path={APP_ROUTES.PAYMENT_HISTORY.ROUTE}
          element={
            <PrivateRoute>
              <SalesHistoryPage />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.SALES_STATISTICS.ROUTE}
          element={
            <PrivateRoute>
              <SalesStaticticsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.ORDER_LIST.ROUTE}
          element={
            <PrivateRoute>
              <OrderPage />
            </PrivateRoute>
          }
        />
        <Route
          path={`${APP_ROUTES.ORDER_DETAIL.ROUTE}/:id`}
          element={
            <PrivateRoute>
              <OrderDetailPage />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.ORGANIZATION_LIST.ROUTE}
          element={
            <PrivateRoute>
              <OrganizationsPage />
            </PrivateRoute>
          }
        />
        <Route
          path={`${APP_ROUTES.ORGANIZATION_DETAIL.ROUTE}/:id`}
          element={
            <PrivateRoute>
              <OrganizationDetailPage />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.RATING.ROUTE}
          element={
            <PrivateRoute>
              <RatingPage />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.PRODUCTS_LIST.ROUTE}
          element={
            <PrivateRoute>
              <ProductsListPage />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.ADDITIONAL_OPTIONS.ROUTE}
          element={
            <PrivateRoute>
              <AdditionalOptions />
            </PrivateRoute>
          }
        />
        <Route
          path={APP_ROUTES.PRODUCTS_REMAKE.ROUTE}
          element={
            <PrivateRoute>
              <RemakePage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};

export default ClinicAppRoutes;
