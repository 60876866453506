// import { Alert, Button, IconButton, Ricon } from '@imago-cloud/design-system';
import { Alert, IconButton, Snackbar, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useTopAlert from '../../recoil/topAlert/useTopAlert';
import { ALERT_COLORS } from '../../theme/overrides/Alert';
import Iconify from '../iconify/Iconify';

export default function TopAlert() {
  const theme = useTheme();

  const { topAlert, DEFAULT_TOP_ALERT_DURATION, initTopAlert } = useTopAlert();
  const { open, title, description, severity, actionButtonText, onActionButtonClick, variant, onClose } = topAlert;

  const handleTopAlertClose = (e: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    initTopAlert();
  };

  const IconButtonStyle = () => {
    const isLight = theme.palette.mode === 'light';

    const standardVariant = variant === 'standard';
    const filledVariant = variant === 'filled';
    const outlinedVariant = variant === 'outlined';

    const colorStyle = ALERT_COLORS.map((color) => ({
      ...(severity === color && {
        // STANDARD
        ...(standardVariant && {
          color: theme.palette[color][isLight ? 'darker' : 'lighter'],
        }),
        // FILLED
        ...(filledVariant && {
          color: theme.palette[color].contrastText,
        }),
        // OUTLINED
        ...(outlinedVariant && {
          color: theme.palette[color][isLight ? 'dark' : 'light'],
        }),
      }),
    }));

    return [...colorStyle];
  };

  return (
    <Snackbar
      key={description}
      open={open}
      autoHideDuration={DEFAULT_TOP_ALERT_DURATION}
      onClose={handleTopAlertClose}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'top',
      }}
      disableWindowBlurListener
    >
      <Alert
        sx={{ width: '960px' }}
        severity={severity ?? 'info'}
        variant={variant ?? 'standard'}
        title={title}
        action={
          actionButtonText || onClose ? (
            <Stack direction="row" alignItems="center" gap="12px">
              {/*{actionButtonText && (*/}
              {/*  <Button color="white" variant="text" size="36" onClick={onActionButtonClick}>*/}
              {/*    {actionButtonText}*/}
              {/*  </Button>*/}
              {/*)}*/}
              {onClose && (
                <IconButton
                  size="small"
                  color="inherit"
                  onClick={onClose}
                  sx={{
                    ...IconButtonStyle(),
                  }}
                >
                  <Iconify icon={'mingcute:close-line'} />
                </IconButton>
              )}
            </Stack>
          ) : undefined
        }
      >
        {description}
      </Alert>
    </Snackbar>
  );
}
