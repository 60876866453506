import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Tab, Tabs } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { OrderDetailResponse } from '../../../../client';
import { CustomTabPanel } from '../../../../components/tabs/CustomTabPanel';
import { OrderDesignTabRx } from './TabRx';
import { OrderDesignTabTimeline } from './TabTimeline';

interface Iprops {
  orderDetailInfo: OrderDetailResponse;
}

const PATIENT_TABS = [
  { label: 'Rx', tab: 'Rx' },
  { label: 'Timeline', tab: 'Timeline' },
];

export const OrderDetailTabs = ({ orderDetailInfo }: Iprops) => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get('tab') ?? 'Rx';

  const [selectedTab, setSelectedTab] = React.useState(currentTab);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue === 'Timeline') {
      searchParams.set('tab', 'Timeline');
      navigate(`?${searchParams.toString()}`, { replace: true });
    } else if (newValue === 'Rx') {
      searchParams.set('tab', 'Rx');
      navigate(`?${searchParams.toString()}`, { replace: true });
    }
    setSelectedTab(newValue);
  };

  return (
    <Card>
      <Tabs
        value={selectedTab}
        onChange={handleChangeTab}
        sx={{
          padding: '16px 24px',
          margin: '0 !important',
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        }}
      >
        {PATIENT_TABS.map((item) => {
          return <Tab key={item.label} label={item.label} value={item.tab} {...a11yProps(item.label)} />;
        })}
      </Tabs>
      <CustomTabPanel sx={{ pt: 0, width: '100%' }} value={selectedTab} index={selectedTab}>
        {selectedTab === 'Rx' && <OrderDesignTabRx orderDetailInfo={orderDetailInfo} />}
        {selectedTab === 'Timeline' && (
          <OrderDesignTabTimeline timeline={orderDetailInfo.timeline} status={orderDetailInfo.status} />
        )}
      </CustomTabPanel>
    </Card>
  );
};

function a11yProps(index: string) {
  return {
    id: `order-form-tab-${index}`,
    'aria-controls': `order-form-tabpanel-${index}`,
  };
}
