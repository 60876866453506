import axios from 'axios';

import { ResponseDtoUserGetResponse } from '../../client';

export const TOKEN_REFRESH_URL = 'account/auth/token/refresh';
const LOGOUT_URL = 'account/logout';

export const adminLoginApi = async () => {
  const { data } = await axios.patch(TOKEN_REFRESH_URL);
  const newAccessToken = data.data.accessToken;
  return newAccessToken;
};

export const postLogout = async () => {
  return await axios.post(LOGOUT_URL).then((res) => res.data);
};

export const getUserData = async (): Promise<ResponseDtoUserGetResponse> => {
  return await axios.get('connect/back-office/users/me').then((res) => res.data);
};
