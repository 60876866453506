import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';

type RHFTextFieldPropType = TextFieldProps & {
  label: string;
  name: string;
  labelVisible?: boolean;
  rules?: any;
  isNumberField?: boolean;
};

export default function RHFTextField({
  label,
  name,
  labelVisible = false,
  size = 'medium',
  rules,
  isNumberField = false,
  ...props
}: RHFTextFieldPropType) {
  const { control, trigger, watch, setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, onBlur, name, value }, fieldState: { error } }) => (
        <TextField
          name={name}
          size={size}
          fullWidth
          value={watch(name) !== undefined && isNumberField ? Number(watch(name)).toLocaleString() : watch(name) ?? ''}
          onChange={(e) => {
            let newValue = e.target.value.replace(/,/g, '');
            onChange(newValue);
            setValue(name, newValue);
          }}
          onBlur={() => {
            trigger(name);
          }}
          InputLabelProps={{
            shrink: labelVisible,
          }}
          placeholder={label}
          error={!!error}
          helperText={error?.message}
          {...props}
        />
      )}
    />
  );
}
