import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ICloseButtonOnlyDialogueProps } from '../../../../constants/helperHooks/useDialogue';
import { useOrderRejectionByQCQuery } from '../../../../recoil/order/useOrder';
import useTopAlert from '../../../../recoil/topAlert/useTopAlert';

type RejectOrderDialogueType = ICloseButtonOnlyDialogueProps & {
  orderId: string;
};

export const RejectOrderDialogue = ({ open, onClose, orderId }: RejectOrderDialogueType) => {
  const queryClient = useQueryClient();
  const { setTopAlert, initTopAlert } = useTopAlert();

  const { mutateAsync } = useOrderRejectionByQCQuery();

  const handleRejectButtonClick = async () => {
    try {
      await mutateAsync(
        { orderId },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['orderDetail', orderId],
            });
            onClose();
            setTopAlert({
              open: true,
              variant: 'filled',
              severity: 'error',
              description: `Order(${orderId}) has been rejected`,
              onClose: initTopAlert,
            });
          },
        },
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiPaper-root': {
          minWidth: '539px',
        },
      }}
    >
      <DialogTitle variant="h6" sx={{ padding: '24px 12px 24px 24px' }}>
        Reject order?
      </DialogTitle>
      <DialogContent sx={{ padding: '12px 24px !important', color: 'text.secondary' }}>
        This order will be rejected and update the status of the order.
      </DialogContent>
      <DialogActions sx={{ padding: '24px', border: 'none !important' }}>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" color="error" onClick={handleRejectButtonClick}>
          Reject
        </Button>
      </DialogActions>
    </Dialog>
  );
};
