import { useMutation } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { AxiosResponse } from 'axios';
import * as dashboardApi from './dashboard.api';
import { userDashboardAnalyticsState } from './dashboardState';
import { UserDashboardStateDTO } from '../dto/states/dashboardState';
import { SuccessResponseDTO } from '../dto/response/success.dto';
import { DashboardDto } from '../dto/response/dashboard.dto';

export function useDashboardActions() {
  const [dashboardAnalytics, setDashboardAnalytics] = useRecoilState(userDashboardAnalyticsState);

  const { errorMessage, isLoading, isError } = dashboardAnalytics;

  const getUserDashboardAnalytics = useMutation({
    mutationFn: dashboardApi.getUserDashboardAnalytics,
    onMutate: () => {
      setDashboardAnalytics((prevData: UserDashboardStateDTO) => ({
        ...prevData,
        isLoading: true,
        isError: false,
      }));
      //   console.log("mutating the API");
    },
    onError: (error) => {
      //   console.log("error the API : ", error);
      setDashboardAnalytics((prevData: UserDashboardStateDTO) => ({
        ...prevData,
        isLoading: false,
        isError: true,
      }));
    },
    onSuccess: (response: AxiosResponse<SuccessResponseDTO<DashboardDto>>) => {
      setDashboardAnalytics((prevData: UserDashboardStateDTO) => ({
        ...prevData,
        userDashboardAnalytics: response.data.data,
        isLoading: false,
        isError: true,
      }));
    },
  });

  return {
    isLoading,
    isError,
    errorMessage,
    getUserDashboardAnalytics: getUserDashboardAnalytics.mutateAsync,
  };
}
