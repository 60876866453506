import SendbirdProvider from '@sendbird/uikit-react/SendbirdProvider';
import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';

import { UseCreateChannelProps } from '../../../../hooks/chat/sendbirdSDK';
import { getSendbirdSessionToken } from '../../../../recoil/chat/chat.api';
import GroupChannelRetry from './GroupChannelRetry';

interface ChatBoxProps {
  chattingChannelUrl: string | undefined;
  children: React.ReactNode;
  retryChannelInfo: UseCreateChannelProps;
}

function BoCustomSendbirdProvider({ chattingChannelUrl, children, retryChannelInfo }: ChatBoxProps) {
  const [sessionToken, setSessionToken] = React.useState<string>('');
  useEffect(() => {
    getSendbirdSessionToken('QC')
      .then((res) => {
        setSessionToken(res.data?.data?.token as string);
      })
      .catch((error) => {
        setSessionToken('-1');
      });

    return () => {
      setSessionToken((prev) => '');
    };
  }, [chattingChannelUrl, getSendbirdSessionToken]);

  if (sessionToken === '') {
    return <CircularProgress color={'inherit'} size={20} sx={{ ml: 1 }} />;
  }

  return (
    <SendbirdProvider
      appId={process.env.REACT_APP_SENDBIRD_APP_ID as string}
      userId={'QC'}
      accessToken={sessionToken}
      nickname={'Admin'}
    >
      <GroupChannelRetry retryChannelInfo={retryChannelInfo} chattingChannelUrl={chattingChannelUrl}>
        {children}
      </GroupChannelRetry>
    </SendbirdProvider>
  );
}

export default BoCustomSendbirdProvider;
