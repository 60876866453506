// @mui
import { CssBaseline } from '@mui/material';
import {
  createTheme,
  StyledEngineProvider,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from '@mui/material/styles';

import customShadows from './customShadows';
import GlobalStyles from './globalStyles';
import componentsOverride from './overrides';
// components
//
import palette from './palette';
import shadows from './shadows';
import typography from './typography';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  // const { themeMode, themeDirection } = useSettingsContext();

  const themeOptions: ThemeOptions = {
    palette: palette('light'),
    typography,
    shape: { borderRadius: 8 },
    direction: 'ltr',
    shadows: shadows('light'),
    customShadows: customShadows('light'),
    bshadows: {},
  };

  const theme = createTheme(themeOptions);

  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
