import { Theme, useTheme } from '@mui/material/styles';

import { BackOfficeOrderResponse } from '../../../client';
import Label, { LabelColor, LabelVariant } from '../../../components/label';
import { STATUS_LABELS } from './statusLabel';

export type OrderStatusType = BackOfficeOrderResponse['status'] | 'REMAKE';

type StatusStyle = {
  variant?: LabelVariant;
  color?: LabelColor;
  sx: { [key: string]: string };
};

const getStatusStyle = (theme: Theme): Partial<Record<OrderStatusType, StatusStyle>> => ({
  ORDERED: { variant: 'filled', color: 'success', sx: { width: 'fit-content' } },
  REJECTED: { variant: 'filled', color: 'error', sx: { width: 'fit-content' } },
  LAB_REJECTED: { variant: 'filled', color: 'error', sx: { width: 'fit-content' } },
  QC_REJECTED: { variant: 'filled', color: 'error', sx: { width: 'fit-content' } },
  DESIGNING: { variant: 'soft', color: 'success', sx: { width: 'fit-content' } },
  WAITING_DESIGN_CONFIRMATION: {
    sx: {
      width: 'fit-content',
      backgroundColor: `${theme.palette.info.dark} !important`,
      color: `${theme.palette.secondary.contrastText} !important`,
    },
  },
  MANUFACTURING: {
    variant: 'filled',
    color: 'info',
    sx: { width: 'fit-content' },
  },
  SHIPPED: {
    variant: 'filled',
    color: 'default',
    sx: { width: 'fit-content' },
  },
  COMPLETED: {
    sx: {
      width: 'fit-content',
      backgroundColor: `${theme.palette.grey['500']} !important`,
      color: `${theme.palette.secondary.contrastText} !important`,
    },
  },
  CANCELED: {
    variant: 'soft',
    color: 'default',
    sx: { width: 'fit-content' },
  },
  REMAKE: {
    variant: 'outlined',
    color: 'default',
    sx: {
      width: 'fit-content',
    },
  },
});

export const OrderStatusLabel = ({ orderStatus }: { orderStatus: OrderStatusType }) => {
  const theme = useTheme();
  const StatusStyle = getStatusStyle(theme) || {
    variant: 'filled',
    color: 'primary',
    sx: { width: 'fit-content' },
  };

  const OrderStatusStyle = StatusStyle[orderStatus];
  const label = orderStatus === 'REMAKE' ? 'Remake' : STATUS_LABELS[orderStatus];

  return (
    <Label variant={OrderStatusStyle?.variant} color={OrderStatusStyle?.color} sx={OrderStatusStyle?.sx}>
      {label}
    </Label>
  );
};
