import * as React from 'react';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';

interface IPropsCustomPopover {
  buttonText?: any;
  buttonStartIcon?: any;
  buttonEndIcon?: any;
  buttonStyle?: any;
  popoverStyle?: any;
  position?: any;
  width?: any;
  icon?: any;
  forceClose?: boolean;
  setForceClose?: any;
  children: any;
}

export default function CustomPopover({
  forceClose,
  setForceClose,
  icon,
  buttonText,
  buttonStartIcon = null,
  buttonEndIcon = null,
  position = 'right',
  buttonStyle,
  popoverStyle,
  width,
  children,
}: IPropsCustomPopover) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  React.useEffect(() => {
    if (forceClose === true) {
      handleClose();
      setForceClose(false);
    }
  }, [forceClose]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'custom-popover' : undefined;

  return (
    <>
      {buttonText ? (
        <Button
          aria-describedby={id}
          variant="outlined"
          onClick={handleClick}
          startIcon={buttonStartIcon}
          endIcon={buttonEndIcon}
          sx={buttonStyle}
        >
          {buttonText}
        </Button>
      ) : icon ? (
        <IconButton onClick={handleClick}>{icon}</IconButton>
      ) : null}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          handleClose();
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: position,
        }}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: width,
            minWidth: width,
            width: width,
            borderRadius: '0.5rem',
            boxShadow: '0px 4px 12px 0px rgba(145, 153, 163, 0.24), 0px 0px 1px 0px rgba(145, 153, 163, 0.64)',
          },
          '& button': {
            borderRadius: '0 !important',
            justifyContent: 'flex-start',
            minWidth: 200,
            padding: '.25rem 2rem !important',
            height: '3rem  !important',
            color: '#333',
            fontWeight: 400,
            fontSize: '1.125rem',
            background: 'white !important',
            borderColor: 'transparent',
            '&:first-of-type(button)': {
              borderTopLeftRadius: '.5rem',
              borderTopRightRadius: '.5rem',
            },
            '&:last-child': {
              borderBottomLeftRadius: '.5rem',
              borderBottomRightRadius: '.5rem',
            },
            '& svg, & img': {
              mr: 1,
              width: 25,
              minWidth: 25,
              height: 25,
              color: '#9199A3',
            },
          },
        }}
      >
        {children}
      </Popover>
    </>
  );
}
