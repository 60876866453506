import React, { Suspense } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import AppRoutes from '../../../AppRoutes';
import ThemeProvider from '../../../theme';
import { LoadingScreen } from '../../loading/LoadingScreen';

export const AppLayout = () => {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Suspense fallback={<LoadingScreen />}>
          <AppRoutes />
        </Suspense>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
