import React from 'react';
import { useFormik } from 'formik';

import { designManufactureCombination, OptionValueRequest, ProductDetail } from '../../../client';
import CustomAlertDialog from '../../../components/dialog/CustomAlertDialog';
import CustomDialog from '../../../components/dialog/CustomDialog';
import { useDialogue } from '../../../constants/helperHooks/useDialogue';
import { useProductItemEditMutation, useProductItemMutation } from '../../../recoil/products/useProduct';
import { NewProductDialogContent } from './NewProductDialogContent';
import ProductOptionEditDialog from './ProductOptionEditDialog';

const DEFAULT_OPTION_VALUE: OptionValueRequest = {
  optionId: '',
  value: '',
};

export const NEW_PRODUCT_INIT_STATE = {
  price: 0,
  priceUnit: 'JPY',
  prosthesis: DEFAULT_OPTION_VALUE,
  method: DEFAULT_OPTION_VALUE,
  material: DEFAULT_OPTION_VALUE,
  shape: DEFAULT_OPTION_VALUE,
  designManufactureCombination: 'DESIGN_AND_MANUFACTURE' as designManufactureCombination,
  requireScanBodyAndImplantInfo: false,
};

const transformToFormikForm = (productForEdit: ProductDetail) => {
  const updated = NEW_PRODUCT_INIT_STATE;
  updated.price = productForEdit.price;
  updated.requireScanBodyAndImplantInfo = productForEdit.requireScanBodyAndImplantInfo;
  return updated;
};

interface NewProductDialogProps {
  open: boolean;
  onCloseDialog: () => void;
  productForEdit?: ProductDetail;
}
export default function NewProductDialog({ open, onCloseDialog, productForEdit }: NewProductDialogProps) {
  const productOptionEditDialog = useDialogue();
  const duplicateItemDialog = useDialogue();
  const { mutateAsync } = useProductItemMutation();
  const { mutateAsync: productItemEditAsync } = useProductItemEditMutation();

  const isEditMode = !!productForEdit;

  const formik = useFormik({
    initialValues: isEditMode ? transformToFormikForm(productForEdit) : NEW_PRODUCT_INIT_STATE,
    onSubmit: async (values) => {
      if (isEditMode) {
        // PATCH
        const { requireScanBodyAndImplantInfo, price } = values;
        const request = {
          id: productForEdit.id,
          body: { price, requireScanBodyAndImplantInfo },
        };
        await productItemEditAsync(request);
      } else {
        // POST
        try {
          await mutateAsync(values);
        } catch (error: any) {
          const { errorCode } = error?.response?.data;
          if (errorCode === 'CONNECT:PRODUCT_ALREADY_EXISTS') {
            return duplicateItemDialog.handleOpenDialogue();
          }
        }
      }
      formik.resetForm();
      onCloseDialog();
    },
  });
  const { values, handleSubmit } = formik;
  const btnDisabled = isEditMode
    ? values?.price === 0
    : values.prosthesis.value === '' || values.material.value === '' || values.price === 0;

  return (
    <>
      <form onSubmit={handleSubmit} id="product-creation-form">
        <CustomDialog
          width={'455px'}
          title={isEditMode ? 'Edit product' : 'Add new product'}
          open={open}
          handleClose={() => {
            onCloseDialog();
            formik.resetForm();
          }}
          // dialogActions={false}
          btnActionText={'Confirm'}
          btnDisabled={btnDisabled}
          btnType="submit"
          formId={'product-creation-form'}
          // dialogActionsStyle={{ marginLeft: 7, maxWidth: '66%', display: 'flex', justifyContent: 'space-between' }}
        >
          <NewProductDialogContent
            formik={formik}
            productForEdit={productForEdit}
            onProductOptionEditDialogClick={productOptionEditDialog.handleOpenDialogue}
          />
        </CustomDialog>
      </form>

      <CustomAlertDialog
        title={`This product already exists`}
        desc={`Please verify this product before proceeding.`}
        btnActionText={'Confirm'}
        alertType={'success'}
        btnAction={duplicateItemDialog.handleCloseDialogue}
        isHideCancelBtn={true}
        open={duplicateItemDialog.open}
        handleClose={duplicateItemDialog.handleCloseDialogue}
      />

      <ProductOptionEditDialog
        isOpen={productOptionEditDialog.open}
        handleClose={productOptionEditDialog.handleCloseDialogue}
      />
    </>
  );
}
