import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { ICloseButtonOnlyDialogueProps } from '../../../constants/helperHooks/useDialogue';

type UpdateProductLstConfirmDialoguePropsType = ICloseButtonOnlyDialogueProps & {
  handleProductListUpdate: () => void;
  isPending: boolean;
};

export const UpdateProductLstConfirmDialogue = ({
  open,
  onClose,
  handleProductListUpdate,
  isPending,
}: UpdateProductLstConfirmDialoguePropsType) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '539px !important',
          minWidth: '539px',
        },
      }}
    >
      <DialogTitle>Update product list?</DialogTitle>
      <DialogContent>
        <Stack sx={{ py: '12px' }}>
          <Typography variant="body1" color="text.secondary">
            Changes will update remake settings.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ border: 'none !important' }}>
        <Button variant="outlined" color="inherit" size="medium" onClick={onClose} disabled={isPending}>
          Cancel
        </Button>
        <LoadingButton
          loading={isPending}
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => {
            handleProductListUpdate();
          }}
        >
          Confirm
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
