import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Box, Card, Typography, Button } from '@mui/material';

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from 'react';
import moment from 'moment';
// chart options

export const CardStatistics = ({ setSearchAnalyticsByMonth, chartStatisticsOptions }: any) => {
  const [selectedMonth, setSelectedMonth] = useState<number>(moment().month());
  useEffect(() => {
    setSearchAnalyticsByMonth(moment().month(selectedMonth).format('MMMM DD YYYY'));
  }, [selectedMonth]);

  return (
    <Card>
      <Box display="flex" justifyContent="space-between">
        <Typography
          className="card-title"
          component="caption"
          sx={{ display: 'flex !important', alignItems: 'center' }}
        >
          Statistics
        </Typography>
        <Box display="flex" alignItems="center">
          <Typography color="#6A6E74" fontWeight={600}>
            {moment().month(selectedMonth).format('YYYY.MMMM')}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Button
              variant="text"
              sx={{
                p: 1,
                ml: '12px',
                minWidth: 'inherit',
                ':hover': { backgroundColor: '#ebebeb' },
                pointerEvents: Math.abs(moment().month() - selectedMonth) >= 12 ? 'none' : '',
              }}
              onClick={(e) => {
                setSelectedMonth((pre) => pre - 1);
              }}
            >
              <ArrowBackIosNewIcon
                sx={{ fontSize: 12, color: Math.abs(moment().month() - selectedMonth) >= 12 ? '#B4BBC2' : '#6A6E74' }}
              />
            </Button>
            <Button
              variant="text"
              sx={{
                p: 1,
                ml: '8px',
                pointerEvents:
                  moment().month(selectedMonth).format('YYYY MM DD') === moment().format('YYYY MM DD') ? 'none' : '',
                minWidth: 'inherit',
                ':hover': { backgroundColor: '#ebebeb' },
              }}
              onClick={() => setSelectedMonth((pre) => (pre === 11 ? 11 : pre + 1))}
            >
              <ArrowForwardIosIcon
                sx={{
                  fontSize: 12,
                  color:
                    moment().month(selectedMonth).format('YYYY MM DD') === moment().format('YYYY MM DD')
                      ? '#B4BBC2'
                      : '#6A6E74',
                }}
              />
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ mt: 5 }}>
        <HighchartsReact highcharts={Highcharts} options={chartStatisticsOptions} />
      </Box>
    </Card>
  );
};
