// routes
// components

// ----------------------------------------------------------------------

import { APP_ROUTES } from '../../../../constants/AppRoutes';
import SvgColor from './SvgColor';

const icon = (name: string) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  dashboard: icon('ic_analytics'),
  sales: icon('ic_invoice'),
  orders: icon('ic_cart'),
  organization: icon('ic_userGroup'),
  rating: icon('ic_label'),
  products: icon('ic_billList'),
  management: icon('ic_user'),
};

const navConfig = [
  {
    title: 'Dashboard',
    path: APP_ROUTES.DASHBOARD.ROUTE,
    icon: ICONS.dashboard,
  },
  {
    title: 'Sales',
    path: APP_ROUTES.SALES.ROUTE,
    icon: ICONS.sales,
    children: [
      { title: 'Sales statistics', path: APP_ROUTES.SALES_STATISTICS.ROUTE },
      { title: 'Payment history', path: APP_ROUTES.PAYMENT_HISTORY.ROUTE },
      {
        title: 'Refund',
        path: APP_ROUTES.REFUND.ROUTE,
      },
    ],
  },
  {
    title: 'Orders',
    path: APP_ROUTES.ORDER_LIST.ROUTE,
    icon: ICONS.orders,
  },
  {
    title: 'Organization',
    path: APP_ROUTES.ORGANIZATION_LIST.ROUTE,
    icon: ICONS.organization,
  },
  {
    title: 'Rating',
    path: APP_ROUTES.RATING.ROUTE,
    icon: ICONS.dashboard,
  },
  {
    title: 'Products',
    path: APP_ROUTES.PRODUCTS.ROUTE,
    icon: ICONS.products,
    children: [
      { title: 'Product list', path: APP_ROUTES.PRODUCTS_LIST.ROUTE },
      { title: 'Additional option', path: APP_ROUTES.ADDITIONAL_OPTIONS.ROUTE },
      { title: 'Remake', path: APP_ROUTES.PRODUCTS_REMAKE.ROUTE },
    ],
  },
  {
    title: 'Management',
    path: APP_ROUTES.MANAGEMENT.ROUTE,
    icon: ICONS.management,
    children: [
      { title: 'Admin', path: APP_ROUTES.ADMIN.ROUTE },
      { title: 'Menu access', path: APP_ROUTES.MENU_ACCESS.ROUTE },
    ],
  },
];

export default navConfig;
