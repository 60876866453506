import { CircularProgress, Stack } from '@mui/material';

export const LoadingScreen = () => {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: '100%',
        height: '100%',
        zIndex: 9000,
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <CircularProgress color="primary" />
    </Stack>
  );
};
