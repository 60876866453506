import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Card, Stack, TableCell, TableRow } from '@mui/material';
import Switch from '@mui/material/Switch';

import { SupplyProductDetail } from '../../../client';
import CustomDataTable from '../../../components/table/DataTable';
import useTable from '../../../components/table/useTable';
import { useOrganizationActions } from '../../../recoil/organizations/useOrganizations';
import useTopAlert from '../../../recoil/topAlert/useTopAlert';

const ORGANIZATION_SUPPLY_LIST_COLUMN = [
  { id: 'toggle', label: '', minWidth: 73, alignCol: 'center' },
  { id: 'type', label: 'Type', minWidth: 40, alignCol: 'left' },
  { id: 'method', label: 'Method', minWidth: 200, alignCol: 'left' },
  { id: 'material', label: 'Material', minWidth: 240, alignCol: 'left' },
  { id: 'shape', label: 'Shape', minWidth: 200, alignCol: 'left' },
  { id: 'range', label: 'Range', minWidth: 240, alignCol: 'left' },
  { id: 'uniquePrice', label: 'Unique price', minWidth: 154, alignCol: 'left' },
];

export default function SupplyList() {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { dense, page, rowsPerPage, onChangeDense, onChangePage, onChangeRowsPerPage, setPage } = useTable({
    defaultRowsPerPage: 10,
    defaultCurrentPage: 0,
  });

  const { useOrganizationSupplyProductList, useOrganizationSupplyProductMutation } = useOrganizationActions();
  const { data } = useOrganizationSupplyProductList({
    organizationId: id as string,
    page: page + 1,
    size: rowsPerPage,
  });
  const totalElement = data?.data?.totalElements ?? 0;
  const labSupplyList = data.data?.content ?? [];

  const { mutateAsync, isPending } = useOrganizationSupplyProductMutation();

  const [checkedItemIdSet, setCheckedItemIdSet] = React.useState(new Set());
  const [changedNewSupplyProductList, setChangedNewSupplyProductList] = useState<Array<string>>([]);
  const [changedExcludedSupplyProductList, setChangedExcludedSupplyProductList] = useState<Array<string>>([]);

  const { setTopAlert, initTopAlert } = useTopAlert();

  const resetChangedData = () => {
    setChangedNewSupplyProductList([]);
    setChangedExcludedSupplyProductList([]);
  };

  const handleUpdateClick = async () => {
    if (changedNewSupplyProductList.length === 0 && changedExcludedSupplyProductList.length === 0) return;
    mutateAsync(
      {
        organizationId: id as string,
        requestBody: {
          newSuppliedProductIds: changedNewSupplyProductList,
          newExcludedSuppliedProductIds: changedExcludedSupplyProductList,
        },
      },
      {
        onSuccess: () => {
          setTopAlert({
            open: true,
            variant: 'filled',
            severity: 'success',
            description: 'Updated successfully',
            onClose: initTopAlert,
          });
          queryClient.invalidateQueries({
            queryKey: ['organization-supplyList'],
          });
          resetChangedData();
        },
      },
    );
  };

  const handleSwitchChange = (e: ChangeEvent<HTMLInputElement>, product: SupplyProductDetail) => {
    const { checked } = e.target;
    if (checked) {
      if (product.isSupplyTarget === false) {
        setChangedNewSupplyProductList((prev) => [...prev, product.id]);
      } else if (product.isSupplyTarget === true) {
        setChangedExcludedSupplyProductList((prev) => prev.filter((productId) => productId !== product.id));
      }
      // Set
      const updated = new Set(checkedItemIdSet);
      updated.add(product.id);
      setCheckedItemIdSet(updated);
    } else {
      if (product.isSupplyTarget === false) {
        setChangedNewSupplyProductList((prev) => prev.filter((productId) => productId !== product.id));
      } else if (product.isSupplyTarget === true) {
        setChangedExcludedSupplyProductList((prev) => [...prev, product.id]);
      }
      // Set
      const updated = new Set(checkedItemIdSet);
      updated.delete(product.id);
      setCheckedItemIdSet(updated);
    }
  };

  useEffect(() => {
    if (labSupplyList.length === 0) return;
    const initialCheckedItems = labSupplyList.filter((product) => product.isSupplyTarget).map((product) => product.id);
    const updatedCheckedItems = initialCheckedItems.filter((id) => !changedExcludedSupplyProductList.includes(id));
    setCheckedItemIdSet(new Set([...updatedCheckedItems, ...changedNewSupplyProductList]));
  }, [labSupplyList]);

  return (
    <Stack gap="50px">
      <Card>
        <CustomDataTable
          tableColumn={ORGANIZATION_SUPPLY_LIST_COLUMN}
          rowsPerPage={rowsPerPage}
          page={page}
          totalElement={totalElement}
          dense={dense}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          onChangeDense={onChangeDense}
          sx={{ width: '100%' }}
        >
          {labSupplyList.map((product) => (
            <TableRow key={product.id}>
              <TableCell align="center">
                <Switch
                  checked={checkedItemIdSet.has(product.id)}
                  onChange={(e) => {
                    handleSwitchChange(e, product);
                  }}
                />
              </TableCell>
              <TableCell align="left">{product.prosthesis}</TableCell>
              <TableCell align="left">{product.method}</TableCell>
              <TableCell align="left">{product.material}</TableCell>
              <TableCell align="left">{product.shape}</TableCell>
              <TableCell align="left">{product.designManufactureCombination}</TableCell>
              <TableCell align="left">{`${product.priceUnit} ${product.price.toLocaleString()}`}</TableCell>
            </TableRow>
          ))}
        </CustomDataTable>
      </Card>
      <Stack alignItems={'flex-end'}>
        <LoadingButton
          loading={isPending}
          variant="contained"
          size="large"
          onClick={handleUpdateClick}
          disabled={!changedNewSupplyProductList.length && !changedExcludedSupplyProductList.length}
        >
          Update
        </LoadingButton>
      </Stack>
    </Stack>
  );
}
