import * as React from 'react';
import { IconButton, Stack, TableCell, TableRow, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { alpha, useTheme } from '@mui/material/styles';

import { BackOfficeOrderRatingResponse } from '../../client';
import Iconify from '../../components/iconify/Iconify';
import { DataTableWithScroll } from '../../components/table/DataTableWithScroll';
import { ICloseButtonOnlyDialogueProps } from '../../constants/helperHooks/useDialogue';
import { capitalizeFirstLetter } from '../../utils/formatString';

type RatingDetailDialoguePropsType = ICloseButtonOnlyDialogueProps & {
  order: BackOfficeOrderRatingResponse;
};

const tableColumn = [
  {
    id: 'product',
    label: 'Product',
    alignCol: 'left',
    maxWidth: 280,
  },
  { id: 'rate', label: 'Rate', alignCol: 'center', maxWidth: 197 },
];

export const RatingDetailDialogue = ({ open, onClose, order }: RatingDetailDialoguePropsType) => {
  const theme = useTheme();
  const hasComment = order.rateInfo.comment !== '';

  const orderRatingList = Object.values(
    order.orderItems.reduce<
      Record<
        string,
        {
          prosthesis: string;
          toothNumbers: string[];
          rate: number;
        }
      >
    >((acc, item) => {
      if (item.groupId) {
        if (!acc[item.groupId]) {
          acc[item.groupId] = {
            prosthesis: item.prosthesis ?? '',
            toothNumbers: [],
            rate: 0,
          };
        }
        acc[item.groupId].toothNumbers.push(item.toothNumber);
        if (item.rate > 0) {
          acc[item.groupId].rate = item.rate;
        }
      } else {
        acc[item.orderItemId] = {
          prosthesis: item.prosthesis ?? '',
          toothNumbers: [item.toothNumber],
          rate: item.rate,
        };
      }
      return acc;
    }, {}),
  ).map((group) => {
    const { prosthesis, toothNumbers, rate } = group;
    const formattedToothNumbers = toothNumbers
      .map((num, index) => {
        if (index === 0 || index === toothNumbers.length - 1) {
          return num;
        } else {
          return `(${num})`;
        }
      })
      .join('-');

    return {
      displayText: `${capitalizeFirstLetter(prosthesis)} ${formattedToothNumbers}`,
      rate,
    };
  });

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '525px !important',
          minWidth: '525px',
        },
      }}
    >
      <DialogTitle sx={{ padding: '24px 12px 24px 24px' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Rating detail</Typography>
          <IconButton aria-label="close" onClick={onClose} color="default" size="medium">
            <Iconify icon="mingcute:close-line" />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Stack gap="8px" sx={{ maxHeight: '313px', padding: '0 24px 16px 24px' }}>
          <Typography variant="subtitle1">Order information</Typography>
          <Stack gap="4px">
            <OrderInfoRow label={'Patient name:'} value={order.patientName} />
            <OrderInfoRow label={'Organization name:'} value={order.orderer.organization.name} />
            <OrderInfoRow label={'User email:'} value={order.orderer.email} />
            <OrderInfoRow label={'Lab:'} value={order.lab?.organization.name ?? ''} />
            <Typography variant="subtitle1">Comment:</Typography>
            <TextField
              multiline
              rows={4}
              defaultValue={hasComment ? order.rateInfo.comment : 'None'}
              variant="filled"
              InputProps={{
                readOnly: true,
              }}
              sx={{
                borderRadius: '8px',
                backgroundColor: alpha(theme.palette.grey[500], 0.08),
                '& .MuiFilledInput-root': {
                  padding: '10px 12px',
                  fontSize: '14px',
                  lineHeight: '22px',
                  color: hasComment ? 'text.primary' : 'text.disabled',
                  '& textarea': {
                    '&::-webkit-scrollbar': {
                      width: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: alpha(theme.palette.grey[600], 0.48),
                      borderRadius: '12px',
                    },
                  },
                },
              }}
            />
          </Stack>
          <Divider sx={{ mt: '8px' }} />
        </Stack>
        <Stack gap="8px" sx={{ maxHeight: '288px', padding: '0 24px 16px 24px' }}>
          <Typography variant="subtitle1">Rating</Typography>
          <DataTableWithScroll
            tableColumn={tableColumn}
            sx={{ minHeight: '232px !important', height: '232px !important' }}
          >
            {orderRatingList.map((item, index) => {
              return (
                <TableRow key={index}>
                  <TableCell align="left" sx={{ maxWidth: 280 }}>
                    {item.displayText}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: 197 }}>
                    {item.rate}
                  </TableCell>
                </TableRow>
              );
            })}
          </DataTableWithScroll>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

const OrderInfoRow = ({ label, value }: { label: string; value: string }) => {
  return (
    <Stack direction="row" textAlign="center" gap="4px">
      <Typography variant="subtitle1" color="text.primary">
        {label}
      </Typography>
      <Typography variant="body1" color="text.secondary">
        {value}
      </Typography>
    </Stack>
  );
};
