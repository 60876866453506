export const getStatusStyle = (status: string) => {
  switch (status) {
    case 'PUBLISHED':
      return `
        background-color: #0083CA;
        color: #ffffff;
      `;
    case 'IN_PROGRESS':
      return `
        background-color: #449715;
        color: #ffffff;
      `;
    case 'CONFIRMED':
      return `
        background-color: #333333;
        color: #ffffff;
      `;

    default:
      return '';
  }
};
