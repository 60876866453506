import React, { ReactNode } from 'react';
import { FormControl, InputLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

export type SEARCH_TARGET_TYPE = {
  id: string;
  label: string;
};

type SearchTargetSelectProps = {
  value: string | null;
  onChange: (event: any, child?: ReactNode) => void;
  searchTargetName: string;
  searchTargetList: SEARCH_TARGET_TYPE[];
};

const SearchTargetSelect = ({ value, onChange, searchTargetName, searchTargetList }: SearchTargetSelectProps) => {
  return (
    <FormControl size="small" sx={{ width: '320px', height: '100%' }}>
      <InputLabel id={searchTargetName}>{searchTargetName}</InputLabel>
      <Select label={searchTargetName} value={value || searchTargetList[0].id} onChange={onChange}>
        {searchTargetList.map((option) => (
          <MenuItem key={option.id} value={option.id} sx={{ mx: 1, borderRadius: 1 }}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default SearchTargetSelect;
