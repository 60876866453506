import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Icon, Stack, Typography } from '@mui/material';

import { APP_ROUTES } from '../../constants/AppRoutes';

export default function NotFound() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const mainRouter = APP_ROUTES.DASHBOARD.ROUTE;

  useEffect(() => {
    if (pathname === mainRouter) {
      navigate(mainRouter);
    }
  }, [navigate, pathname]);

  return (
    <Stack sx={{ px: '16px', width: '100%', height: '90vh' }} justifyContent="center" alignItems="center">
      <Stack sx={{ maxWidth: '535px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          {/*<Icon icon="Warning" />*/}
          <Typography variant="h3" sx={{ marginLeft: '8px' }} color="error.main">
            404 Error
          </Typography>
        </Stack>

        <Typography variant="subtitle2" sx={{ my: '12px' }} textAlign="center">
          {/*<Translate id={'error_page_title_404_not_available'}>{`Sorry, this page isn't available.`}</Translate>*/}
          Sorry, this page isn't available.
        </Typography>
        <Typography variant="h4" textAlign="center">
          The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          href={mainRouter}
          size="medium"
          sx={{ mt: '44px', minWidth: '129px' }}
        >
          Go Home
        </Button>
      </Stack>
    </Stack>
  );
}
