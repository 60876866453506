import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import { ONE_YEAR } from '../../../constants/common';

export type DateValidType = 'NONE' | 'DURING_X_DAYS';

const DATE_ALERT_MESSAGE = {
  DURING_X_DAYS: (days: number) => `The duration can be set up to a maximum of ${getFormatDate(days)}.`,
};

export const getFormatDate = (days: number) => (days && days / 365 >= 1 ? `${days / 365} year` : `${days} days`);

//start~end date 사이 기간이 x일
export const checkWithinXDays = (
  startDate: Dayjs | null | undefined,
  endDate: Dayjs | null | undefined,
  days = ONE_YEAR,
) => {
  if (startDate?.isValid() && endDate?.isValid() && days) {
    return endDate.diff(startDate, 'day') < days;
  }
  return true;
};

export const useDatePicker = (dateValidType: DateValidType = 'NONE', days?: number) => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const handleStartDateChange = (date: Dayjs | null) => {
    const newStartDate: Dayjs | null = dayjs(date).startOf('day');
    const newEndDate: Dayjs | null = dayjs(endDate).startOf('day');

    if (newStartDate && !newStartDate?.isValid()) return;

    if (newEndDate?.isBefore(newStartDate, 'day')) {
      setEndDate(null);
      setStartDate(newStartDate);
      return;
    }

    if (
      newStartDate &&
      newEndDate &&
      dateValidType === 'DURING_X_DAYS' &&
      !checkWithinXDays(newStartDate, newEndDate, days)
    ) {
      days && alert(DATE_ALERT_MESSAGE.DURING_X_DAYS(days));
      setStartDate(() => null);
      return;
    }
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (date: Dayjs | null) => {
    const newStartDate: Dayjs | null = dayjs(startDate).startOf('day');
    const newEndDate: Dayjs | null = dayjs(date).startOf('day');

    if (newEndDate && !newEndDate?.isValid()) return;

    //startDate endDate 존재할 경우, 순서 검사
    if (newStartDate && newEndDate) {
      if (newEndDate?.isBefore(newStartDate, 'day')) {
        setEndDate(null);
        setStartDate(newEndDate);
        return;
      }
    }

    setEndDate(newEndDate);

    if (
      // 정해진 x일동안의 기간이 아니라면
      newStartDate &&
      newEndDate &&
      dateValidType === 'DURING_X_DAYS' &&
      !checkWithinXDays(newStartDate, newEndDate, days)
    ) {
      days && alert(DATE_ALERT_MESSAGE.DURING_X_DAYS(days));
      setEndDate(() => null);
      return;
    }
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return {
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    handleReset,
  };
};
