import { useEffect, useRef } from 'react';

type usePersistentPaginationProps = {
  listKey: string;
  flagKey: string;
  setPage: (page: number) => void;
};

export const usePersistentPagination = ({ listKey, flagKey, setPage }: usePersistentPaginationProps) => {
  const hasSetPage = useRef(false);

  useEffect(() => {
    if (!hasSetPage.current) {
      if (!window.localStorage.getItem(flagKey)) {
        setPage(0);
        window.localStorage.removeItem(listKey);
      } else {
        window.localStorage.removeItem(flagKey);
      }
      hasSetPage.current = true;
    }
  }, [listKey, flagKey, setPage]);
};

export const useSetPaginationFlag = (flagKey: string) => {
  useEffect(() => {
    window.localStorage.setItem(flagKey, 'true');
  }, [flagKey]);
};
