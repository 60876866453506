import * as React from 'react';
import { Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CustomPopover from '../../../components/popover/CustomPopover';
interface Iprop {
  id?: string;
  getInvoiceByIdHandler?: (id: string) => void;
}
export default function TableRowActions({ getInvoiceByIdHandler, id }: Iprop) {
  const [forceClose, setForceClose] = React.useState(false);

  return (
    <>
      <CustomPopover icon={<MoreVertIcon />} forceClose={forceClose} setForceClose={setForceClose}>
        <Button
          variant="text"
          onClick={() => {
            if (getInvoiceByIdHandler && id) {
              getInvoiceByIdHandler(id);
            }
          }}
        >
          Detail
        </Button>
        {/* <Button variant="text" startIcon={<EditIcon sx={{ mx: 0 }} />} sx={{}}>
          Edit
        </Button> */}
      </CustomPopover>

      {/* {openAlertDialog && (
        <CustomAlertDialog
          title="Delete"
          desc={<TextField fullWidth label="Reason" />}
          alertType="danger"
          btnActionText="Delete"
          open={openAlertDialog}
          handleClose={() => setOpenAlertDialog(false)}
        />
      )} */}
    </>
  );
}
