import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, Typography } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';

import { ConnectLogo } from '../../../assets/images';
import Label from '../../label';
import { HeaderUser } from './User/User';

import '../css/header.min.css';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth: number = 280;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  background: 'white',
  boxShadow: 'none',
  borderLeft: 'none',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const AppHeader = (props: any) => {
  const navigate = useNavigate();
  return (
    <AppBar className="appbar" position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={{ padding: '8px 16px !important' }}>
        <Stack direction="row" gap="8px" justifyContent="flex-start" alignItems="center">
          <Stack
            direction="row"
            onClick={() => {
              navigate('/dashboard');
            }}
            gap="4px"
            sx={{ cursor: 'pointer' }}
            alignItems="center"
          >
            <ConnectLogo />
            <Typography variant="subtitle1" color="grey.900" sx={{ fontFamily: 'Inter' }}>
              Dentbird Connect
            </Typography>
          </Stack>
          <Label variant="soft" color="primary">
            Admin
          </Label>
        </Stack>
        <Box className="appbar-actions">
          <HeaderUser />
        </Box>
      </Toolbar>
    </AppBar>
  );
};
