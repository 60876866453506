import React from 'react';
import { Toaster } from 'react-hot-toast';

import { AppLayout } from './components/shared/AppLayout/AppLayout';

import './assets/css/styles.min.css';

export default function App() {
  // focusManager.setEventListener((handleFocus) => {
  //   if (typeof window !== 'undefined' && window.addEventListener) {
  //     const focusHandler = () => {
  //       handleFocus(true);
  //     };
  //     const blurHandler = () => {
  //       handleFocus(false);
  //     };
  //     window.addEventListener('focus', focusHandler);
  //     window.addEventListener('blur', blurHandler);
  //
  //     return () => {
  //       window.removeEventListener('focus', focusHandler);
  //       window.removeEventListener('blur', blurHandler);
  //     };
  //   }
  // });

  return (
    <>
      <AppLayout />
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}
